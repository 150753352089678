.ant-alert {
    border-radius: $border-radius;
    padding: 14px 16px;
    gap: 10px;
    
    .ant-alert-icon {
        margin-inline-end: 0px;
        position: relative;
        top: 0px;
        width: 18px;
        height: 18px;
    }

    &.ant-alert-with-description {
        .ant-alert-icon {
            top: 5px;
        }
    }

    .ant-alert-content {
        .ant-alert-message {
            font-weight: $font-weight-semibold;
            margin-bottom: 2px;
        }

        .ant-alert-description {
            font-weight: $font-weight-normal;
        }
    }

    &-success  {
        background-color: $green-focus1;
        border: 1px solid $green-100;

        .ant-alert-icon {
            path {
                stroke: $green-500;
            }
        }

        .ant-alert-content {
            .ant-alert-message {
                color: $green-500;
            }

            .ant-alert-description {
                color: $green-500;
            }
        }
    }

    &-info  {
        background-color: $cyan-focus1;
        border: 1px solid $cyan-100;

        .ant-alert-icon {
            path {
                stroke: $cyan-500;
            }
        }

        .ant-alert-content {
            .ant-alert-message {
                color: $cyan-500;
            }

            .ant-alert-description {
                color: $cyan-500;
            }
        }
    }

    &-warning  {
        background-color: $yellow-focus1;
        border: 1px solid $yellow-100;

        .ant-alert-icon {
            path {
                stroke: $yellow-500;
            }
        }

        .ant-alert-content {
            .ant-alert-message {
                color: $yellow-500;
            }

            .ant-alert-description {
                color: $yellow-500;
            }
        }
    }

    &-error  {
        background-color: $red-focus1;
        border: 1px solid $red-100;

        .ant-alert-icon {
            path {
                stroke: $red-500;
            }
        }

        .ant-alert-content {
            .ant-alert-message {
                color: $red-500;
            }

            .ant-alert-description {
                color: $red-500;
            }
        }
    }
}