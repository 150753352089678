.job-type-layout {
    background-color: #F0F3FA;
    
    .top-section {
        position: sticky;
        background-color: white;
        border-top: 1px solid #F4F5F8;
        z-index: 1;

        &.small-screen,
        &.is-dialog {
            position: relative;
            top: 0px !important;
        }

        -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
        -moz-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
        box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);

        @include respond-below('md') {
            margin-bottom: 10px;
        }

        .custom-close-btn {
            padding: 0px;
            padding-top: 5px;

            .MuiIconButton-label {
                i {
                    color: rgba($color-secondary, 0.4);
                    font-size: 35px;
                }
            }

            &:hover {
                background-color: transparent !important;

                .MuiIconButton-label {
                    i {
                        color: #F4F5F8;
                    }
                }
            }

            .MuiTouchRipple-root {
                display: none;
            }
        }

        input[disabled] {
            cursor: not-allowed;
        }

        button {
            &.Mui-disabled {
                cursor: not-allowed;
                pointer-events: unset;
            }
        }
    }

    .left-section {
        position: sticky;

        &.is-dialog {
            position: relative !important;
            top: unset !important;
            height: auto !important;
            padding: 10px;
        }

        .job-type-form-menu {
            padding: 20px 35px;

            .job-type-form-menu-item {
                user-select: none;
                cursor: pointer;
                padding-bottom: 10px;

                label {
                    color: $color-secondary;
                }

                &:hover {
                    label {
                        cursor: pointer;
                        color: $color-active;
                    }
                }

                // &.active {
                //     label {
                //         cursor: pointer;
                //         color: $color-active;
                //     }
                // }
            }

            @include respond-below('md') {
                padding: 0px;
            }
        }

        @include respond-below('md') {
            position: relative !important;
            top: unset !important;
            height: auto !important;
            padding: 10px;
        }
    }

    .content-section {
        &.is-dialog {
            overflow-y: scroll;
            height: 65vh;
            padding-bottom: 0px !important;
        }

        @include respond-below('lg') {
            &.is-dialog {
                overflow-y: unset;
                height: unset;
            }
        }

        @include respond-below('md') {
            padding: 0 10px;
        }
    }
}