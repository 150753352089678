// SVG stroke
.stroke-none {
    path {
        stroke: unset !important;
    }
}
.stroke-muted {
    path {
        stroke: $text-muted;
    }
}
