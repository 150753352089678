.jobs-form-layout {
    background-color: white;
    
    .header-form-section {
        width: 100%;
        z-index: 2;

        .header-save-button {
            display: none;
            
            @include media-breakpoint-down(md) {
                display: flex;
            }
        }
    }

    .body-form-section {
        @include media-breakpoint-down(md) {
            padding-bottom: 100px !important;
        }
    }

    .footer-form-section {
        position: fixed;
        bottom: 0px;

        width: 100%;
        background-color: #F7F7F7;
        z-index: 6;

        .custom-divider {
            width: 1px;
            height: 40px;
            border-left: 1px solid $secondary
        }
        
        @include media-breakpoint-down(md) {
            position: relative !important;
        }
    }

    .top-form-section {
        @include media-breakpoint-up(lg) {
            padding-left: 100px !important;
            padding-right: 100px !important;
        }
        @include media-breakpoint-up(xl) {
            padding-left: 150px !important;
            padding-right: 150px !important;
        }
    }

    .locations-form-section {
        @include media-breakpoint-up(lg) {
            padding-left: 100px !important;
            padding-right: 100px !important;
        }
        @include media-breakpoint-up(xl) {
            padding-left: 150px !important;
            padding-right: 150px !important;
        }
    }

    .job-type-form-section {
        @include media-breakpoint-up(lg) {
            padding-left: 100px !important;
            padding-right: 100px !important;
        }
        @include media-breakpoint-up(xl) {
            padding-left: 150px !important;
            padding-right: 150px !important;
        }
        
        .custom-curousel {
            padding: 0px !important;
            background-color: transparent !important;
            border: 0px !important;

            .rec-slider-container {
                margin: 0px !important;

                .rec-carousel-item {
                    .btn-outline-secondary {
                        background-color: white;

                        &:hover {
                            background-color: $primary;
                            color: white !important;
                        }
                    }
                }

                @media all and (min-width: 768px) {
                    .rec-carousel-item {
                        &:first-child {
                            > div {
                                padding-left: 0px !important;
                            }
                        }

                        &:last-child {
                            > div {
                                padding-right: 0px !important;
                            }
                        }
                    }
                }
            }

            &.is-invalid {
                border: 0px !important;
            }
        }
    }

    .steps-form-section {
        @include media-breakpoint-up(lg) {
            padding-left: 100px !important;
            padding-right: 100px !important;
        }
        @include media-breakpoint-up(xl) {
            padding-left: 150px !important;
            padding-right: 150px !important;
        }
    }

    .dispatch-form-sections {
        @include media-breakpoint-up(lg) {
            padding-left: 100px !important;
            padding-right: 100px !important;
        }
        @include media-breakpoint-up(xl) {
            padding-left: 150px !important;
            padding-right: 150px !important;
        }
    }

    .recurring-form-section {
        @include media-breakpoint-up(lg) {
            padding-left: 100px !important;
            padding-right: 100px !important;
        }
        @include media-breakpoint-up(xl) {
            padding-left: 150px !important;
            padding-right: 150px !important;
        }
    }

    .options-form-section {
        @include media-breakpoint-up(lg) {
            padding-left: 100px !important;
            padding-right: 100px !important;
        }
        @include media-breakpoint-up(xl) {
            padding-left: 150px !important;
            padding-right: 150px !important;
        }
    }
}