.custom-cropper-component {
    position: relative;
    text-align: center;

    .cropper-view-box, .cropper-face {
        border-radius: 50%;
    }

    .default-image {
        cursor: pointer;
        
        img {
            border-radius: 50%;
            overflow: hidden;
            -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
            -moz-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
            box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
        }
    }

    &.no-circle {
        .cropper-view-box, .cropper-face {
            border-radius: unset;
        }

        .default-image {
            img {
                border-radius: unset;
            }
        }
    }

    &.no-shadow {
        .default-image {
            img {
                -webkit-box-shadow: none;
                -moz-box-shadow: none;
                box-shadow: none;
            }
        }
    }

    .crop-image-btn {
        position: relative;
        text-align: center;
        width: 100%;
    }
    .add-image-btn {
        position: absolute;
        bottom: 0px;
        text-align: center;
        width: 100%;
        display: none;

        span {
            background-color: rgba($color-secondary, 0.7);

            span {
                background-color: transparent;

                svg {
                    color: $white;
                }
            }

            &:hover {
                background-color: rgba($color-secondary, 0.5);

                span {
                    background-color: transparent;

                    svg {
                        color: $white;
                    }
                }
            }
        }
    }
    &:hover {
        > div {
            + .add-image-btn {
                display: block;
            }
        }
    }

    .cropper-wrapper {
        display: flex;
        justify-content: center;
        justify-items: center;
        align-items: center;
        position: relative;
    }

    .progress-circle {
        position: absolute;
        z-index: 1;
        background-color: black;
        display: flex;
        align-items: center;
        justify-items: center;
        opacity: 0.7;
        margin: 0 auto;
        justify-content: center;

        .progress-circle-text {
            position: absolute;
            color: white;
        }
    }
}