.custom-date-picker-component {
    position: relative;

    &.icon-left {
        .material-icons {
            position: absolute;
            top: 15px;
            left: 5px;
            z-index: 1;
    
            &.muted {
                color: rgba($color-secondary, 0.20) !important;
            }
        }
    }

    &.icon-right {
        .material-icons {
            position: absolute;
            top: 15px;
            right: 15px;
            z-index: 1;
    
            &.muted {
                color: rgba($color-secondary, 0.20) !important;
            }
        }
    }

    &.error {
        input {
            border: 1px solid rgba($color-danger, 0.4) !important;
        }
    }

    .react-datepicker-wrapper {
        width: 100%;
        overflow: hidden;
    }

    .react-datepicker-popper {
        z-index: 9999;
    }
    
    input {
        width: 100%;
        min-height: 53px;
        border: 0px;
        color: $color-secondary;
        background-color: #F7F9FD !important;
        padding-left: 15px;
        font-size: $font-size-14;
        line-height: 40px;

        &:focus, &:active {
            outline: none;
        }
        
        &::placeholder {
            font-size: $font-size-14;
            font-weight: normal;
            font-style: italic;
            color: rgba($color-secondary, 0.60);
        }

        .placeholder {
            font-size: $font-size-14;
            font-weight: normal;
            font-style: italic;
            color: rgba($color-secondary, 0.60);
        }

        &[disabled] {
            background-color: #F7F9FD !important;
            color: #999a9d !important;
        }
    }

    &.has-icon {
        &.icon-left {
            input {
                padding-left: 35px;
            }
        }
    }

    &.bg-white-underline {
        input {
            background-color: $white !important;
            border-bottom: 1px solid rgba(#484848, 0.10) !important;
            height: 53px;
        }

        .MuiInputBase-adornedEnd {
            background-color: $white !important;
        }
    
        .MuiInputBase-adornedStart {
            background-color: $white !important;
        }

        &.recurring {
            input {
                padding-left: 0px;
            }
        }
    }

    .react-datepicker__time-container--with-today-button {
        right: -90px !important;
    }
}