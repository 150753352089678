.rmdp-panel-body li {
    box-shadow: unset !important;
}

.rmdp-week-day {
    color: $gray-800 !important;
}

.rmdp-day.rmdp-deactive {
    color: $gray-800 !important;
}

.rmdp-arrow {
    border: solid $gray-500 !important;
    border-width: 0 2px 2px 0 !important;
}

.rmdp-arrow-container:hover {
    background-color: $transparent !important;
    box-shadow: unset !important;

    > i {
        border: solid $gray-800 !important;
        border-width: 0 2px 2px 0 !important;
    }
}

.rmdp-day.rmdp-today {
    &:hover {
        color: $gray-800 !important;
    }

    > span {
        background-color: $gray-100 !important;
        color: $gray-800 !important;
    
        &:hover {
            background-color: $gray-50 !important;
            color: $blue-500 !important;
        }
    }
}

.rmdp-disabled {
    &:hover {
        color: $gray-300 !important;
    }

    > span {
        color: $gray-300 !important;

        &:hover {
            color: $gray-300 !important;
        }
    }
}

.rmdp-rtl .rmdp-panel {
    border-left: unset !important;
    border-right: unset !important;
}

.rmdp-selected span:not(.highlight) {
    background-color: $blue-400 !important;
    box-shadow: $shadow1 !important;
}
.rmdp-selected span:not(.highlight) {
    &:hover {
        background-color: $gray-50 !important;
        color: $blue-500 !important;
    }
}
.rmdp-day:not(.rmdp-day-hidden) span:hover {
    color: $white;
}

.b-deselect {
    color: $gray-800 !important;
    background-color: $gray-100 !important;
}

.rmdp-action-button {
    color: $blue-400 !important;
}

.rmdp-button:not(.rmdp-action-button) {
    background-color: $blue-400 !important;
}

.rmdp-button:not(.rmdp-action-button):hover {
    color: $gray-800 !important;
    background-color: $gray-100 !important;
}

.rmdp-range {
    background-color: $gray-50 !important;
    color: $blue-500 !important;
    box-shadow: unset !important;

    &.start {
        > span {
            background-color: $blue-400 !important;
            color: $white !important;
        }
    }

    &.end {
        > span {
            background-color: $blue-400 !important;
            color: $white !important;
        }
    }

    &:hover {
        background-color: $gray-100 !important;
        
        > span {
            background-color: $gray-100 !important;
            color: $blue-500 !important;

            &:hover {
                background-color: $gray-100 !important;
                color: $blue-500 !important;
            }
        }

        &.start {
            > span {
                background-color: $blue-200 !important;
                color: $white !important;
            }
        }
    
        &.end {
            > span {
                background-color: $blue-200 !important;
                color: $white !important;
            }
        }
    }
}

.ep-arrow {
    top: 1px !important;
    z-index: 1051 !important;

    &+div {
        z-index: 1050 !important;
    }
}