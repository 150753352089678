.make-split-dropdown-list-component {
    button {
        border: 1px solid $primary !important;
        padding-top: 7px !important;
        padding-bottom: 7px !important;
        padding-left: 10px !important;
        padding-right: 10px !important;
    }

    .dropdown-toggle-split {
        border-left: 1px solid white !important;
        margin-left: 0px !important;
    }
}