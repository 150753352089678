.map-page {
    .button-arrow {
        button {
            padding-top: 7px !important;
            padding-bottom: 7px !important;
            min-height: 42px;

            &:first-child {
                margin-right: 5px;
                padding-left: 13px !important;
                padding-right: 10px !important;
            }
            &:last-child {
                padding-left: 13px !important;
                padding-right: 10px !important;
            }

            &.primary {
                border: 1px solid $primary !important;
                color: $primary !important;

                i {
                    color: $primary;
                }
            }
            
            &.secondary {
                border: 1px solid #E4E6EF !important;
                
                i {
                    color: $secondary;
                }
            }
        }
    }

    .dropdown-outline-secondary {
        button {
            background-color: transparent !important;
            border: 1px solid #E4E6EF !important;

            &::after {
                color: $secondary !important;
            }

            &:hover {
                background-color: transparent !important;
            }
        }

        .dropdown-item {
            height: 35px;
        }

        .dropdown-divider {
            border: 0px !important;
            background-color: #E4E6EF !important;
            opacity: 0.7 !important;
        }

        .check-box-icon {
            color: #E4E6EF;

            &.checked {
                color: $primary;
            }
        }
    }

    .active-period {
        &-title {
            margin-top: 5px;
            margin-bottom: 5px;
            font-weight: 500;
        }

        &-legend {
            display: flex;

            > div {
                flex-grow: 4;
                flex: 1 1 0px;
                text-align: center;
                user-select: none;
                cursor: pointer;
                padding-top: 5px;
                padding-bottom: 5px;
                color: black;
                background-color: #ececec;
                transition: all 0.5s;
            }

            &-green {
                &:hover, &.active {
                    background-color: #088F8F;
                    color: white;
                }
            }

            &-yellow {
                &:hover, &.active {
                    background-color: #ffc107;
                    color: white;
                }
            }

            &-orange {
                &:hover, &.active {
                    background-color: #fd7e14;
                    color: white;
                }
            }

            &-red {
                &:hover, &.active {
                    background-color: #dc3545;
                    color: white;
                }
            }
        }
    }
}