html {
  font-family: sans-serif; // 1
  text-size-adjust: 100%; // 2
  -webkit-text-size-adjust: 100%; // 2
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}

body {
    display: flex;
    flex-direction: column;
    color: $body-color;
}

// Angular integration
router-outlet {
    display: none;
}

canvas {
    user-select: none;
}
