// Horizontal scrollbar
.custom-virtualized-scrollbar {
    overflow: auto;
    height: 10px;
    position: fixed;
    bottom: 0px;
    overflow-x: auto !important;
    cursor: pointer;
    z-index: 3;

    .custom-virtualized-scrollbar-child {
        height: 10px;
    }

    &::-webkit-scrollbar-thumb {
        height: 10px;
        background: $gray;

        &:hover {
            background: rgba($gray, 0.5);
        }
    }
    &::-webkit-scrollbar{
        height: 8px;
    }
}

.custom-scroll {
    >div {
        z-index: 2;
    }
}

.dragging-helper-class {
    background-color: $bg-color !important;
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    z-index: 99999;
    td {
        display: none;
        border: 0px;
        &:nth-child(1) {
            opacity: 0;
        }
        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(3) {
            display: block;
        }
    }
}

.dragging-helper-class-row {
    background-color: $bg-color !important;
    z-index: 99999;
    border-bottom: 0px;

    td {
        border-bottom: 0px;
        padding: 10px;
        padding-top: 12px;
        font-weight: bold;
        font-size: 12px;

        .responsive-title {
            display: none;
        }

        svg {
            path {
                fill: $primary;
            }
        }
    }
}

.custom-drag {
    cursor: pointer;
    display: inline-block;
    color: rgba(72, 72, 72, 0.1) !important;
    right: 15px;
    position: absolute;
    font-size: 16px !important;
    &:hover {
        color: rgba(72, 72, 72, 0.6) !important;
    }
    &.is-action-cell {
        right: unset;
        left: 15px;
    }
}

.custom-drag-cell {
    display: inline !important;
    z-index: 999999 !important;
    background-color: #cccccc !important;
    >div {
        top: -7px;
        position: relative;
        color: white !important;
        .custom-drag {
            right: 0px !important;
        }
        .custom-cell-sort {
            display: none !important;
        }
    }
}

.cursor-sortable-drag-icon {
    text-align: center;
    padding: 10px 5px;
    i {
        color: rgba($color-secondary, 0.6) !important;
    }
}

.cursor-sortable-grab {
    cursor: grab;
    &:hover {
        &.cursor-sortable-drag-icon {
            i {
                color: $color-active !important;
            }
        }
    }
}

.custom-sortable-drag-cell {
    z-index: 999999 !important;
    pointer-events: auto !important;
    margin-left: -5px !important;
    cursor: ew-resize;
    * {
        cursor: ns-resize;
    }
    td {
        display: none;
        &:nth-child(1) {
            display: table-cell;
            >div {
                width: 29px;
                i {
                    color: $color-active !important;
                }
            }
        }
        &:nth-child(2) {
            display: table-cell;
            width: 100% !important;
            font-weight: bold;
        }
    }
}

.custom-cell-sort {
    // padding-right: 30px !important;
}

.custom-cell-sort2 {
    padding-right: 0px !important;
}

.custom-cell-sort-icons {
    .sort-up {
        position: absolute;
        width: 20px;
        height: 20px;
        top: 23%;
        overflow: hidden;
        margin-left: 2px;
        i {
            position: absolute;
            left: -6px;
            top: -3px;
            font-size: 25px;
        }
        &.selected {
            i {
                color: $color-secondary !important;
            }
        }
    }
    .sort-down {
        position: absolute;
        width: 20px;
        height: 20px;
        bottom: 17%;
        overflow: hidden;
        margin-left: 2px;
        i {
            position: absolute;
            left: -6px;
            top: -3px;
            font-size: 25px;
        }
        &.selected {
            i {
                color: $color-secondary !important;
            }
        }
    }
    .sort-up1 {
        position: absolute;
        width: 20px;
        height: 20px;
        overflow: hidden;
        margin-left: 2px;
        i {
            position: absolute;
            left: -6px;
            top: -3px;
            font-size: 25px;
        }
        &.selected {
            i {
                color: $color-secondary !important;
            }
        }
    }
    .sort-down1 {
        position: absolute;
        width: 20px;
        height: 20px;
        overflow: hidden;
        margin-left: 2px;
        i {
            position: absolute;
            left: -6px;
            top: -3px;
            font-size: 25px;
        }
        &.selected {
            i {
                color: $color-secondary !important;
            }
        }
    }
}

.table-container {
    background-color: white;
    margin-bottom: 100px;
    overflow-x: unset !important;
    &.no-mb {
        margin-bottom: 0px;
    }
    .row-currsor-hand {
        tr {
            td {
                cursor: pointer;
            }
        }
    }
}

.datatable-component {
    .MuiTableCell-stickyHeader {
        background-color: $white;
    }
    .col-muted {
        color: rgba($muted, 0.5) !important;
    }
    .txt-dark {
        span {
            color: $color-secondary !important;
        }
    }
    .active {
        span {
            color: $color-active !important;
        }
    }
    .MuiTableRow-root.MuiTableRow-hover:hover {
        background-color: rgba($color-secondary, 0.01);
    }
    .MuiTableRow-root.Mui-selected,
    .MuiTableRow-root.Mui-selected:hover {
        background-color: rgba($color-secondary, 0.03);
    }
    .MuiCheckbox-root {
        .MuiIconButton-label {
            color: rgba($color-secondary, 0.10);
            &:hover {
                color: rgba($color-secondary, 0.60);
                svg {
                    color: rgba($color-secondary, 1);
                }
            }
        }
    }
    tr,
    td {
        font-size: $font-size-14;
        word-break: break-word;
    }
    tr {
        th {
            word-break: break-word;
            // min-width: 80px;
            div {
                font-size: $font-size-14;
            }
            .Mui-checked {
                background-color: rgba($color-secondary, 0.10);
                .MuiIconButton-label {
                    color: rgba($color-secondary, 1);
                }
            }
            span {
                color: rgba($color-secondary, 0.60);
                &:hover {
                    color: rgba($color-secondary, 0.60);
                    svg {
                        color: rgba($color-secondary, 1);
                    }
                }
            }
        }
    }
    tr {
        td {
            border: 0px;
            .Mui-checked {
                background-color: rgba($color-primary, 0.03);
                .MuiIconButton-label {
                    color: rgba($color-primary, 1);
                }
            }
            button {
                .MuiIconButton-label {
                    color: rgba($color-secondary, 0.10);
                    .fa {
                        font-size: 16px;
                    }
                }
                &:hover,
                &:active,
                &:focus {
                    .MuiIconButton-label {
                        color: $color-primary;
                    }
                }
            }
        }
    }
    .group-row {
        background-color: transparent !important;
        td {
            vertical-align: bottom !important;
            @include respond-above('md') {
                padding-top: 60px !important;
            }
        }
    }
    .my-drag {
        color: rgba($color-secondary, 0.10);
        padding-top: 5px;
    }
    &.list-style {
        .my-drag {
            padding-top: 5px;
        }
        tr {
            th {
                button {
                    .material-icons {
                        color: rgba($color-secondary, 0.7) !important;
                    }
                }
            }
        }
        tbody {
            tr {
                td {
                    padding: 5px;
                    background-color: $table-td-bg !important;
                    border-top: 20px solid $white !important;
                    border-right: 15px solid $white !important;
                    border-bottom: 20px solid $white !important;
                    border-left: 15px solid $white !important;
                    &:first-child {
                        background-color: $white !important;
                        border-left: 0px !important;
                    }
                    &:nth-child(2) {
                        border-right: 0px !important;
                    }
                    &:nth-child(3) {
                        border-left: 0px !important;
                    }
                    &:last-child {
                        background-color: $white !important;
                        border-right: 0px !important;
                    }
                }
            }
        }
    }
    &.job-billing-style {
        tr {
            th {
                button {
                    .material-icons {
                        color: rgba($color-secondary, 0.7) !important;
                    }
                }
            }
        }
        tbody {
            tr {
                td {
                    padding: 0px;
                    background-color: $white !important;
                    border-top: 2px solid $table-td-bg;
                    padding-top: 10px;
                    padding-bottom: 10px;
                }
                &:first-child {
                    td {
                        padding-top: 0px;
                    }
                }
            }
        }
    }
    &.customer-site-standard-additional-table-style {
        &.custom-add-row {
            .custom-details-row--1 {
                border: 0px !important;
                border-bottom: 10px solid $bg-color !important;
                td {
                    border-top-left-radius: 0px !important;
                    border-bottom-left-radius: 0px !important;
                    border-top-right-radius: 0px !important;
                    border-bottom-right-radius: 0px !important;
                }
                &:hover {
                    background-color: $bg-color !important;
                }
            }
        }
        tr {
            th {
                padding: 15px 5px !important;
                border-bottom: 10px solid $bg-color;
                &:first-child {
                    border-top-left-radius: 5px !important;
                    border-bottom-left-radius: 5px !important;
                }
                &:last-child {
                    border-top-right-radius: 5px !important;
                    border-bottom-right-radius: 5px !important;
                }
            }
        }
        tbody {
            tr {
                td {
                    padding: 10px 5px !important;
                    background-color: $white;
                    border-bottom: 10px solid $bg-color;
                    min-height: 60px !important;
                    &:first-child {
                        border-top-left-radius: 5px !important;
                        border-bottom-left-radius: 5px !important;
                    }
                    &:last-child {
                        border-top-right-radius: 5px;
                        border-bottom-right-radius: 5px !important;
                    }
                }
                &:hover {
                    background-color: transparent !important;
                    td {
                        background-color: transparent !important;
                    }
                }
            }
        }
        .custom-cell-sort-icons {
            position: relative;
            .sort-up {
                top: -16px !important;
            }
            .sort-down {
                bottom: -16px !important;
            }
        }
        .details-row {
            display: none;
            cursor: default !important;
            td {
                cursor: default !important;
            }
            &.exp-details-row {
                >td {
                    border-bottom: 0px !important;
                }
            }
        }
        .exp-details-row {
            display: table-row;
            &:hover {
                background-color: transparent !important;
                >td {
                    background-color: transparent !important;
                }
            }
        }
        .details-second-row {
            td {
                background-color: $bg-color !important;
                padding: 0px !important;
                &:first-child {
                    border-top-left-radius: 0px !important;
                    border-bottom-left-radius: 0px !important;
                }
                &:last-child {
                    border-top-right-radius: 0px !important;
                    border-bottom-right-radius: 0px !important;
                }
            }
            &:hover {
                background-color: $bg-color !important;
                td {
                    background-color: $bg-color !important;
                }
            }
        }
        .has-expanded-rows {
            tr {
                opacity: 1;
                &.exp-row {
                    display: none;
                    td {
                        border-bottom: 0px !important;
                    }
                }
                &:not(.exp-row) {
                    opacity: 1;
                }
                &.exp-details-row {
                    opacity: 1;
                }
            }
        }
    }
    &.customer-site-service-style {
        tr {
            th {
                button {
                    .material-icons {
                        color: rgba($color-secondary, 0.7) !important;
                    }
                }
            }
        }
        tbody {
            tr {
                td {
                    padding: 0px;
                    background-color: $white;
                    border-top: 0px;
                    padding-top: 10px;
                    padding-bottom: 10px;
                }
            }
        }
        tbody {
            tr {
                &.highlight {
                    td {
                        -moz-animation: blinkingAnim 0.7s 3;
                        -o-animation: blinkingAnim 0.7s 3;
                        -webkit-animation: blinkingAnim 0.7s 3;
                        animation: blinkingAnim 0.7s 3;
                    }
                }
                &.highlight-yellow {
                    td {
                        -moz-animation: blinkingAnimYellow 0.7s 3;
                        -o-animation: blinkingAnimYellow 0.7s 3;
                        -webkit-animation: blinkingAnimYellow 0.7s 3;
                        animation: blinkingAnimYellow 0.7s 3;
                    }
                }
                &.highlight-red {
                    td {
                        -moz-animation: blinkingAnimRed 0.7s 3;
                        -o-animation: blinkingAnimRed 0.7s 3;
                        -webkit-animation: blinkingAnimRed 0.7s 3;
                        animation: blinkingAnimRed 0.7s 3;
                    }
                }
            }
        }
    }
    &.customer-site-service-style-2 {
        .custom-details-btn-2 {
            border-radius: 5px !important;
            text-transform: none;
        }
        tr {
            th {
                background-color: $white;
                border-bottom: 0px;
                >div {
                    color: #7F7F7F !important;
                    font-size: $font-size-14;
                    font-weight: bold;
                }
            }
        }
        tbody {
            tr {
                td {
                    padding: 0px;
                    background-color: $white;
                    border-top: 0px;
                    padding-top: 10px;
                    padding-bottom: 10px;
                    @include respond-below('sm') {
                        display: block !important;
                        .responsive-title {
                            padding-left: 10px;
                            padding-right: 10px;
                        }
                    }
                }
                @include respond-below('sm') {
                    display: block !important;
                }
            }
            .details-row {
                display: none;
            }
            .visible-details-row {
                display: table-row;
            }
        }
        tbody {
            tr {
                &.highlight {
                    td {
                        -moz-animation: blinkingAnim 0.7s 3;
                        -o-animation: blinkingAnim 0.7s 3;
                        -webkit-animation: blinkingAnim 0.7s 3;
                        animation: blinkingAnim 0.7s 3;
                    }
                }
                &.highlight-yellow {
                    td {
                        -moz-animation: blinkingAnimYellow 0.7s 3;
                        -o-animation: blinkingAnimYellow 0.7s 3;
                        -webkit-animation: blinkingAnimYellow 0.7s 3;
                        animation: blinkingAnimYellow 0.7s 3;
                    }
                }
                &.highlight-red {
                    td {
                        -moz-animation: blinkingAnimRed 0.7s 3;
                        -o-animation: blinkingAnimRed 0.7s 3;
                        -webkit-animation: blinkingAnimRed 0.7s 3;
                        animation: blinkingAnimRed 0.7s 3;
                    }
                }
            }
        }
    }
    &.customer-site-service-style-3 {
        .custom-details-btn-2 {
            border-radius: 5px !important;
            text-transform: none;
        }
        tr {
            th {
                background-color: $white;
                border-bottom: 1px solid rgba(#484848, 0.10) !important;
                >div {
                    color: #7F7F7F !important;
                    font-size: $font-size-14;
                    font-weight: bold;
                }
            }
        }
        tbody {
            tr {
                td {
                    padding: 0px;
                    background-color: $white;
                    border-top: 0px;
                    padding-top: 10px;
                    padding-bottom: 10px;
                }
            }
            .details-row {
                display: none;
            }
            .visible-details-row {
                display: table-row;
            }
        }
        tbody {
            tr {
                &.highlight {
                    td {
                        -moz-animation: blinkingAnim 0.7s 3;
                        -o-animation: blinkingAnim 0.7s 3;
                        -webkit-animation: blinkingAnim 0.7s 3;
                        animation: blinkingAnim 0.7s 3;
                    }
                }
                &.highlight-yellow {
                    td {
                        -moz-animation: blinkingAnimYellow 0.7s 3;
                        -o-animation: blinkingAnimYellow 0.7s 3;
                        -webkit-animation: blinkingAnimYellow 0.7s 3;
                        animation: blinkingAnimYellow 0.7s 3;
                    }
                }
                &.highlight-red {
                    td {
                        -moz-animation: blinkingAnimRed 0.7s 3;
                        -o-animation: blinkingAnimRed 0.7s 3;
                        -webkit-animation: blinkingAnimRed 0.7s 3;
                        animation: blinkingAnimRed 0.7s 3;
                    }
                }
            }
        }
    }
    &.customer-site-service-style-2a {
        .custom-details-btn-2 {
            border-radius: 5px !important;
            text-transform: none;
        }
        tr {
            th {
                background-color: $white;
                border-bottom: 0px;
                >div {
                    color: #7F7F7F !important;
                    font-size: $font-size-14;
                    font-weight: bold;
                }
            }
        }
        >tbody {
            >tr {
                border-top: 10px solid #F0F3FA;
                border-bottom: 10px solid #F0F3FA;
            }
            tr {
                td {
                    padding: 0px;
                    background-color: $white;
                    border-top: 0px;
                    padding-top: 10px;
                    padding-bottom: 10px;
                    @include respond-below('sm') {
                        display: block !important;
                        .responsive-title {
                            padding-left: 10px;
                            padding-right: 10px;
                        }
                    }
                }
                @include respond-below('sm') {
                    display: block !important;
                }
            }
            .details-row {
                display: none;
                border-top: 0px;
            }
            .visible-details-row {
                display: table-row;
            }
        }
        tbody {
            tr {
                &.highlight {
                    td {
                        -moz-animation: blinkingAnim 0.7s 3;
                        -o-animation: blinkingAnim 0.7s 3;
                        -webkit-animation: blinkingAnim 0.7s 3;
                        animation: blinkingAnim 0.7s 3;
                    }
                }
                &.highlight-yellow {
                    td {
                        -moz-animation: blinkingAnimYellow 0.7s 3;
                        -o-animation: blinkingAnimYellow 0.7s 3;
                        -webkit-animation: blinkingAnimYellow 0.7s 3;
                        animation: blinkingAnimYellow 0.7s 3;
                    }
                }
                &.highlight-red {
                    td {
                        -moz-animation: blinkingAnimRed 0.7s 3;
                        -o-animation: blinkingAnimRed 0.7s 3;
                        -webkit-animation: blinkingAnimRed 0.7s 3;
                        animation: blinkingAnimRed 0.7s 3;
                    }
                }
            }
        }
        .custom-open-top {
            box-shadow: 0px 0px 16px -10px #484848;
            border-top: 1px solid $color-active;
            border-bottom: 0px;
            border-left: 1px solid $color-active;
            border-right: 1px solid $color-active;
        }
        .custom-open-bottom {
            box-shadow: 0px 0px 16px -10px #484848;
            border-top: 0px;
            border-bottom: 1px solid $color-active;
            border-left: 1px solid $color-active;
            border-right: 1px solid $color-active;
        }
    }
    &.new-manage-style {
        tr {
            th {
                border: 0px;
                padding: 0px;
                padding-bottom: 35px;
                button {
                    .material-icons {
                        color: rgba($color-secondary, 0.7) !important;
                    }
                }
                .add-icon-fill-btn {
                    color: #06D6A0 !important;
                    .MuiIconButton-label {
                        color: #06D6A0 !important;
                        .material-icons {
                            color: #06D6A0 !important;
                        }
                    }
                }
            }
        }
        tbody {
            tr {
                td {
                    padding: 0px;
                    background-color: $white;
                    border-top: 0px;
                    padding-top: 10px;
                    padding-bottom: 10px;
                    @include respond-below('sm') {
                        display: block !important;
                    }
                }
                @include respond-below('sm') {
                    display: block !important;
                }
            }
        }
        &.only-two-col {
            tbody {
                tr {
                    td {
                        @include respond-below('sm') {
                            display: table-cell !important;
                            width: 100% !important;
                        }
                    }
                    @include respond-below('sm') {
                        display: table-row !important;
                        width: 100% !important;
                    }
                }
            }
        }
        tbody {
            tr {
                &.highlight {
                    td {
                        -moz-animation: blinkingAnim 0.7s 3;
                        -o-animation: blinkingAnim 0.7s 3;
                        -webkit-animation: blinkingAnim 0.7s 3;
                        animation: blinkingAnim 0.7s 3;
                    }
                }
                &.highlight-yellow {
                    td {
                        -moz-animation: blinkingAnimYellow 0.7s 3;
                        -o-animation: blinkingAnimYellow 0.7s 3;
                        -webkit-animation: blinkingAnimYellow 0.7s 3;
                        animation: blinkingAnimYellow 0.7s 3;
                    }
                }
                &.highlight-red {
                    td {
                        -moz-animation: blinkingAnimRed 0.7s 3;
                        -o-animation: blinkingAnimRed 0.7s 3;
                        -webkit-animation: blinkingAnimRed 0.7s 3;
                        animation: blinkingAnimRed 0.7s 3;
                    }
                }
            }
        }
        &.custom-vehicle-list {
            tbody {
                tr {
                    td {
                        @include respond-below('md') {
                            display: block !important;
                            width: 100% !important;
                        }
                    }
                    @include respond-below('md') {
                        display: block !important;
                        width: 100% !important;
                    }
                }
            }
        }
        &.job-type-page {
            tr {
                th {
                    border-bottom: 1px solid #E5E5E5;
                    padding: 5px 0px;
                    padding-left: 0px !important;
                }
            }
        }
    }
    &.subscription-manage-style {
        tr {
            th {
                border: 0px;
                padding: 0px;
                padding-bottom: 15px;
                span {
                    color: $color-secondary !important;
                    font-weight: bold;
                }
                border-bottom: 1px solid #E5E5E5;
            }
        }
        tbody {
            tr {
                td {
                    padding: 0px;
                    background-color: $white;
                    border-top: 0px;
                    padding-top: 10px;
                    padding-bottom: 10px;
                    padding-left: 15px;
                    @include respond-below('sm') {
                        display: block !important;
                    }
                }
                @include respond-below('sm') {
                    display: block !important;
                }
            }
        }
        &.only-two-col {
            tbody {
                tr {
                    td {
                        @include respond-below('sm') {
                            display: table-cell !important;
                            width: 100% !important;
                        }
                    }
                    @include respond-below('sm') {
                        display: table-row !important;
                        width: 100% !important;
                    }
                }
            }
        }
        tbody {
            tr {
                &.highlight {
                    td {
                        -moz-animation: blinkingAnim 0.7s 3;
                        -o-animation: blinkingAnim 0.7s 3;
                        -webkit-animation: blinkingAnim 0.7s 3;
                        animation: blinkingAnim 0.7s 3;
                    }
                }
                &.highlight-yellow {
                    td {
                        -moz-animation: blinkingAnimYellow 0.7s 3;
                        -o-animation: blinkingAnimYellow 0.7s 3;
                        -webkit-animation: blinkingAnimYellow 0.7s 3;
                        animation: blinkingAnimYellow 0.7s 3;
                    }
                }
                &.highlight-red {
                    td {
                        -moz-animation: blinkingAnimRed 0.7s 3;
                        -o-animation: blinkingAnimRed 0.7s 3;
                        -webkit-animation: blinkingAnimRed 0.7s 3;
                        animation: blinkingAnimRed 0.7s 3;
                    }
                }
            }
        }
        &.custom-vehicle-list {
            tbody {
                tr {
                    td {
                        @include respond-below('md') {
                            display: block !important;
                            width: 100% !important;
                        }
                    }
                    @include respond-below('md') {
                        display: block !important;
                        width: 100% !important;
                    }
                }
            }
        }
        &.job-type-page {
            tr {
                th {
                    border-bottom: 1px solid #E5E5E5;
                    padding: 5px 0px;
                    padding-left: 0px !important;
                }
            }
        }
    }
    &.empty-style {
        width: inherit !important;
        &.p-0 {
            tbody {
                tr {
                    td {
                        padding-top: 0px !important;
                        padding-bottom: 0px !important;
                    }
                }
            }
            &.m-p-0 {
                tbody {
                    tr {
                        td {
                            @include respond-below('md') {
                                padding-top: 5px !important;
                                padding-bottom: 5px !important;
                            }
                        }
                    }
                }
            }
        }
        &.p-5 {
            tbody {
                tr {
                    td {
                        padding: 7px !important;
                    }
                }
            }
        }
        .my-drag {
            padding-top: 5px;
        }
        tr {
            th {
                button {
                    .material-icons {
                        color: rgba(transparent, 0.7) !important;
                    }
                }
            }
        }
        tbody {
            tr {
                td {
                    padding: 5px;
                    background-color: transparent !important;
                    border-top: 20px solid $white !important;
                    border-right: 15px solid $white !important;
                    border-bottom: 20px solid $white !important;
                    border-left: 15px solid $white !important;
                    &:last-child {
                        // background-color: $white !important;
                        border-right: 0px !important;
                    }
                }
            }
            @include respond-below('sm') {
                display: block !important;
                &:before {
                    content: none;
                }
                &:before {
                    content: none;
                }
                tr {
                    display: block !important;
                    td {
                        display: block;
                        width: auto !important;
                        border-top: 0px !important;
                        border-right: 0px !important;
                        border-left: 0px !important;
                        border-bottom: 0px !important;
                        span {
                            width: 100% !important;
                            input {
                                width: 100% !important;
                            }
                        }
                    }
                    .table-cell-border {
                        display: none !important;
                    }
                    .responsive-hide {
                        display: none !important;
                    }
                    .datatable-checkbox-style {
                        text-align: center;
                    }
                    .responsive-title {
                        display: block;
                    }
                }
            }
        }
        &.no-left-border {
            tbody {
                tr {
                    td {
                        border: 0px !important;
                        &:first-child {
                            padding-left: 0px;
                        }
                    }
                }
            }
        }
        &.time-block-style {
            tbody {
                tr {
                    td {
                        background-color: $table-td-bg !important;
                        &:last-child {
                            background-color: transparent !important;
                        }
                        &:nth-child(1) {
                            border-right: 0px !important;
                        }
                        &:nth-child(2) {
                            border-left: 0px !important;
                            border-right: 0px !important;
                        }
                        &:nth-child(3) {
                            border-left: 0px !important;
                            border-right: 0px !important;
                        }
                        &:nth-child(4) {
                            border-left: 0px !important;
                        }
                    }
                }
            }
        }
    }
    &.custom-table-style {
        .my-drag {
            padding-top: 5px;
        }
        .MuiTableRow-root.Mui-selected,
        .MuiTableRow-root.Mui-selected:hover {
            background-color: rgba($color-active, 0.08);
        }
        @include respond-below('sm') {
            .MuiTableRow-root.MuiTableRow-hover:hover {
                background-color: $white;
            }
        }
        thead {
            background-color: $white;
            tr {
                box-shadow: 0 4px 4px -1px gray !important;
                .table-cell-border {
                    width: 15px !important;
                    padding: 0px !important;
                }
                th {
                    height: 42px;
                    border: 0px;
                    padding: 0px 10px 0px 10px;
                    &.MuiTableCell-paddingCheckbox {
                        padding: 0px 4px 0px 4px !important;
                    }
                    color: rgba($color-secondary, 0.60);
                    * {
                        color: rgba($color-secondary, 0.60);
                    }
                }
            }
            @include respond-below('sm') {
                display: none;
            }
        }
        tbody {
            &:after {
                content: "-";
                display: block;
                line-height: 1em;
                color: transparent;
            }
            &:before {
                content: "@";
                display: block;
                line-height: 10px;
                text-indent: -99999px;
            }
            tr {
                td {
                    padding: 10px;
                    &.MuiTableCell-paddingCheckbox {
                        padding: 0px 4px 0px 4px !important;
                    }
                }
                &:first-child {
                    td {
                        border-top: 5px solid $bg-color;
                    }
                }
                &:last-child {
                    td {
                        border-bottom: 15px solid $bg-color;
                    }
                }
                &:nth-child(even) {
                    background-color: $table-td-bg;
                }
                &:nth-child(odd) {
                    background-color: $white;
                }
                .table-cell-border {
                    width: 15px !important;
                    padding: 0px !important;
                    background-color: $bg-color;
                }
                .responsive-title {
                    display: none;
                }
            }
            a {
                text-decoration: none;
                color: rgba($color-secondary, 0.20);
                &:hover,
                &:active,
                &.active {
                    color: $color-active;
                }
            }
            .green-link {
                &.active {
                    color: #06D6A0;
                    &:hover {
                        color: rgba($color-secondary, 0.20);
                    }
                }
                &:hover {
                    color: #06D6A0;
                }
                &.no-hover {
                    cursor: default !important;
                    &:not(.active) {
                        color: rgba($color-secondary, 0.20) !important;
                    }
                    &:hover:not(.active) {
                        color: rgba($color-secondary, 0.20) !important;
                    }
                }
            }
            @include respond-below('sm') {
                display: block !important;
                &:before {
                    content: none;
                }
                &:before {
                    content: none;
                }
                tr {
                    display: block !important;
                    border-top: 15px solid $bg-color !important;
                    border-bottom: 15px solid $bg-color !important;
                    td {
                        display: block;
                        width: auto !important;
                        border-style: solid !important;
                        border-width: 1px !important;
                        border-color: $bg-color !important;
                        border-left: 15px solid $bg-color !important;
                        border-right: 15px solid $bg-color !important;
                        &:first-child {
                            border-top: 0px !important;
                        }
                        &:last-child {
                            border-bottom: 0px !important;
                        }
                    }
                    .table-cell-border {
                        display: none !important;
                    }
                    .responsive-hide {
                        display: none !important;
                    }
                    .datatable-checkbox-style {
                        text-align: center;
                    }
                    .responsive-title {
                        display: block;
                    }
                }
            }
        }
        &.padding-left-0 {
            td {
                padding-left: 0px;
            }
        }
        &.padding-left-5 {
            td {
                padding-left: 5px;
            }
        }
        &.padding-left-20 {
            td {
                padding-left: 20px !important;
            }
        }
        &.padding-right-20 {
            td {
                padding-right: 20px !important;
            }
        }
    }
    &.custom-job-table-style {
        &.sticky {
            word-break: break-all !important;
            thead {
                tr {
                    th {
                        position: sticky !important;
                        div {
                            color: $color-secondary !important;
                        }
                    }
                }
            }
        }
        .my-drag {
            padding-top: 5px;
        }
        .skeleton_row {
            border: 0px !important;
            background-color: white !important;
            padding-top: 0px !important;
            padding-bottom: 0px !important;
            td {
                border: 0px !important;
                background-color: white !important;
                padding-top: 0px !important;
                padding-bottom: 0px !important;
            }
        }
        .MuiTableRow-root.Mui-selected,
        .MuiTableRow-root.Mui-selected:hover {
            background-color: rgba($color-active, 0.08);
        }
        @include respond-below('sm') {
            .MuiTableRow-root.MuiTableRow-hover:hover {
                background-color: $white;
            }
        }
        width: inherit !important;
        thead {
            background-color: $white;
            tr {
                .table-cell-border {
                    width: 15px !important;
                    padding: 0px !important;
                }
                th {
                    height: 42px;
                    border-bottom: 1px solid $bg-color;
                    padding: 0px 10px 0px 10px;
                    &.MuiTableCell-paddingCheckbox {
                        padding: 0px 4px 0px 4px !important;
                    }
                    color: rgba($color-secondary, 0.60);
                    * {
                        color: rgba($color-secondary, 0.60);
                    }
                    &:first-child {
                        padding-left: 10px !important;
                    }
                }
            }
            @include respond-below('sm') {
                display: none;
            }
        }
        tbody {
            tr {
                td {
                    padding: 10px;
                    border-bottom: 1px solid $bg-color;
                    min-width: 200px !important;
                    max-width: 200px !important;
                    &.MuiTableCell-paddingCheckbox {
                        padding: 0px 4px 0px 4px !important;
                    }
                    &:first-child {
                        padding-left: 10px !important;
                        min-width: 100px !important;
                    }
                }
                &:nth-child(even) {
                    background-color: $white;
                }
                &:nth-child(odd) {
                    background-color: $white;
                }
                .table-cell-border {
                    width: 15px !important;
                    padding: 0px !important;
                    background-color: $bg-color;
                }
                .responsive-title {
                    display: none;
                }
            }
            a {
                text-decoration: none;
                color: rgba($color-secondary, 0.20);
                &:hover,
                &:active,
                &.active {
                    color: $color-active;
                }
            }
            .green-link {
                &.active {
                    color: #06D6A0;
                    &:hover {
                        color: rgba($color-secondary, 0.20);
                    }
                }
                &:hover {
                    color: #06D6A0;
                }
                &.no-hover {
                    cursor: default !important;
                    &:not(.active) {
                        color: rgba($color-secondary, 0.20) !important;
                    }
                    &:hover:not(.active) {
                        color: rgba($color-secondary, 0.20) !important;
                    }
                }
            }
            @include respond-below('sm') {
                display: block !important;
                &:before {
                    content: none;
                }
                &:before {
                    content: none;
                }
                .row {
                    margin-right: 0px;
                    margin-left: 0px;
                }
                tr {
                    display: block !important;
                    td {
                        display: block;
                        width: auto !important;
                        border-top: 0px !important;
                        border-right: 0px !important;
                        border-left: 0px !important;
                        border-bottom: 0px !important;
                        span {
                            width: 100% !important;
                            input {
                                width: 100% !important;
                            }
                        }
                    }
                    .table-cell-border {
                        display: none !important;
                    }
                    .responsive-hide {
                        display: none !important;
                    }
                    .datatable-checkbox-style {
                        text-align: center;
                    }
                    .responsive-title {
                        display: block;
                    }
                }
            }
        }
        &.padding-left-0 {
            td {
                padding-left: 0px;
            }
        }
        &.padding-left-5 {
            td {
                padding-left: 5px;
            }
        }
        &.padding-left-20 {
            td {
                padding-left: 20px !important;
            }
        }
        &.padding-right-20 {
            td {
                padding-right: 20px !important;
            }
        }
    }
    &.custom-billing-table-style {
        .my-drag {
            padding-top: 5px;
        }
        .skeleton_row {
            border: 0px !important;
            background-color: white !important;
            padding-top: 0px !important;
            padding-bottom: 0px !important;
            td {
                border: 0px !important;
                background-color: white !important;
                padding-top: 0px !important;
                padding-bottom: 0px !important;
            }
        }
        .MuiTableRow-root.Mui-selected,
        .MuiTableRow-root.Mui-selected:hover {
            background-color: rgba($color-active, 0.08);
        }
        @include respond-below('sm') {
            .MuiTableRow-root.MuiTableRow-hover:hover {
                background-color: $white;
            }
        }
        width: inherit !important;
        thead {
            background-color: $white;
            tr {
                .table-cell-border {
                    width: 15px !important;
                    padding: 0px !important;
                }
                th {
                    height: 42px;
                    border-bottom: 1px solid $bg-color;
                    padding: 0px 10px 0px 10px;
                    &.MuiTableCell-paddingCheckbox {
                        padding: 0px 4px 0px 4px !important;
                    }
                    color: rgba($color-secondary, 0.60);
                    * {
                        color: rgba($color-secondary, 0.60);
                    }
                }
            }
            // @include respond-below('sm') {
            //     display: none;
            // }
        }
        tbody {
            &:after {
                content: "-";
                display: block;
                line-height: 1em;
                color: transparent;
            }
            // &:before {
            //     content:"@";
            //     display:block;
            //     line-height:85px;
            //     text-indent:-99999px;
            //     @include respond-below('xl') {
            //         line-height:10px;
            //     }
            // }
            .datatable-checkbox-style {
                vertical-align: middle !important;
            }
            .custom-expand-details-td {
                vertical-align: middle !important;
            }
            tr {
                td {
                    overflow: visible;
                    z-index: unset;
                    padding: 10px;
                    border-bottom: 0px solid $bg-color;
                    border-bottom: 1px solid rgb(220, 220, 220);
                    &.MuiTableCell-paddingCheckbox {
                        padding: 0px 4px 0px 4px !important;
                    }
                }
                &:nth-child(even) {
                    background-color: $white;
                }
                &:nth-child(odd) {
                    background-color: $white;
                }
                .table-cell-border {
                    width: 15px !important;
                    padding: 0px !important;
                    background-color: $bg-color;
                }
                .responsive-title {
                    display: none;
                }
                &:hover {
                    td {
                        background-color: $color-hover;
                    }
                }
            }
            .no-bottom-border {
                td {
                    background-color: $white;
                }
            }
            .details-row {
                display: none;
                td {
                    border-bottom: 0px;
                }
            }
            .details-last-row {
                td {
                    border-bottom: 20px solid $bg-color;
                }
            }
            .visible-details-row {
                display: table-row;
            }
            a {
                text-decoration: none;
                color: rgba($color-secondary, 0.20);
                &:hover,
                &:active,
                &.active {
                    color: $color-active;
                }
            }
            .green-link {
                &.active {
                    color: #06D6A0;
                    &:hover {
                        color: rgba($color-secondary, 0.20);
                    }
                }
                &:hover {
                    color: #06D6A0;
                }
                &.no-hover {
                    cursor: default !important;
                    &:not(.active) {
                        color: rgba($color-secondary, 0.20) !important;
                    }
                    &:hover:not(.active) {
                        color: rgba($color-secondary, 0.20) !important;
                    }
                }
            }
            // @include respond-below('sm') {
            //     display: block !important;
            //     &:before {
            //         content: none;
            //     }
            //     &:before {
            //         content: none;
            //     }
            //     tr {
            //         display: block !important;
            //         border-top: 15px solid $bg-color !important;
            //         border-bottom: 15px solid $bg-color !important;
            //         td {
            //             display: block;
            //             width: auto !important;
            //             border-style: solid !important;
            //             border-width: 1px !important;
            //             border-color: $bg-color !important;
            //             border-left: 15px solid $bg-color !important;
            //             border-right: 15px solid $bg-color !important;
            //             &:first-child {
            //                 border-top: 0px !important;
            //             }
            //             &:last-child {
            //                 border-bottom: 0px !important;
            //             }
            //         }
            //         .table-cell-border {
            //             display: none !important;
            //         }
            //         .responsive-hide {
            //             display: none !important;
            //         }
            //         .datatable-checkbox-style {
            //             text-align: center;
            //         }
            //         .responsive-title {
            //             display: block;
            //         }
            //     }
            // }
        }
        &.padding-left-0 {
            td {
                padding-left: 0px;
            }
        }
        &.padding-left-5 {
            td {
                padding-left: 5px;
            }
        }
        &.padding-left-20 {
            td {
                padding-left: 20px !important;
            }
        }
        &.padding-right-20 {
            td {
                padding-right: 20px !important;
            }
        }
    }
    &.custom-billing-group-table-style {
        .my-drag {
            padding-top: 5px;
        }
        .datatable-checkbox-style {
            vertical-align: bottom !important;
            >span {
                padding-bottom: 6px;
            }
        }
        .skeleton_row {
            border: 0px !important;
            background-color: white !important;
            padding-top: 0px !important;
            padding-bottom: 0px !important;
            td {
                border: 0px !important;
                background-color: white !important;
                padding-top: 0px !important;
                padding-bottom: 0px !important;
            }
        }
        .MuiTableRow-root.Mui-selected,
        .MuiTableRow-root.Mui-selected:hover {
            background-color: rgba($color-active, 0.08);
            td {
                background-color: rgba($color-active, 0.08);
                border-bottom: 1px solid rgba($color-active, 0.08);
            }
        }
        @include respond-below('sm') {
            .MuiTableRow-root.MuiTableRow-hover:hover {
                background-color: $white;
            }
        }
        thead {
            background-color: $white;
            tr {
                .table-cell-border {
                    width: 15px !important;
                    padding: 0px !important;
                }
                th {
                    height: 42px;
                    border-bottom: 1px solid $bg-color;
                    padding: 0px 10px 0px 10px;
                    &.MuiTableCell-paddingCheckbox {
                        padding: 0px 4px 0px 4px !important;
                    }
                    color: rgba($color-secondary, 0.60);
                    * {
                        color: rgba($color-secondary, 0.60);
                    }
                }
            }
            // @include respond-below('sm') {
            //     display: none;
            // }
        }
        >tbody {
            // &:after {
            //     content: "-";
            //     display: block;
            //     line-height: 1em;
            //     color: transparent;
            // }
            // &:before {
            //     content:"@";
            //     display:block;
            //     line-height:85px;
            //     text-indent:-99999px;
            //     @include respond-below('xl') {
            //         line-height:10px;
            //     }
            // }
            tr {
                .datatable-checkbox-style {
                    vertical-align: middle !important;
                }
                &.details-row {
                    td {
                        overflow: visible;
                        z-index: unset;
                        background-color: $white;
                        border-bottom: 0px;
                        padding: 0px;
                        input {
                            min-height: 30px !important;
                        }
                    }
                }
                td {
                    padding: 7px 5px 7px 5px;
                    background-color: white;
                    &.MuiTableCell-paddingCheckbox {
                        padding: 0px 4px 0px 4px !important;
                    }
                }
                &:nth-child(even) {
                    background-color: $transparent;
                }
                &:nth-child(odd) {
                    background-color: $transparent;
                }
                .table-cell-border {
                    width: 15px !important;
                    padding: 0px !important;
                    background-color: $bg-color;
                }
                .responsive-title {
                    display: none;
                }
            }
            a {
                text-decoration: none;
                color: rgba($color-secondary, 0.20);
                &:hover,
                &:active,
                &.active {
                    color: $color-active;
                }
            }
            .green-link {
                &.active {
                    color: #06D6A0;
                    &:hover {
                        color: rgba($color-secondary, 0.20);
                    }
                }
                &:hover {
                    color: #06D6A0;
                }
                &.no-hover {
                    cursor: default !important;
                    &:not(.active) {
                        color: rgba($color-secondary, 0.20) !important;
                    }
                    &:hover:not(.active) {
                        color: rgba($color-secondary, 0.20) !important;
                    }
                }
            }
            // @include respond-below('sm') {
            //     display: block !important;
            //     &:before {
            //         content: none;
            //     }
            //     &:before {
            //         content: none;
            //     }
            //     tr {
            //         display: block !important;
            //         // border-top: 15px solid $bg-color !important;
            //         // border-bottom: 15px solid $bg-color !important;
            //         td {
            //             display: block;
            //             width: auto !important;
            //             border-style: solid !important;
            //             border-width: 1px !important;
            //             // border-color: $bg-color !important;
            //             border-left: 15px solid $bg-color !important;
            //             border-right: 15px solid $bg-color !important;
            //             &:first-child {
            //                 border-top: 0px !important;
            //             }
            //             &:last-child {
            //                 border-bottom: 0px !important;
            //             }
            //         }
            //         .table-cell-border {
            //             display: none !important;
            //         }
            //         .responsive-hide {
            //             display: none !important;
            //         }
            //         .datatable-checkbox-style {
            //             text-align: center;
            //         }
            //         .responsive-title {
            //             display: block;
            //         }
            //     }
            // }
        }
        &.padding-left-0 {
            td {
                padding-left: 0px;
            }
        }
        &.padding-left-5 {
            td {
                padding-left: 5px;
            }
        }
        &.padding-left-20 {
            td {
                padding-left: 20px !important;
            }
        }
        &.padding-right-20 {
            td {
                padding-right: 20px !important;
            }
        }
    }
    &.custom-billing-group-details-table-style {
        .my-drag {
            padding-top: 5px;
        }
        .datatable-checkbox-style {
            vertical-align: bottom !important;
            >span {
                padding-bottom: 6px;
            }
        }
        .skeleton_row {
            border: 0px !important;
            background-color: white !important;
            padding-top: 0px !important;
            padding-bottom: 0px !important;
            td {
                border: 0px !important;
                background-color: white !important;
                padding-top: 0px !important;
                padding-bottom: 0px !important;
            }
        }
        .MuiTableRow-root.Mui-selected,
        .MuiTableRow-root.Mui-selected:hover {
            background-color: rgba($color-active, 0.08);
            td {
                background-color: rgba($color-active, 0.08);
                border-bottom: 1px solid rgba($color-active, 0.08);
            }
        }
        @include respond-below('sm') {
            .MuiTableRow-root.MuiTableRow-hover:hover {
                background-color: $white;
            }
        }
        width: inherit !important;
        thead {
            background-color: $white;
            tr {
                .table-cell-border {
                    width: 15px !important;
                    padding: 0px !important;
                }
                th {
                    height: 42px;
                    border-bottom: 1px solid $bg-color !important;
                    padding: 0px 10px 0px 10px;
                    &.MuiTableCell-paddingCheckbox {
                        padding: 0px 4px 0px 4px !important;
                    }
                    color: rgba($color-secondary, 0.60);
                    * {
                        color: rgba($color-secondary, 0.60);
                    }
                }
            }
            // @include respond-below('sm') {
            //     display: none;
            // }
        }
        tbody {
            tr {
                .datatable-checkbox-style {
                    vertical-align: middle !important;
                }
                .custom-expand-details-td {
                    vertical-align: middle !important;
                }
                &.details-row {
                    td {
                        overflow: visible;
                        z-index: unset;
                        background-color: $white;
                        border-bottom: 0px;
                        padding: 0px;
                        // input {
                        //     min-height: 30px !important;
                        // }
                    }
                }
                td {
                    padding: 7px 5px 7px 5px;
                    background-color: white;
                    &.MuiTableCell-paddingCheckbox {
                        padding: 0px 4px 0px 4px !important;
                    }
                }
                &:nth-child(even) {
                    background-color: $transparent;
                }
                &:nth-child(odd) {
                    background-color: $transparent;
                }
                .table-cell-border {
                    width: 15px !important;
                    padding: 0px !important;
                    background-color: $bg-color;
                }
                .responsive-title {
                    display: none;
                }
                &:hover {
                    td {
                        background-color: $color-hover;
                    }
                }
            }
            a {
                text-decoration: none;
                color: rgba($color-secondary, 0.20);
                &:hover,
                &:active,
                &.active {
                    color: $color-active;
                }
            }
            .green-link {
                &.active {
                    color: #06D6A0;
                    &:hover {
                        color: rgba($color-secondary, 0.20);
                    }
                }
                &:hover {
                    color: #06D6A0;
                }
                &.no-hover {
                    cursor: default !important;
                    &:not(.active) {
                        color: rgba($color-secondary, 0.20) !important;
                    }
                    &:hover:not(.active) {
                        color: rgba($color-secondary, 0.20) !important;
                    }
                }
            }
            // @include respond-below('sm') {
            //     display: block !important;
            //     &:before {
            //         content: none;
            //     }
            //     &:before {
            //         content: none;
            //     }
            //     tr {
            //         display: block !important;
            //         // border-top: 15px solid $bg-color !important;
            //         // border-bottom: 15px solid $bg-color !important;
            //         td {
            //             display: block;
            //             width: auto !important;
            //             border-style: solid !important;
            //             border-width: 1px !important;
            //             // border-color: $bg-color !important;
            //             border-left: 15px solid $bg-color !important;
            //             border-right: 15px solid $bg-color !important;
            //             &:first-child {
            //                 border-top: 0px !important;
            //             }
            //             &:last-child {
            //                 border-bottom: 0px !important;
            //             }
            //         }
            //         .table-cell-border {
            //             display: none !important;
            //         }
            //         .responsive-hide {
            //             display: none !important;
            //         }
            //         .datatable-checkbox-style {
            //             text-align: center;
            //         }
            //         .responsive-title {
            //             display: block;
            //         }
            //     }
            // }
        }
        &.padding-left-0 {
            td {
                padding-left: 0px;
            }
        }
        &.padding-left-5 {
            td {
                padding-left: 5px;
            }
        }
        &.padding-left-20 {
            td {
                padding-left: 20px !important;
            }
        }
        &.padding-right-20 {
            td {
                padding-right: 20px !important;
            }
        }
    }
    &.custom-empty-table-style {
        .my-drag {
            padding-top: 5px;
        }
        .MuiTableRow-root.Mui-selected,
        .MuiTableRow-root.Mui-selected:hover {
            background-color: $white;
        }
        @include respond-below('sm') {
            .MuiTableRow-root.MuiTableRow-hover:hover {
                background-color: $white;
            }
        }
        thead {
            background-color: $white;
            tr {
                .table-cell-border {
                    width: 3px;
                }
                th {
                    height: 42px;
                    border: 0px;
                    padding: 0px 10px 0px 15px;
                    border-bottom: 1px solid rgba($color-secondary, 0.10);
                    color: rgba($color-secondary, 0.60);
                    * {
                        color: rgba($color-secondary, 0.60);
                    }
                }
            }
            @include respond-below('sm') {
                display: none;
            }
        }
        tbody {
            tr {
                .table-cell-border {
                    width: 3px;
                    background-color: $bg-color;
                }
                .responsive-title {
                    display: none;
                }
                td {
                    padding-top: 10px;
                    padding-bottom: 10px;
                }
            }
            a {
                text-decoration: none;
                color: rgba($color-secondary, 0.20);
                &:hover,
                &:active,
                &.active {
                    color: $color-active;
                }
            }
            .green-link {
                &.active {
                    color: #06D6A0;
                    &:hover {
                        color: rgba($color-secondary, 0.20);
                    }
                }
                &:hover {
                    color: #06D6A0;
                }
                &.no-hover {
                    cursor: default !important;
                    &:not(.active) {
                        color: rgba($color-secondary, 0.20) !important;
                    }
                    &:hover:not(.active) {
                        color: rgba($color-secondary, 0.20) !important;
                    }
                }
            }
            @include respond-below('sm') {
                display: block !important;
                &:before {
                    content: none;
                }
                &:before {
                    content: none;
                }
                tr {
                    display: block !important;
                    border-top: 15px solid $bg-color !important;
                    border-bottom: 15px solid $bg-color !important;
                    td {
                        display: block;
                        width: auto !important;
                        border-style: solid !important;
                        border-width: 1px !important;
                        border-color: $bg-color !important;
                        border-left: 15px solid $bg-color !important;
                        border-right: 15px solid $bg-color !important;
                        &:first-child {
                            border-top: 0px !important;
                        }
                        &:last-child {
                            border-bottom: 0px !important;
                        }
                    }
                    .table-cell-border {
                        display: none !important;
                    }
                    .responsive-hide {
                        display: none !important;
                    }
                    .datatable-checkbox-style {
                        text-align: center;
                    }
                    .responsive-title {
                        display: block;
                    }
                }
            }
        }
    }
    &.bin-type-in {
        thead {
            tr {
                th {
                    height: 42px;
                    border: 0px;
                    padding: 0px 10px 0px 15px;
                    border-bottom: 1px solid rgba($color-secondary, 0.10);
                    border-right: 1px solid rgba($color-secondary, 0.10);
                    &:last-child {
                        border-right: 0px;
                    }
                    color: rgba($color-secondary, 0.60);
                    * {
                        color: rgba($color-secondary, 0.60);
                    }
                }
            }
            @include respond-below('sm') {
                display: none;
            }
        }
        tbody {
            tr {
                .table-cell-border {
                    width: 3px;
                    background-color: $bg-color;
                }
                .responsive-title {
                    display: none;
                }
                td {
                    padding-top: 10px;
                    padding-bottom: 10px;
                }
            }
            a {
                text-decoration: none;
                color: rgba($color-secondary, 0.20);
                &:hover,
                &:active,
                &.active {
                    color: $color-active;
                }
            }
            @include respond-below('sm') {
                display: block !important;
                &:before {
                    content: none;
                }
                &:before {
                    content: none;
                }
                tr {
                    display: block !important;
                    border-top: 15px solid $bg-color !important;
                    border-bottom: 15px solid $bg-color !important;
                    td {
                        display: block;
                        width: auto !important;
                        border-style: solid !important;
                        border-width: 1px !important;
                        border-color: $bg-color !important;
                        border-left: 15px solid $bg-color !important;
                        border-right: 15px solid $bg-color !important;
                        &:first-child {
                            border-top: 0px !important;
                        }
                        &:last-child {
                            border-bottom: 0px !important;
                        }
                    }
                    .table-cell-border {
                        display: none !important;
                    }
                    .responsive-hide {
                        display: none !important;
                    }
                    .datatable-checkbox-style {
                        text-align: center;
                    }
                    .responsive-title {
                        display: block;
                    }
                }
            }
        }
    }
    &.billing-by-site-empty-table-style {
        thead {
            tr {
                th {
                    padding: 0px !important;
                    &:first-child {
                        .MuiCheckbox-root {
                            display: none !important;
                        }
                    }
                }
            }
        }
        tbody {
            tr {
                td {
                    padding: 0px !important;
                    .custom-coll-field {
                        min-height: 40px !important;
                    }
                }
                &.Mui-selected {
                    background-color: transparent !important;
                }
                &.infinite-scroll-loading {
                    display: none !important;
                }
            }
        }
    }
    &.billing-by-site-table-style {
        thead {
            tr {
                th {
                    height: 42px;
                    border: 0px;
                    border-bottom: 1px solid $color-secondary;
                    padding: 0px 10px 0px 10px !important;
                    &:first-child {
                        padding: 0px !important;
                        svg {
                            path {
                                fill: $color-secondary !important;
                            }
                        }
                    }
                    &:last-child {
                        border-right: 0px;
                    }
                    color: rgba($color-secondary, 1);
                    * {
                        color: rgba($color-secondary, 1);
                        font-weight: bold;
                    }
                    &.MuiTableCell-stickyHeader {
                        top: 64px !important;
                    }
                    >div {
                        position: relative !important;
                        .custom-cell-sort {
                            position: relative !important;
                            .custom-cell-sort {
                                position: relative !important;
                                .sort-up {
                                    top: -15px;
                                }
                                .sort-down {
                                    bottom: -15px;
                                }
                                .sort-up1 {}
                                .sort-down1 {}
                            }
                        }
                        .custom-drag {
                            position: relative !important;
                            display: contents;
                        }
                    }
                }
            }
            @include respond-below('sm') {
                display: none;
            }
        }
        tbody {
            tr {
                .table-cell-border {
                    width: 3px;
                    background-color: $bg-color;
                }
                .responsive-title {
                    display: none;
                }
                td {
                    padding-top: 10px;
                    padding-bottom: 10px;
                    border-bottom: 1px solid $color-secondary;
                    font-weight: normal;
                    &:first-child {
                        svg {
                            path {
                                fill: $color-secondary !important;
                            }
                        }
                        .link-default-svg {
                            svg {
                                path {
                                    fill: none !important;
                                }
                            }
                            &:hover {
                                svg {
                                    path {
                                        opacity: 1 !important;
                                        stroke: $color-active !important;
                                        fill: $color-active !important;
                                    }
                                }
                            }
                            &.active {
                                svg {
                                    path {
                                        opacity: 1 !important;
                                        stroke: $color-active !important;
                                        fill: $color-active !important;
                                    }
                                }
                            }
                        }
                    }
                    .Mui-checked {
                        &:first-child {
                            svg {
                                path {
                                    fill: $color-active !important;
                                }
                            }
                        }
                    }
                }
                &:last-child {
                    td {
                        border-bottom: 0px;
                    }
                }
            }
            a {
                text-decoration: none;
                color: rgba($color-secondary, 0.20);
                &:hover,
                &:active,
                &.active {
                    color: $color-active;
                }
            }
            @include respond-below('sm') {
                display: block !important;
                &:before {
                    content: none;
                }
                &:before {
                    content: none;
                }
                tr {
                    display: block !important;
                    border-top: 15px solid $bg-color !important;
                    border-bottom: 15px solid $bg-color !important;
                    td {
                        display: block;
                        width: auto !important;
                        border-style: solid !important;
                        border-width: 1px !important;
                        border-color: $bg-color !important;
                        border-left: 15px solid $bg-color !important;
                        border-right: 15px solid $bg-color !important;
                        &:first-child {
                            border-top: 0px !important;
                        }
                        &:last-child {
                            border-bottom: 0px !important;
                        }
                    }
                    .table-cell-border {
                        display: none !important;
                    }
                    .responsive-hide {
                        display: none !important;
                    }
                    .datatable-checkbox-style {
                        text-align: center;
                    }
                    .responsive-title {
                        display: block;
                    }
                }
            }
        }
        .details-row {
            display: none;
            cursor: default !important;
            td {
                cursor: default !important;
            }
        }
        .exp-details-row {
            display: table-row;
            &:hover {
                background-color: transparent !important;
                >td {
                    background-color: transparent !important;
                }
            }
        }
        .has-expanded-rows {
            tr {
                opacity: 1;
                &.exp-row {
                    td {
                        border-bottom: 1px solid transparent !important;
                    }
                }
                &:not(.exp-row) {
                    opacity: 0.5;
                }
                &.exp-details-row {
                    opacity: 1;
                }
            }
        }
    }
    /**********************************************************************/
    tbody {
        tr {
            &.highlight {
                -moz-animation: blinkingAnim 0.7s 3;
                -o-animation: blinkingAnim 0.7s 3;
                -webkit-animation: blinkingAnim 0.7s 3;
                animation: blinkingAnim 0.7s 3;
            }
            &.highlight-yellow {
                td {
                    -moz-animation: blinkingAnimYellow 0.7s 3;
                    -o-animation: blinkingAnimYellow 0.7s 3;
                    -webkit-animation: blinkingAnimYellow 0.7s 3;
                    animation: blinkingAnimYellow 0.7s 3;
                }
            }
            &.highlight-red {
                td {
                    -moz-animation: blinkingAnimRed 0.7s 3;
                    -o-animation: blinkingAnimRed 0.7s 3;
                    -webkit-animation: blinkingAnimRed 0.7s 3;
                    animation: blinkingAnimRed 0.7s 3;
                }
            }
        }
    }
    &.white {
        tbody {
            tr {
                td {
                    background-color: $white;
                    padding-top: 0px !important;
                    padding-bottom: 0px !important;
                    padding-left: 15px;
                }
            }
            .table-cell-border {
                width: 0px !important;
            }
        }
    }
    .sort-icon {
        cursor: pointer;
        position: relative;
        text-align: center;
        top: 3px;
        padding-left: 0px;
        color: rgba($color-secondary, 0.10) !important;
        &:hover {
            color: rgba($color-secondary, 0.60) !important;
        }
    }
    .sort-icon-active {
        cursor: pointer;
        position: relative;
        text-align: center;
        top: 3px;
        padding-left: 0px;
        color: #287bd99c !important;
        &:hover {
            color: rgba($color-secondary, 0.60) !important;
        }
    }
    .real-link {
        color: $color-active !important;
        text-decoration: underline !important;
        &.underline-none {
            text-decoration: none !important;
        }
        &:hover {
            color: rgba($color-active, 0.60) !important;
        }
    }
    .skeleton_row {
        border: 0px !important;
        background-color: transparent !important;
        padding-top: 0px !important;
        padding-bottom: 0px !important;
        td {
            border: 0px !important;
            background-color: transparent !important;
            padding-top: 0px !important;
            padding-bottom: 0px !important;
        }
    }
    &.td-overflow-visible {
        tr td {
            overflow: visible !important;
        }
    }
    &.no-col-padding {
        tr {
            th {
                padding-left: 2px !important;
                padding-right: 2px !important;
            }
            td {
                padding-left: 2px !important;
                padding-right: 2px !important;
            }
        }
    }
}


/**********************************************************************/

.table-sync-v4 {
    border: 0px;
    
    &.with-details {
        table {
            thead {
                tr {
                    th {
                        border-color: transparent;
                    }
                }
            }
    
            tbody {
                tr {
                    td {
                        height: 50px;
                        border-top: 2px solid #DFDFDF;
                        border-bottom: 1px solid transparent;
                    }

                    &.details_main_row {
                        td{
                            padding-top: 15px !important;
                        }
                    }

                    &.details-row {
                        td {
                            border-color: transparent;
                            height: 30px;
                            font-size: 13px;
                            font-style: italic;
                            color: #7A7A7A;
                            cursor: pointer !important;
                        }

                        &.is-last {
                            td{
                                padding-bottom: 25px !important;
                            }
                        }

                        &:hover {
                            td {
                                background-color: rgba(#6EB104, 0.1) !important;
                            }
                        }
                    }
                    
                    &.hover {
                        td {
                            background-color: rgba(#6EB104, 0.1) !important;
                        }
                    }

                    &.selected {
                        td {
                            background-color: rgba($color-active, 0.08) !important;
                        }
                    }
                }
            }
        }
    }
}

.table-v4 {
    .custom-grip {
        top: 3px;
        left: 1px;
        position: relative;
    }

    .my-drag-head {
        color: rgba($color-secondary, 0.60);
    }
    .my-drag-row {
        color: rgba($color-secondary, 0.60);

        &:hover {
            path {
                fill: $primary;
            }
        }
    }
    
    &.no-hover {
        tbody {
            tr {
                &:hover {
                    >td {
                        background-color: transparent !important;
                    }
                }
            }
        }
    }
}

.table-classic {
    .no-responsive-table {
        thead {
            tr {
                th {
                    border: 1px solid #D9D9D9;
                    padding: 5px !important;
                    
                    div {
                        font-size: 13px !important;
                        font-weight: 400 !important;
                    }
                }
            }
        }

        tbody {
            tr {
                td {
                    height: 50px;
                    padding: 5px !important;
                    border: 1px solid #D9D9D9;
                    
                    div {
                        font-size: 13px !important;
                    }
                    
                    .responsive-title {
                        display: none;
                    }
                }

                &.details-row-report {
                    td {
                        padding: 5px !important;
                        font-size: 13px;
                        font-style: italic !important;
                        color: #7A7A7A !important;
                        cursor: default !important;
                    }

                    // &.is-last {
                    //     td{
                    //         padding-bottom: 25px !important;
                    //     }
                    // }
                }
            }
        }
    }
}

table:not(.no-responsive-table,.ant-picker-content) {
    .MuiTableRow-root.Mui-selected,
    .MuiTableRow-root.Mui-selected:hover {
        background-color: rgba($color-active, 0.08);
    }
    @include respond-below('sm') {
        .MuiTableRow-root.MuiTableRow-hover:hover {
            background-color: $white;
        }
    }
    width: inherit !important;

    &.w-100 {
        width: 100% !important;
    }

    thead {
        tr {
            th {
                background-color: #F7F7F7;
                height: 42px;
                min-width: 100px;
                border: 0px;
                border-bottom: 1px solid #DFDFDF;
                padding: 0px 10px 0px 10px !important;
                padding-left: 10px !important;
                padding-right: 10px !important;
                padding-top: 3px !important;
                padding-bottom: 3px !important;
                &.MuiTableCell-paddingCheckbox {
                    padding: 0px 4px 0px 4px !important;
                }
                color: rgba($color-secondary, 0.60);
                * {
                    color: #484848;
                    font-weight: 400;
                }

                .cursor-grab {
                    text-transform: lowercase;
                    display: inline-block;

                    &:first-letter {
                        text-transform: uppercase;
                    }
                }

                >div {
                    position: relative !important;
                    min-width: 100px;

                    > div {
                        overflow: hidden;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                    }

                    .custom-cell-sort {
                        position: relative !important;
                        left: 5px;
                        width: 10px;
                        height: 22px;
                        text-align: center;
                        .sort-up {
                            position: absolute;
                            top: 0px;
                            left: 0px;
                            width: 12px;
                            height: 12px;
                            overflow: hidden;
                            cursor: pointer;
                            .custom-sort-icon-up {
                                position: absolute;
                                width: 10px;
                                height: 10px;
                                top: -4px;
                                left: -4px;
                                font-size: unset;
                                transform: scale(2) !important;
                                color: rgba($color-secondary, 0.10);
                            }
                            &:hover {
                                i {
                                    color: rgba($color-secondary, 0.60);
                                }
                            }
                        }
                        .sort-down {
                            position: absolute;
                            bottom: 0px;
                            left: 0px;
                            width: 12px;
                            height: 12px;
                            overflow: hidden;
                            cursor: pointer;
                            .custom-sort-icon-down {
                                position: absolute;
                                width: 10px;
                                height: 10px;
                                top: -4px;
                                left: -4px;
                                font-size: unset;
                                transform: scale(2) !important;
                                color: rgba($color-secondary, 0.10);
                            }
                            &:hover {
                                i {
                                    color: rgba($color-secondary, 0.60);
                                }
                            }
                        }
                    }
                    .custom-drag {
                        position: relative !important;
                        display: contents;
                    }
                }
            }
        }
        // @include respond-below('sm') {
        //     display: none !important;
        // }
    }
    tbody {
        .datatable-checkbox-style {
            vertical-align: middle !important;
        }
        .custom-expand-details-td {
            vertical-align: middle !important;
        }
        tr {
            td {
                overflow: visible;
                z-index: unset;
                padding: 10px;
                padding-left: 10px !important;
                padding-right: 10px !important;
                padding-top: 3px !important;
                padding-bottom: 3px !important;
                height: 42px;
                border-bottom: 1px solid #DFDFDF;
                // border-left: 2px solid #DFDFDF;
                // border-right: 2px solid #DFDFDF;
                color: #484848;
                // font-weight: 500;
                &.MuiTableCell-paddingCheckbox {
                    padding: 0px 4px 0px 4px !important;
                }
            }
            &:nth-child(even) {
                background-color: $white;
            }
            &:nth-child(odd) {
                background-color: $white;
            }
            .table-cell-border {
                width: 15px !important;
                padding: 0px !important;
                // background-color: $bg-color;
            }
            .responsive-title {
                display: none;
            }
            &:hover {
                >td {
                    background-color: rgba(#6EB104, 0.1);
                }
            }
        }
    }
    .no-bottom-border {
        td {
            background-color: $white;
        }
    }
    .details-row {
        display: none;
        cursor: default !important;
        border-top: 1px solid #ECECEC;
        border-bottom: 1px solid #ECECEC;
        >td {
            cursor: default !important;
        }
    }
    .exp-details-row {
        display: table-row;
        &:hover {
            background-color: transparent !important;
            >td {
                background-color: transparent !important;
            }
        }
        &.exp-details-color:not(.is-loading) {
            background-color: #F3F7FF87 !important;
            &:hover {
                background-color: #F3F7FF87 !important;
                >td {
                    background-color: #F3F7FF87 !important;
                }
            }
        }
    }
    .has-expanded-rows {
        >tr {
            opacity: 1;
            &.exp-row {
                td {
                    border-bottom: 1px solid transparent !important;
                }
            }
            &:not(.exp-row) {
                opacity: 0.5;
            }
            &.exp-details-row {
                opacity: 1;
            }
        }
    }
}


.custom-cell-line-3 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.table-div--block {
    > div {
        display: block;
    }
}

.responsive-table-md {
    table {
        thead {
            @include respond-below('md') {
                display: none !important;
            }
        }

        tbody {
            tr {
                td {
                    @include respond-below('md') {
                        display: block !important;

                        &:last-child {
                            margin-bottom: 50px;
                        }
                    }
                }

                @include respond-below('md') {
                    display: block !important;
                }
            }
        }
    }
}

.table-v5 {
    .custom-grip {
        top: 3px;
        left: 1px;
        position: relative;
    }

    .my-drag-head {
        color: rgba($color-secondary, 0.60);
    }
    .my-drag-row {
        color: rgba($color-secondary, 0.60);

        &:hover {
            path {
                fill: $primary;
            }
        }
    }
    
    &.no-hover {
        tbody {
            tr {
                &:hover {
                    >td {
                        background-color: transparent !important;
                    }
                }
            }
        }
    }

    .table-style-v5 {
        width: max-content!important;
        min-width: 100% !important;

        tbody {
            tr {
                background-color: #F5F5F5 !important;
                border: 1px solid #DFDFDF !important;

                td {
                    background-color: #F5F5F5 !important;
                }

                &:hover {
                    background-color: rgba(#6EB104, 0.1) !important;
                    >td {
                        background-color: rgba(#6EB104, 0.1) !important;
                    }
                }
            }
        }

        
        .exp-details-row {
            background-color: white !important;
            >td {
                background-color: white !important;
            }

            &:hover {
                background-color: white !important;
                >td {
                    background-color: white !important;
                }
            }
        }
    }
}

/**********************************************************************/

.no-row-currsor {
    table {
        tr {
            td {
                cursor: default !important;
            }
        }
    }
}
.no-selection {
    .MuiTableRow-root.Mui-selected,
    .MuiTableRow-root.Mui-selected:hover {
        background-color: transparent !important;
    }
}


@include respond-below('md') {
    .grip-container {
        // display: none !important;
    }
}

.no-hover-details {
    tbody {
        tr {
            &.details-row {
                &:hover {
                    background-color: white !important;
                    td {
                        background-color: white !important;
                    }
                }
            }
        }
    }
}

.custom-expand-details-th,
.custom-expand-details-td {
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.MuiTableCell-stickyHeader {
    left: auto !important;
}

@keyframes blinkingAnim {
    0% {
        background-color: rgba($color-active, 0.5);
    }
    100% {
        background-color: transparent;
    }
}

@-webkit-keyframes blinkingAnim {
    0% {
        background-color: rgba($color-active, 0.5);
    }
    100% {
        background-color: transparent;
    }
}

@-moz-keyframes blinkingAnim {
    0% {
        background-color: rgba($color-active, 0.5);
    }
    100% {
        background-color: transparent;
    }
}

@-ms-keyframes blinkingAnim {
    0% {
        background-color: rgba($color-active, 0.5);
    }
    100% {
        background-color: transparent;
    }
}

@keyframes blinkingAnimYellow {
    0% {
        background-color: rgba($color-warning, 0.5);
    }
    100% {
        background-color: transparent;
    }
}

@-webkit-keyframes blinkingAnimYellow {
    0% {
        background-color: rgba($color-warning, 0.5);
    }
    100% {
        background-color: transparent;
    }
}

@-moz-keyframes blinkingAnimYellow {
    0% {
        background-color: rgba($color-warning, 0.5);
    }
    100% {
        background-color: transparent;
    }
}

@-ms-keyframes blinkingAnimYellow {
    0% {
        background-color: rgba($color-warning, 0.5);
    }
    100% {
        background-color: transparent;
    }
}

@keyframes blinkingAnimRed {
    0% {
        background-color: rgba($color-danger, 0.5);
    }
    100% {
        background-color: transparent;
    }
}

@-webkit-keyframes blinkingAnimRed {
    0% {
        background-color: rgba($color-danger, 0.5);
    }
    100% {
        background-color: transparent;
    }
}

@-moz-keyframes blinkingAnimRed {
    0% {
        background-color: rgba($color-danger, 0.5);
    }
    100% {
        background-color: transparent;
    }
}

@-ms-keyframes blinkingAnimRed {
    0% {
        background-color: rgba($color-danger, 0.5);
    }
    100% {
        background-color: transparent;
    }
}