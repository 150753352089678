.customer-dropdown-component {
    position: relative;

    > div {
        width: 100%;
    }

    .placeholder {
        position: absolute;
        z-index: 1;
        height: 100%;
        padding-left: 15px;
        line-height: 54px;

        &.none {
            display: none !important;
        }
    }

    .Mui-error {
        border: 1px solid rgba($color-danger, 0.40) !important;
    }

    label.Mui-error {
        border: 0px !important;
    }

    .MuiSelect-select {
        min-height: 38px !important;
        color: $color-secondary !important;
        background-color: $white !important;
        padding-left: 15px !important;
        font-size: $font-size-14 !important;
        line-height: 38px !important;
        border: 1px solid rgba($color-secondary, 0.20) !important;

        &::placeholder {
            font-weight: normal;
            font-style: italic;
            color: rgba($color-secondary, 0.60);
            opacity: 1;
        }

        &[aria-expanded="true"] {
            border: 1px solid rgba($color-secondary, 0.10) !important;
        }
    }

    &.default {
        input, .MuiSelect-select, textarea {
            border: 0px !important;
            min-height: 40px !important;
            color: $color-secondary;
            background-color: #F7F9FD !important;
            padding-left: 15px;
            font-size: $font-size-14;
            line-height: 40px;
    
            &::placeholder {
                font-weight: normal;
                font-style: italic !important;
                color: rgba($color-secondary, 0.60);
                opacity: 1;
            }
    
            &[aria-invalid="true"] {
                border: 1px solid rgba($color-danger, 0.40);
            }
        }

        .placeholder {
            font-size: $font-size-14;
            font-weight: normal;
            font-style: italic !important;
            color: rgba($color-secondary, 0.60);
            cursor: pointer;
            overflow: hidden !important;
        }
    }

    &.job-form-billing {
        input, .MuiSelect-select, textarea {
            border: 0px !important;
            padding: 5px 15px;
            background-color: #F4F8FF !important;
            color: #484848 !important;
            border-radius: 5px;
            padding-top: 0px;
            padding-bottom: 0px;
            height: 50px;
            line-height: 50px !important;
    
            &::placeholder {
                color: rgba(#484848, 0.6) !important;
            }
            
            &[disabled] {
                cursor: not-allowed !important;
                pointer-events: all !important;
            }

            &[aria-invalid="true"] {
                border: 1px solid rgba($color-danger, 0.40);
            }
        }

        .placeholder {
            color: rgba(#484848, 0.6) !important;
        }
    }

    &.billing-group-input {
        height: 30px !important;
        border-bottom: 1px solid rgba($color-secondary, 0.20);

        &.bold {
            input, .MuiSelect-select, textarea {
                font-weight: bold !important;
            }
        }


        input, .MuiSelect-select, textarea {
            border: 0px !important;
            min-height: 20px !important;
            color: $color-secondary;
            background-color: transparent !important;
            padding: 0px;
            padding-left: 0px;
            padding-right: 20px;
            font-size: $font-size-14;
            line-height: 20px;
    
            &::placeholder {
                font-weight: normal;
                font-style: italic !important;
                color: rgba($color-secondary, 0.60);
                opacity: 1;
            }
    
            &[aria-invalid="true"] {
                border: 1px solid rgba($color-danger, 0.40);
            }
        }

        .placeholder {
            font-size: $font-size-14;
            font-weight: normal;
            font-style: italic !important;
            color: rgba($color-secondary, 0.60);
            cursor: pointer;
            overflow: hidden !important;
        }
    }

    .MuiInput-underline:before, .MuiInput-underline:after {
        content: none !important;
    }

    .MuiInputLabel-asterisk {
        display: none;
    }

    &.padding-0 {
        .MuiSelect-select {
            padding-top: 0px !important;
            padding-bottom: 0px !important;
        }

        .placeholder {
            line-height: 40px;
        }
    }

    &.default-no-shadow-bold {
        &.muted-label {
            label {
                font-weight: bold !important;
                color: rgba($color-secondary, 0.60) !important;
            }
            
            .custom-label {
                label {
                    font-weight: bold !important;
                    color: rgba($color-secondary, 0.60) !important;
                }
            }
        }
    }

    &.disabled {
        div {
            cursor: not-allowed !important;
        }
    }

    &.min-h-50 {
        input, .MuiSelect-select, textarea {
            min-height: 53px !important;
            padding-top: 0px !important;
            padding-bottom: 0px !important;
            line-height: 53px !important;
        }

        .placeholder {
            line-height: 53px !important;
        }
    }

    &.min-h-40 {
        input, .MuiSelect-select, textarea {
            min-height: 43px !important;
        }

        .placeholder {
            line-height: 43px !important;
        }
    }

    &.min-w-150 {
        width: 180px !important;
    }
}

.customer-dropdown-menu-component {
    .custom-search {
        margin-bottom: 0px;
        margin-top: 20px;
        
        input {
            padding-left: 15px !important;
            padding-right: 15px !important;
        }

        label {
            &[data-shrink="true"] {
                padding-left: 0px !important;
            }
            &[data-shrink="false"] {
                padding-left: 15px !important;
            }
        }
    }

    ul {
        padding: 5px;

        li {
            min-height: 45px;
        }
        
        .MuiListSubheader-root {
            padding: 0px !important;
            margin-bottom: 10px;

            button {
                background-color: $table-td-bg !important;
                padding-bottom: 0;
                padding-top: 10px;
                padding-bottom: 5px;

                i {
                    color: $color-active;
                }
    
                div {
                    color: $color-active;
                    font-weight: 600;
                }
            }
        }

        .text {
            font-size: $font-size-14;
            color: $color-secondary;
            font-weight: normal;
        }

        .delete-btn {
            .MuiIconButton-label {
                color: rgba($color-secondary, 0.20);
            }

            &:hover {
                .MuiIconButton-label {
                    color: $color-danger;
                }
            }
        }
    }
}