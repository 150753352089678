.jobs-dialog-component {
    // overflow: auto;
    
    .MuiDialog-paper {
        border-radius: 0px !important;
        overflow-y: visible;
        margin: 55px;
        margin-left: 35px;
        margin: 66px auto;

        @include respond-below('md') {
            margin-left: 10px !important;

            .custom-div {
                padding: 10px;
            }
        }

        > div {
            padding: 0px !important;
        }
    }

    .MuiDialog-paperWidthMd {
        max-width: 1366px;
    }

    .custom-tabs-component {
        position: relative;
        margin-top: -48px;

        .custom-tabs {
            > div {
                > div {
                    @include respond-below('lg') {
                        display: block !important;
                    }
                }
            }

            button {
                border-left: 1px outset rgba($black, 0.1) !important;
                border-right: 1px outset rgba($black, 0.1) !important;

                &:first-child {
                    border-left: 0px;
                }
                &:last-child {
                    border-right: 0px;
                }

                span {
                    text-transform: capitalize !important;
                    font-size: $font-size-14 !important;
                    font-weight: 500 !important;
                }

                @include respond-below('lg') {
                    display: block !important;
                    width: 100%;
                    min-width: 100%;
                }

                &.Mui-disabled {
                    span {
                        color: rgba(0, 0, 0, 0.2) !important;
                    }
                }
            }
        }
    }

    .dialog-close-btn-out {
        position: absolute;
        top: -50px;
        right: -15px;
        z-index: 1;
        
        span {
            color: $white;

            svg {
                font-size: 30px;
            }
        }
    }

    .dialog-close-btn-in {
        position: absolute;
        top: 3px;
        right: 3px;
        z-index: 1;
    
        span {
            color: rgba($color-secondary, 0.10);

            svg {
                font-size: 30px;
            }
        }
    }
}