.grantt-chart-page {
    .gantt-chart-header {
        position: sticky;
        top: 65px;
        z-index: 1;
        background-color: #F0F3FA;

        @include respond-below('xl') {
            position: unset;
            top: unset;
        }
    }

    .custom-drag-over {
        border: 1px solid rgba($color-primary, 0.70) !important;
        background-color: rgba($color-primary, 0.30) !important;
    }

    .grantt-chart-page-header {
        box-shadow: 0 5px 10px -1px gray !important;
        margin-bottom: 15px;

        .custom-accordian {
            background-color: transparent;
            border: 0px;
            box-shadow: unset;

            .Mui-focused {
                background-color: transparent;
            }

            .MuiAccordionSummary-root {
                min-height: 50px;
            }

            .MuiAccordionSummary-content {
                margin: 10px
            }
        }

        .custom-unassigned-count {
            background-color: #FFFFFF;
            border-radius: 2px;
            padding: 5px 10px;
            font-size: 12px;
            line-height: 25px;
            margin-left: 10px;
        }

        .custom-wrapper-items {
            max-height: 155px;
            overflow-y: scroll;
            
            .custom-item {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
    
                background-color: #FFFFFF;
                box-shadow: 0 3px 6px 0px #D5D5D5;
                border-radius: 8px;
                padding: 5px;
                
                * {
                    color: rgba(#484848, 0.70);
                }
    
                .custom-time {
                  padding-right: 15px;
                }
          
                .custom-circle {
                  position: relative;
                  display: inline-block;
                  width: 10px;
                  height: 10px;
                  border-radius: 50%;
                  background-color: #484848;
                  margin-left: 10px;
                  margin-right: 10px;
                }

                .custom-watch {
                    position: relative;
                    top: 5px;
                    font-size: 21px;
                    color: #484848;
                    padding-right: 10px;
                  }
            }
        }
    }

    .date-filter-btn {
        border: 1px solid #06D6A0 !important;
        background-color: $white !important;
        border-radius: 5px !important;
        text-decoration: none !important;
        min-height: 47px !important;
        line-height: 15px !important;
        display: inline-block;
        min-width: 30px;
        padding: 0px;
        box-shadow: unset;
        top: 2px;
        margin-right: 5px;

        .MuiButton-label {
            min-width: 30px;
            color: #06D6A0 !important;

            i {
                color: #06D6A0 !important;
                font-size: 35px;
            }
        }

        &:hover {
            background-color: rgba($black, 0.02) !important;
            box-shadow: unset;
        }
    }

    .custom-search-field {
        background-color: #FFFFFF;
        border: 0px solid #F0F3FA;
        padding-left: 10px;

        .MuiInput-underline {
          &:before {
            content: unset;
          }
          &:after {
            content: unset;
          }
        }
    
        button {
          padding: 5px;
        }
    
        input {
          font-size: 14px;
          background-color: #FFFFFF;
          border: 0px solid #FFFFFF;
        }
    }

    button.ganttchart-drivers-btn {
        background-color: transparent !important;
        border: 0px !important;
        border-radius: 0px !important;
        box-shadow: unset !important;
        text-transform: unset !important;
        width: 100% !important;
        justify-content: start;


        .MuiButton-label {
            color: $color-secondary !important;
            text-transform: unset !important;

            i {
                position: relative;
                top: -1px;
                left: 5px;
                color: $color-secondary !important;
            }
        }
    }

    .custom-ganttchart-footer {
        position: fixed;
        bottom: 0px;
        left: 0px;
        width: 100%;
        background-color: #F0F3FA;
        color: #7F7F7F;

        * {
            color: #7F7F7F;
        }
    }
}