.ant-color-picker {
    .ant-color-picker-inner-content {
        .ant-color-picker-slider-container {
            padding: 0px 15px !important;
        }

        .ant-color-picker-input-container {
            padding: 0px 15px 10px 15px !important;
        }
    }
}