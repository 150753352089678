.free-text-tags-imput-component {
    .react-tagsinput {
        border: 0px;
        min-height: 50px !important;
        color: $color-secondary;
        background-color: #F7F9FD !important;
        padding-left: 15px;
        font-size: $font-size-14;

        .react-tagsinput-tag {
            background-color: white;
            border: 1px solid rgba($color-secondary, 0.60);
            color: $color-secondary;
        }

        input:-webkit-autofill,
        input:-webkit-autofill:hover, 
        input:-webkit-autofill:focus { 
            -webkit-box-shadow: 0 0 0px 1000px #F7F9FD inset;
        }

        input {
            width: 100%;
            margin-top: 9px;

            &::placeholder {
                font-weight: normal;
                font-style: italic;
                color: rgba($color-secondary, 0.60);
                opacity: 1;
                font-size: $font-size-14;
            }
        }
    }

    &.job-form-step-in {
        .react-tagsinput {
            border: 0px;
            padding: 5px 15px;
            background-color: #F4F8FF !important;
            color: #484848 !important;
            border-radius: 5px;
            padding-top: 0px;
            padding-bottom: 0px;
            min-height: 50px;
    
            .react-tagsinput-tag {
                position: relative;
                background-color: white;
                min-height: 35px;
                padding-right: 40px;
                margin: 5px;
                border-radius: 5px;
                border: 0px;
                line-height: 25px;

                -webkit-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.16);
                -moz-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.16);
                box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.16);

                color: $color-secondary;
                font-weight: bold;
                font-size: 13px;
                align-items: center;
                padding-left: 10px;
            }
    
            .react-tagsinput-remove {
                text-decoration: none;
                color: $color-danger;

                position: absolute;
                color: #EF476F;
                font-weight: bold;
                font-size: 18px;
                right: 5px;
                align-items: center;
                height: 100%;

                &:hover {
                    opacity: 0.5;
                }
            }

            input:-webkit-autofill,
            input:-webkit-autofill:hover, 
            input:-webkit-autofill:focus { 
                -webkit-box-shadow: 0 0 0px 1000px #F7F9FD inset;
            }
    
            input {
                &::placeholder {
                    color: rgba(#484848, 0.6) !important;
                    font-weight: normal !important;
                    font-style: italic;
                    color: rgba($color-secondary, 0.60) !important;
                    font-size: 14px;
                }
    
                &[disabled] {
                    cursor: not-allowed !important;
                    pointer-events: all !important;
                }
            }
        }
    }

    &.advanced-filter {
        margin: 0 !important;
        padding: 0px;
        overflow: hidden;

        .react-tagsinput {
            min-height: 40px !important;
            border: 0px;
            padding: 5px 15px;
            background-color: white !important;
            color: #484848 !important;
            padding-top: 0px;
            padding-bottom: 0px;
            min-height: 50px;
    
            .react-tagsinput-tag {
                position: relative;
                background-color: white;
                padding-right: 20px;
                margin: 5px;
                border-radius: 5px;
                border: 0px;
                line-height: 25px;

                -webkit-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.16);
                -moz-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.16);
                box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.16);

                color: $color-secondary;
                font-weight: bold;
                font-size: 13px;
                align-items: center;
                padding-left: 10px;
                padding-top: 0;
                padding-bottom: 0;
            }
    
            .react-tagsinput-remove {
                text-decoration: none;
                color: $color-danger;

                position: absolute;
                color: #EF476F;
                font-weight: bold;
                font-size: 18px;
                right: 5px;
                align-items: center;
                height: 100%;

                &:hover {
                    opacity: 0.5;
                }
            }

            input:-webkit-autofill,
            input:-webkit-autofill:hover, 
            input:-webkit-autofill:focus { 
                -webkit-box-shadow: 0 0 0px 1000px #F7F9FD inset;
            }
    
            input {
                margin-top: 6px;
                margin-bottom: 6px;

                &::placeholder {
                    color: rgba($color-secondary, 0.70) !important;
                    font-family: Poppins, Roboto, "Lucida Sans", "Lucida Sans Regular", "Lucida Grande", "Lucida Sans Unicode", Geneva, Verdana, sans-serif !important;
                    font-style: normal;
                    font-size: 1.1rem !important;
                    font-weight: 500 !important;
                    line-height: 1.5 !important;
                }
    
                &[disabled] {
                    cursor: not-allowed !important;
                    pointer-events: all !important;
                }
            }
        }

        &.bigPlaceholder {
            .react-tagsinput {
                input {
                    width: 100% !important;
                }
            }
        }
        
        &.smallPlaceholder {
            .react-tagsinput {
                input {
                    width: auto !important;
                }
            }
        }
    }

    &.custom-free-text-tag {
        margin: 0 !important;
        padding: 0px;
        overflow: hidden;

        .react-tagsinput {
            min-height: 40px !important;
            border: 0px;
            padding: 5px 15px;
            background-color: white !important;
            color: #484848 !important;
            padding-top: 0px;
            padding-bottom: 0px;
            min-height: 50px;
    
            .react-tagsinput-tag {
                position: relative;
                background-color: white;
                padding-right: 20px;
                margin: 5px;
                border-radius: 5px;
                border: 0px;
                line-height: 25px;

                -webkit-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.16);
                -moz-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.16);
                box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.16);

                color: $color-secondary;
                font-weight: bold;
                font-size: 13px;
                align-items: center;
                padding-left: 10px;
                padding-top: 0;
                padding-bottom: 0;
            }
    
            .react-tagsinput-remove {
                text-decoration: none;
                color: $color-danger;

                position: absolute;
                color: #EF476F;
                font-weight: bold;
                font-size: 18px;
                right: 5px;
                top: 1px;
                align-items: center;
                height: 100%;

                &:hover {
                    opacity: 0.5;
                }
            }

            input:-webkit-autofill,
            input:-webkit-autofill:hover, 
            input:-webkit-autofill:focus { 
                -webkit-box-shadow: 0 0 0px 1000px #F7F9FD inset;
            }
    
            input {
                margin-top: 6px;
                margin-bottom: 6px;

                &::placeholder {
                    color: rgba($color-secondary, 0.70) !important;
                    font-family: Poppins, Roboto, "Lucida Sans", "Lucida Sans Regular", "Lucida Grande", "Lucida Sans Unicode", Geneva, Verdana, sans-serif !important;
                    font-style: normal;
                    font-size: 1.1rem !important;
                    font-weight: 500 !important;
                    line-height: 1.5 !important;
                }
    
                &[disabled] {
                    cursor: not-allowed !important;
                    pointer-events: all !important;
                }
            }
        }

        &.bigPlaceholder {
            .react-tagsinput {
                input {
                    width: 100% !important;
                }
            }
        }
        
        &.smallPlaceholder {
            .react-tagsinput {
                input {
                    width: auto !important;
                }
            }
        }

        &--green {
            .react-tagsinput {
                .react-tagsinput-tag {
                    background-color: #F0F7E5;
                    border-radius: 0px !important;
                    border: 1px solid #F0F7E5;
                }
            }
        }

        &--blue {
            .react-tagsinput {
                .react-tagsinput-tag {
                    background-color: #E7EEFF;
                    border-radius: 0px !important;
                    border: 1px solid #E7EEFF;
                }
            }
        }
    }

    &.customer-email-group {
        .react-tagsinput {
            .react-tagsinput-remove {
                color: #EF476F;
                text-decoration: none;
                
                &:hover {
                    opacity: 0.5;
                }
            }
        }
    }

    &.error {
        .react-tagsinput {
            border: 1px solid rgba($color-danger, 0.40);
        }
    }
}