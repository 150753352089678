.custom-menu-sub-items-component {
    .custom-btn {
        .MuiButton-label {
            text-transform: none;
            color: $color-secondary;

            .custom-span-text {
                color: $color-secondary;
            }
            .custom-span-icon {
                color: $color-secondary;
                display: flex;
            }
        }

        &.disabled {
            .MuiButton-label {
                color: rgba(#7F7F7F, 0.45);
            }
        }
    }

    &.job-form-status {
        .custom-btn {
            width: 100%;
            height: 50px;
            background-color: #FFFFFF;
            
            .MuiButton-label {
                color: $color-secondary;
                display: flex;

                .custom-span-text {
                    color: $color-secondary;
                    display: flex;
                    width: 100%;
                }
                .custom-span-icon {
                    color: $color-secondary;
                    display: flex;
                }
            }

            &.Mui-disabled {
                background-color: #FFFFFF;
                cursor: not-allowed !important;

                .MuiButton-label {
                    cursor: not-allowed !important;
                    pointer-events: auto;
                }
            }
        }
    }

    &.job-form-site-address {
        .custom-btn {
            width: 100%;
            height: 21px;
            padding: 0px;
            border-radius: 0px;
            background-color: #FFFFFF;
            border-bottom: 1px solid #E5E5E5;
            padding-bottom: 5px;
            
            .MuiButton-label {
                color: $color-secondary;
                display: flex;

                .custom-span-text {
                    color: $color-secondary;
                    font-weight: bold;
                    display: flex;
                    width: 100%;
                }
                .custom-span-icon {
                    color: $color-secondary;
                    display: flex;
                    position: relative;
                    left: 5px;
                }
            }

            &.Mui-disabled {
                cursor: not-allowed !important;

                .custom-span-text {
                    pointer-events: auto;
                }
            }
        }
    }
}

.custom-dropdown-menu-sub-items-component {
    padding: 0px;

    .MuiCollapse-entered {
        display: none;
    }

    > div {
        padding: 5px 7px;

        &:hover {
            > .MuiCollapse-entered {
                display: block;

                ul {
                    > div {
                        padding: 2px 10px;
                        
                        &:hover {
                            > .MuiCollapse-entered {
                                display: block;
                            }
                        }

                        // &:active {
                        //     > .MuiCollapse-entered {
                        //         display: none;
                        //     }
                        // }
                    }
                }
            }
        }

        // &:active {
        //     > .MuiCollapse-entered {
        //         display: none;
        //     }
        // }
    }
}


.custom-icon-button-dropdown-componenet {
    .custom-button {
        .MuiIconButton-label {
            i {
                color: $color-active;
            }
        }

        &:hover {
            background-color: rgba($color-active, 0.2);
        }
    }

    &.active {
        .custom-button {
            background-color: $color-active;

            .MuiIconButton-label {
                i {
                    color: white;
                }
            }

            &:hover {
                background-color: rgba($color-active, 0.2);

                .MuiIconButton-label {
                    i {
                        color: $color-active;
                    }
                }
            }
        }
    }

    &.job-list-icon-button-dropdown {
        display: inline-block;

        .custom-button {
            .MuiIconButton-label {
                i {
                    color: $color-active;
                }
            }

            &:hover {
                background-color: rgba(0, 0, 0, 0.04);
            }

            svg {
                width: 15px;
                height: 15px;
            }
        }
    
        &.active {
            .custom-button {
                .MuiIconButton-label {
                    i {
                        color: white;
                    }
                }
    
                &:hover {
                    .MuiIconButton-label {
                        i {
                            color: $color-active;
                        }
                    }
                }
            }
        }
    }
}
.custom-menu {
    &.job-type-icon-button-dropdown {
        .Mui-selected {
            background-color: $color-active;
            color: white;

            &:hover {
                background-color: rgba($color-active, 0.8);
                color: white;
            }
        }

        &.Mui-disabled {
            cursor: not-allowed !important;
        }
    }
    &.job-list-icon-button-dropdown {
        .Mui-selected {
            background-color: $color-active;
            color: white;

            &:hover {
                background-color: rgba($color-active, 0.8);
                color: white;
            }
        }

        &.Mui-disabled {
            cursor: not-allowed !important;
        }
    }
}

.custom-item-disabled {
    color: rgba(#7F7F7F, 0.45) !important;
    
    .text-red {
        color: rgba(#7F7F7F, 0.45) !important;
    }
}


.custom-paper {
    &.job-form-status {
        min-width: 300px;
    }
}