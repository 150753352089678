.rich-text-editor-component {
    position: relative;
    // padding: 0px;
    overflow: hidden;

    &.icon {
        .custom-icon {
            position: absolute;
            right: 10px;
            top: 55%;
            color: $color-active;
        }

        .editor-class {
            padding-right: 35px;
        }
    }

    // .toolbar-class {
    //     background-color: #F8F8F8;
    // }

    .editor-class {
        min-height: 160px;
        background-color: #F7F9FD;
        padding: 10px;

        .public-DraftStyleDefault-block {
            margin: 0px !important;
        }

        .public-DraftEditorPlaceholder-inner {
            color: rgba($color-secondary, 0.60) !important;
        }
    }

    &.min-height-50 {
        .editor-class {
            min-height: 50px;
        }
    }

    .rdw-link-modal {
        height: auto !important;
    }
}

.tre-text {
    margin: 0;
    
    * {
        margin: 0;
    }
}