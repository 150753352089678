@mixin tag-custom-variant($background, $color, $border) {
  border-radius: 4px !important;
  padding: 2px 8px !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  line-height: 18px !important;

  background-color: $background !important;
  color: $color !important;
  border: 1px solid $border !important;

  &.btn-lg {
    padding: 6px 10px !important;
    border-radius: 6px !important;
    font-size: 14px !important;
    font-weight: 400 !important;
  }
}


$custom-primary-bg-color: $blue-focus1;
$custom-primary-color: $blue-400;
$custom-primary-border: $transparent;
.badge.bg-#{$custom-prefix}-primary {
  @include tag-custom-variant(
    $custom-primary-bg-color,
    $custom-primary-color,
    $custom-primary-border,
  );
}

$custom-primary-bg-color: $blue-focus1;
$custom-primary-color: $blue-400;
$custom-primary-border: $blue-50;
.badge.bg-#{$custom-prefix}-border-primary {
  @include tag-custom-variant(
    $custom-primary-bg-color,
    $custom-primary-color,
    $custom-primary-border,
  );
}



$custom-success-bg-color: $green-focus1;
$custom-success-color: $green-400;
$custom-success-border: $transparent;
.badge.bg-#{$custom-prefix}-success {
  @include tag-custom-variant(
    $custom-success-bg-color,
    $custom-success-color,
    $custom-success-border,
  );
}

$custom-success-bg-color: $green-focus1;
$custom-success-color: $green-400;
$custom-success-border: $green-50;
.badge.bg-#{$custom-prefix}-border-success {
  @include tag-custom-variant(
    $custom-success-bg-color,
    $custom-success-color,
    $custom-success-border,
  );
}



$custom-warning-bg-color: $yellow-focus1;
$custom-warning-color: $yellow-400;
$custom-warning-border: $transparent;
.badge.bg-#{$custom-prefix}-warning {
  @include tag-custom-variant(
    $custom-warning-bg-color,
    $custom-warning-color,
    $custom-warning-border,
  );
}

$custom-warning-bg-color: $yellow-focus1;
$custom-warning-color: $yellow-400;
$custom-warning-border: $yellow-50;
.badge.bg-#{$custom-prefix}-border-warning {
  @include tag-custom-variant(
    $custom-warning-bg-color,
    $custom-warning-color,
    $custom-warning-border,
  );
}



$custom-danger-bg-color: $red-focus1;
$custom-danger-color: $red-400;
$custom-danger-border: $transparent;
.badge.bg-#{$custom-prefix}-danger {
  @include tag-custom-variant(
    $custom-danger-bg-color,
    $custom-danger-color,
    $custom-danger-border,
  );
}

$custom-danger-bg-color: $red-focus2;
$custom-danger-color: $red-400;
$custom-danger-border: $red-50;
.badge.bg-#{$custom-prefix}-border-danger {
  @include tag-custom-variant(
    $custom-danger-bg-color,
    $custom-danger-color,
    $custom-danger-border,
  );
}



$custom-info-bg-color: $cyan-focus1;
$custom-info-color: $cyan-400;
$custom-info-border: $transparent;
.badge.bg-#{$custom-prefix}-info {
  @include tag-custom-variant(
    $custom-info-bg-color,
    $custom-info-color,
    $custom-info-border,
  );
}

$custom-info-bg-color: $cyan-focus1;
$custom-info-color: $cyan-400;
$custom-info-border: $cyan-50;
.badge.bg-#{$custom-prefix}-border-info {
  @include tag-custom-variant(
    $custom-info-bg-color,
    $custom-info-color,
    $custom-info-border,
  );
}



$custom-defalut-bg-color: $gray-100;
$custom-defalut-color: $gray-700;
$custom-defalut-border: $transparent;
.badge.bg-#{$custom-prefix}-defalut {
  @include tag-custom-variant(
    $custom-defalut-bg-color,
    $custom-defalut-color,
    $custom-defalut-border,
  );
}

$custom-defalut-bg-color: $gray-100;
$custom-defalut-color: $gray-700;
$custom-defalut-border: $gray-300;
.badge.bg-#{$custom-prefix}-border-defalut {
  @include tag-custom-variant(
    $custom-defalut-bg-color,
    $custom-defalut-color,
    $custom-defalut-border,
  );
}



$custom-defalut-bg-color: $white;
$custom-defalut-color: $gray-800;
$custom-defalut-border: $transparent;
.badge.bg-#{$custom-prefix}-light {
  @include tag-custom-variant(
    $custom-defalut-bg-color,
    $custom-defalut-color,
    $custom-defalut-border,
  );
}

$custom-defalut-bg-color: $white;
$custom-defalut-color: $gray-800;
$custom-defalut-border: $gray-300;
.badge.bg-#{$custom-prefix}-border-light {
  @include tag-custom-variant(
    $custom-defalut-bg-color,
    $custom-defalut-color,
    $custom-defalut-border,
  );
}