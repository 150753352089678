.custom-collapse-component {
    &.job-steps-row {
        border: 1px solid #EEEFF2;
        border-radius: 6px;
        margin-bottom: 20px;

        .exp-btn {
            position: relative;
            top: 3px;
        }

        .custom-collapse-item {
            min-height: 56px;
            display: flex;
            align-items: center;
        }

        .custom-collapse-details {
            min-height: 56px;
            background-color: #FAFBFC;
        }
    }

    &.custom-company-list-collapse {
        .custom-collapse-item {
            .custom-card-component {
                border-top-left-radius: 6px;
                border-top-right-radius: 6px;
                border-bottom-left-radius: 6px;
                border-bottom-right-radius: 6px;
            }
        }
        
        .manage-company-list-details-component {
            .custom-card-component {
                border-top-left-radius: 0px;
                border-top-right-radius: 0px;
                border-bottom-left-radius: 6px;
                border-bottom-right-radius: 6px;
                margin-top: 0px;
            }
        }

        &.open {
            .custom-collapse-item {
                .custom-card-component {
                    border-bottom-left-radius: 0px;
                    border-bottom-right-radius: 0px;
                    margin-bottom: 0px;
                    // border-bottom: 1px solid #F4F8FF;
                }
            }
        }
    }
}