.plus-minus-button {
    user-select: none;
    
    .input-group-text {
        padding: 5px 8px !important;
        cursor: pointer;

        svg {
            position: relative;
            top: -2px;
        }

        &:hover {
            svg {
                path {
                    stroke: var(--bs-gray-900) !important;
                }
            }
        }

        &:first-child ~ .form-control.custom {
            padding-left: 30px !important;
        }
    }

    .form-control {
        width: 80px;
        padding-right: 30px !important;
        text-align: center;
        padding-top: 4px !important;
        padding-bottom: 2px !important;
        min-height: 32px !important;

        &.custom {
            padding-right: 30px !important;
        }
    }
}




.plus-minus-button-start {
    user-select: none;
    
    .input-group-text {
        padding-left: 0px;
        padding-right: 0px;

        &>span {
            cursor: pointer;

            &>svg {
                position: relative;
                top: -2px;
                margin: 4px 4px !important;
        
                path {
                    stroke: var(--bs-gray-500) !important;
                }
            }
            
        
            &:hover {
                &>svg {
                    path {
                        stroke: var(--bs-gray-900) !important;
                    }
                }
            }
        }
    }

    .form-control {
        width: 80px;
        padding-right: 45px !important;
        text-align: left;
        padding-top: 4px !important;
        padding-bottom: 2px !important;
        min-height: 35px !important;

        &.custom {
            padding-right: 45px !important;
        }
    }
}