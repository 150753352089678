.Toastify {
    .Toastify__toast-container {
        min-width: 390px;
        .Toastify__toast {
            padding: 16px;
            box-shadow: $shadow2;
            border: 0px;

            .Toastify__toast-body {
                color: $gray-800;
                font-size: 12px;
        
                .Toastify__toast-icon {
                    margin-right: 12px;
                    width: 28px;
                }

                .title {
                    color: $gray-900;
                    font-size: 16px;
                    font-weight: 600;
                    margin-bottom: 4px;
                }
            }

            .Toastify__close-button {
                opacity: 1;
                margin-left: 12px;

                svg {
                    path {
                        fill: $gray-900;
                    }
                }

                &:hover {
                    opacity: 0.5;;
                }
            }
        }
    }
}