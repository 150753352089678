.custom-toggle-component {
    position: relative;

    .custom-wrapper {
        align-items: center;
        justify-content: center;
        display: flex;
        width: 53px;
        min-width: 80px;
        min-height: 33px;

        padding: 0px 15px;
        
        border-radius: 20px;
        border: 1px solid $color-secondary;

        .custom-text, .custom-text span {
            display: inline-block;
            user-select: none;
            color: $color-secondary;
            width: 40px;
            padding-right: 10px;
        }

        .custom-btns {
            display: inline-block;
            color: $color-secondary;
            cursor: pointer;
            position: absolute;
            right: 8px;

            i {
                position: relative;
                font-size: 18px;
            }

            .custom-up {
                position: relative;
                display: flex;

                i {
                    top: 4px;

                    &:hover {
                        opacity: 0.6;
                    }
                }
            }

            .custom-down {
                position: relative;
                display: flex;

                i {
                    bottom: 4px;

                    &:hover {
                        opacity: 0.6;
                    }
                }
            }
        }
    }

    // &.custom-options {
        .custom-wrapper {
            width: auto;

            .custom-text, .custom-text span {
                width: auto;
                min-width: 25px;
            }
        }
    // }

    &.primary {
        .custom-wrapper {
            border: 1px solid $color-secondary; //$color-active
            cursor: pointer;
    
            .custom-text, .custom-text span {
                color: $color-secondary; //$color-active
            }
    
            .custom-btns {
                display: inline-block;
    
                i {
                    color: $color-secondary; //$color-active

                    &:hover {
                        opacity: 0.6;
                    }
                }
            }
        }
    }

    &.danger {
        .custom-wrapper {
            border: 1px solid $color-secondary; //$color-danger
            cursor: pointer;
    
            .custom-text, .custom-text span {
                color: $color-secondary; //$color-danger
            }
    
            .custom-btns {
                display: inline-block;
    
                i {
                    color: $color-secondary; //$color-danger

                    &:hover {
                        opacity: 0.6;
                    }
                }
            }
        }
    }

    &.dark {
        .custom-wrapper {
            border: 1px solid $color-secondary;
            cursor: pointer;
    
            .custom-text, .custom-text span {
                color: $color-secondary;
            }
    
            .custom-btns {
                display: inline-block;
    
                i {
                    color: $color-secondary;

                    &:hover {
                        opacity: 0.6;
                    }
                }
            }
        }
    }

    &.disabled {
        .custom-wrapper {
            cursor: not-allowed;
            border: 1px solid #CCCCCC;
        }
    }


    &.custom-input {
        .custom-wrapper {
            width: 100%;
            border: 0px;
            padding: 5px 15px;
            background-color: #F4F8FF;
            border-radius: 5px;
            padding-top: 0px;
            padding-bottom: 0px;
            height: 50px;

            .custom-text, .custom-text span {
                width: 100%;
                color: #484848;
            }

            .custom-btns {
                i {
                    color: #484848;
                }
            }
        }
    }

    &.custom-default-input {
        .custom-wrapper {
            width: 100%;
            border: 0px;
            padding: 5px 15px;
            background-color: #F7F9FD;
            border-radius: 0px;
            padding-top: 0px;
            padding-bottom: 0px;
            height: 50px;

            .custom-text, .custom-text span {
                width: 100%;
                color: #484848;
            }

            .custom-btns {
                i {
                    color: #484848;
                }
            }
        }
    }
}