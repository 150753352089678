.custom-range-component {
    border-radius: 5px !important;
    padding-left: 15px;
    padding-right: 15px;

    @include respond-below('md') {
        height: 50px;
        padding-left: 25px;
        padding-right: 25px;
    }

    .custom-render-track {
        height: 12px;
        width: 100%;
        background-color: $bg-color;
        border-radius: 5px !important;
    }

    .custom-render-thumb {
        appearance: none;
        background: white;
        border: 1px solid rgba(0, 0, 0, 0.16);
        border-radius: 100%;
        cursor: pointer;
        display: block;
        outline: none;
        width: 20px;
        height: 20px;
        -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16) !important;
        -moz-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16) !important;
        box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16) !important;

        .custom-render-thumb-child {
            position: relative;
            color: $color-active;
            background-color: transparent;
            text-align: center;
            padding: 3px 10px;
            top: 25px;
            font-weight: bold;

            /* Depends on label */
            width: 90px;
            left: -35px;

            &:hover {
                color: $color-active !important;
                background-color: transparent !important;
            }

            &.active {
                color: white !important;
                background-color: $color-active !important;
                border-radius: 5px;
            }

            &.error {
                color: $color-danger !important;
            }

            .custom-render-thumb-child-input {
                position: relative;
                width: 44px;
                // width: 70px;
                // padding-right: 25px;
                border: 1px solid rgba($color-secondary, 0.3);
                border-radius: 5px;
                text-align: center;
                outline: unset;

                &::placeholder {
                    color: $color-active !important;
                }
            }

            .custom-render-thumb-child-txt {
                color: $color-active;
                background-color: white;
                padding-top: 3px;
                padding-bottom: 3px;
            }
        }
        
        &.active {
            background: $color-active !important;

            &.error {
                background: $color-danger !important;
            }

            .custom-render-thumb-child {
                .custom-render-thumb-child-txt {
                    color: white !important;
                    background-color: transparent;
                    padding-top: 3px;
                    padding-bottom: 3px;
                }

                &.error {
                    color: white !important;
                    background-color: $color-danger !important;
                    border-radius: 5px;
                }

                .custom-render-thumb-child-input {
                    color: $color-active !important;
                }
            }
        }
    }
}