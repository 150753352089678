.new-range-date-picker-component {
    .rdrDefinedRangesWrapper {
        display: none;
    }
    .rdrDateDisplayWrapper {
        // display: none;
    }
    .rdrMonths {
        @include respond-below('sm') {
            display: table !important;
        }

        .rdrMonth {
            width: 19em;

            @include respond-below('sm') {
                display: block !important;
            }
        }
    }

    @include respond-below('sm') {
        .rdrMonth {
            margin: 0 auto;
        }

        * {
            font-size: 12px !important;
        }
    }
}

.new-range-dropdown {
    top: 140px !important;
    right: 10px;
    left: unset !important;
    transform: unset !important;

    @include respond-below('sm') {
        top: 10px !important;
        left: 10px !important;
        right: unset !important;
        transform: unset !important;
    }
}