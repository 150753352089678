.custom-icon-button {
    cursor: pointer;
    position: relative;
    color: $color-secondary;

    &:hover, &:focus, &:active {
        color: $color-active;
        opacity: 1 !important;
    }

    &.danger {
        color: $color-secondary;

        &:hover, &:focus, &:active {
            color: $color-danger;
            opacity: 1 !important;
        }
    }

    &.warning {
        color: $color-warning;

        &:hover, &:focus, &:active {
            color: $color-warning;
            opacity: 1 !important;
        }
    }
}