.custom-swich-component {
    .react-switch-bg {
        div {
            &:first-child {
                span {
                    position: absolute;
                    top: 9px;
                    left: 15px;
                    font-weight: bold;
                    color: $white;
                }
            }
    
            &:last-child {
                span {
                    position: absolute;
                    top: 9px;
                    left: 10px;
                    font-weight: bold;
                    color: $color-secondary;
                }
            }
        }
    }

    &.align-center {
        align-items: center;
        display: flex;
    }

    .custom-extra-text {
        padding-left: 15px;
    }

    &.disabled {
        div {
            cursor: not-allowed !important;
        }
    }


    &.default-switch {
        .react-switch-handle {
            background-color: $color-secondary !important;
        }

        &.active {
            .react-switch-handle {
                background-color: $color-active !important;
            }
        }
    }

}