.files-dropzone-component {
    .container {
        padding: 0px;
    }

    &.mw-250 {
        min-width: 250px;
    }
    &.max-w-250 {
        max-width: 250px;
    }
    
    .dropzone-container {
        &:focus {
            outline: none;
        }

        .dropzone {
            cursor: pointer;
            background-repeat: no-repeat;
            background-position: center center;
            background-color: $table-td-bg;
    
            padding-top: 196px;
            padding-bottom: 15px;
        }
    }
    
    .dropzone-files-item {
        cursor: pointer;
        position: relative;
        height: 100%;
        overflow: hidden;

        &.grayscale {
            .dropzone-image {
                filter: grayscale(1);
                -webkit-filter: grayscale(1);
            }
        }
        &.blur {
            .dropzone-image {
                filter: blur(100px);
                -webkit-filter: blur(100px);
            }
        }

        .dropzone-remove-file {
            button {
                position: absolute;
                right: 5px;
                top: 5px;
                display: none;

                &:hover {
                    .material-icons {
                        color: $color-danger;
                    }
                }
            }
        }

        .dropzone-progress-bar {
            position: absolute;
            width: 100%;
            height: 100%;
            z-index: 1;
        }

        .dropzone-image {
            height: 100%;
            min-height: 200px;

            @for $i from 0 through 100 {
                &.grayscale-#{$i} {
                    filter: grayscale(round(percentage((100 - $i)/100))) !important;
                    -webkit-filter: grayscale(round(percentage((100 - $i)/100))) !important;
                }

                &.blur-#{$i} {
                    filter: blur((100 - $i) + px) !important;
                    -webkit-filter: blur((100 - $i) + px) !important;
                }
            }
        }

        .dropzone-details {
            position: absolute;
            font-size: 12px;
            bottom: 0;
            background-color: rgba($black, 0.4);
            color: $white;
            width: 100%;
            padding-left: 5px;
            padding-right: 5px;
            display: none;
        }

        &:hover {
            button {
                display: block;
            }
            .dropzone-details {
                display: block;
            }
        }
    }

    .bg-size-unset {
        background-size: unset !important;
    }

    &.disabled {
        cursor: unset;
        // background-color: $table-td-bg;

        .dropzone-container {
            cursor: unset;
            
            .dropzone {
                cursor: unset;
                opacity: 0.3;
            }
        }
    }



    &.job-form-files-dropzone-signature {
        .dropzone-container {
            .dropzone {
                background-color: transparent;
                border-bottom: 1px solid #E5E5E5;
            }
        }
    }

    &.job-form-files-dropzone-images {
        .dropzone-container {
            .dropzone {
                height: 200px;

                padding-top: unset;
                padding-bottom: unset;

                @include respond-below('lg') {
                    height: 200px;
                }

                @include respond-below('md') {
                    height: 200px;
                }

                @include respond-below('sm') {
                    height: 200px;
                }
            }
        }

        .dropzone-files-item {
            height: 200px;
            
            .dropzone-image {
     
                height: 200px;
                background-color: transparent;
                border: 1px solid #dedede47 !important;
                background-size: contain !important;
                @include respond-below('lg') {
                    height: 200px;
                }

                @include respond-below('md') {
                    height: 200px;
                }

                @include respond-below('sm') {
                    height: 200px;
                }
            }
        }
    }

    &.job-form-files-dropzone-images-inline {
        .dropzone-container {
            .dropzone {
                height: 200px;

                padding-top: unset;
                padding-bottom: unset;

                background-color: transparent !important;
                background-size: 100px !important;

                @include respond-below('lg') {
                    height: 200px;
                }

                @include respond-below('md') {
                    height: 200px;
                }

                @include respond-below('sm') {
                    height: 200px;
                }
            }
        }

        .dropzone-files-item {
            height: 200px;
            
            .dropzone-image {
     
                height: 200px;
                background-color: transparent;
                border: 1px solid #dedede47 !important;
                background-size: contain !important;
                @include respond-below('lg') {
                    height: 200px;
                }

                @include respond-below('md') {
                    height: 200px;
                }

                @include respond-below('sm') {
                    height: 200px;
                }
            }
        }
    }
}