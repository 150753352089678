.hide-backdrop-overlay {
    background-color: transparent;
}

.offcanvas {
    @media (max-width: 991.98px) {
        width: 100% !important;
    }

    .offcanvas-header {
        background-color: $blue-focus1;
        padding: 26px 24px;

        .offcanvas-title {
            color: $gray-900;
            font-weight: 500;
            font-size: 20px;
        }

        .btn-close {
            color: $gray-900;
            background-size: 10px;
            opacity: 1;

            &:hover {
                opacity: 0.5;
            }

            &:focus, &:focus-visible {
                box-shadow: unset !important;
            }
        }
    }
    
    .offcanvas-body {
        padding: 24px;

        .sticky {
            @media (min-width: 991.98px) {
                overflow: auto;
                height: calc(100vh - 155px);
            }
            
            .modal-body {
                @media (min-width: 991.98px) {
                    position: sticky;
                    top: 0;
                    height: unset;
                }
            }

            .modal-footer {
                @media (min-width: 991.98px) {
                    position: fixed;
                    bottom: 0;
                    width: 100%;
                }
            }
        }
    }

    .modal-body {
        padding: 24px;

        @media (min-width: 991.98px) {
            overflow: auto;
            height: calc(100vh - 85px);
        }

        &-inner {
            @media (min-width: 991.98px) {
                overflow: auto;
                height: calc(100vh - 156px) !important;
            }
        }
    }

    .modal-footer {
        padding: 20px 24px;
    }
}