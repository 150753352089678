
.ant-radio-wrapper {
  .ant-radio {
    &.ant-radio-checked {
      .ant-radio-inner {
        background-color: white;
  
        &::after {
          background-color: $blue-500;
          width: 22px;
          height: 22px;
          left: 4px;
          top: 4px;
        }
      }
    }
    // .ant-radio-input {}
  
    .ant-radio-inner {
      border-color: $gray-300;
    }
  }
}