.tags-imput-component {
    > div {
        border: 0px !important;
        background-color: transparent !important;
        padding: 0px !important;

        div[role="combobox"] {
            flex: 1 1 50% !important;
        }

        .custom-chip {
            position: relative;
            background-color: $table-td-bg;
            padding: 10px 20px 10px 20px;
            margin-right: 10px;
            margin-bottom: 10px;
            width: 18%;
            
            @include respond-below('md') {
                width: 18%;
            }
            @include respond-below('sm') {
                width: 24%;
            }
            @include respond-below('xs') {
                width: 90%;
            }

            span {
                top: 0px;
                position: relative;
                font-size: $font-size-14;
                color: $color-secondary;

                &.cancelled {
                    color: #EF476F;
                }
            }
            
            .material-icons {
                padding-right: 10px;
                float: left;

                &.muted {
                    color: rgba($muted, 0.10) !important;
                }
            }

            &-remove {
                cursor: pointer;
                padding-left: 10px;
                right: 10px;
                top: 10px !important;
                position: absolute !important;

                &:hover {
                    color: rgba($color-secondary, 0.5);
                }
            }
        }
    }

    &.custom-tag-input {
        background-color: #F7F9FD !important;
        border: 15px solid #F7F9FD !important;

        input {
            min-height: 40px !important;
            color: $color-secondary !important;
            background-color: #F7F9FD !important;
            padding-left: 15px;
            font-size: $font-size-14;
            line-height: 40px;
            min-width: 200px;

            &::placeholder {
                font-weight: normal;
                font-style: italic;
                color: rgba($color-secondary, 0.60);
                opacity: 1;
            }
        }

        .custom-tag {
            position: relative;
            background-color: $white;
            border: 1px solid rgba($color-secondary, 0.20);
            border-radius: 5px;
            padding: 10px 20px 10px 20px;
            margin-right: 10px;
            margin-bottom: 10px;
            width: 18%;
            
            @include respond-below('md') {
                width: 18%;
            }
            @include respond-below('sm') {
                width: 24%;
            }
            @include respond-below('xs') {
                width: 100%;
            }

            span {
                top: 0px;
                position: relative;
                font-size: $font-size-14;
                color: $color-secondary;
            }
            
            .material-icons {
                padding-right: 10px;
                float: left;

                &.muted {
                    color: rgba($muted, 0.10) !important;
                }
            }

            &-remove {
                cursor: pointer;
                padding-left: 10px;
                right: 12px;
                top: 6px !important;
                position: absolute !important;
                color: $color-danger !important;
                font-size: 20px !important;

                &:hover {
                    color: rgba($color-secondary, 0.5);
                }
            }
        }
    }

    &.custom-tag-input-exp {
        position: relative;
        background-color: #F7F9FD !important;
        
        @include respond-below('md') {
            width: 100%;
        }
        @include respond-below('sm') {
            width: 85vw;
        }
        @include respond-below('xs') {
            width: 70vw;
        }

        > div {
            overflow: hidden;
            height: 55px;
        }

        .exp-el {
            position: absolute;
            top: 0;
            right: 0;
            cursor: pointer;

            i {
                font-size: 14px;
            }

            &:hover {
                color: rgba($color-secondary, 0.5) !important;
            }
        }

        input {
            min-height: 40px !important;
            color: $color-secondary !important;
            background-color: #F7F9FD !important;
            padding-left: 15px;
            font-size: $font-size-14;
            line-height: 40px;

            &::placeholder {
                font-weight: normal;
                font-style: italic;
                color: rgba($color-secondary, 0.60);
                opacity: 1;
            }
        }

        .custom-tag {
            position: relative;
            background-color: $white;
            border: 1px solid rgba($color-secondary, 0.20);
            border-radius: 5px;
            padding: 8px 20px 8px 10px;
            margin: 5px;

            text-overflow: ellipsis;
            overflow: hidden; 
            width: 100px; 
            white-space: nowrap;
            
            @include respond-below('md') {
                width: 18%;
            }
            @include respond-below('sm') {
                width: 24%;
            }
            @include respond-below('xs') {
                width: 100%;
            }

            span {
                top: 0px;
                position: relative;
                font-size: $font-size-14;
                color: $color-secondary;
            }
            
            .material-icons {
                padding-right: 10px;
                float: left;

                &.muted {
                    color: rgba($muted, 0.10) !important;
                }
            }

            &-remove {
                cursor: pointer;
                padding-left: 10px;
                right: 20px;
                top: 5px !important;
                position: absolute !important;
                color: $color-danger !important;
                font-size: 20px !important;
                width: 10px !important;

                &:hover {
                    color: rgba($color-secondary, 0.5);
                }
            }
        }

        &.exp-active {
            > div {
                overflow: visible;
                height: auto;
            }
            
            .custom-tag {
                position: relative;
                background-color: $white;
                border: 1px solid rgba($color-secondary, 0.20);
                border-radius: 5px;
                padding: 8px 20px 8px 10px;
                margin: 5px;
            }

            .exp-el {
                i {
                    -webkit-transform: rotate(90deg);
                    -moz-transform: rotate(90deg);
                    -ms-transform: rotate(90deg);
                    -o-transform: rotate(90deg);
                    transform: rotate(90deg);
                }
            }
        }


        .custom-tags-suggestions-container {
            ul {
                li {
                    padding: 0px !important;
        
                    div {
                        padding: 3px 5px !important;

                        &.active {
                            background: $color-active !important;
                            color: white !important;
                        }
                    }
                }
            }
        }
    }

    &.custom-tag-input-rounded {
        background-color: #F7F9FD !important;
        border: 15px solid #F7F9FD !important;

        input {
            min-height: 40px !important;
            color: $color-secondary !important;
            background-color: #F7F9FD !important;
            padding-left: 15px;
            font-size: $font-size-14;
            line-height: 40px;
            min-width: 200px;

            &::placeholder {
                font-weight: normal;
                font-style: italic;
                color: rgba($color-secondary, 0.60);
                opacity: 1;
            }
        }

        .custom-tag {
            position: relative;
            background-color: $white;
            border: 1px solid rgba($color-secondary, 0.20);
            border-radius: 20px;
            padding: 10px 20px 10px 20px;
            margin-right: 10px;
            margin-bottom: 10px;
            width: 18%;
            
            @include respond-below('md') {
                width: 18%;
            }
            @include respond-below('sm') {
                width: 24%;
            }
            @include respond-below('xs') {
                width: 100%;
            }

            span {
                top: 0px;
                position: relative;
                font-size: $font-size-14;
                color: $color-secondary;
            }
            
            .material-icons {
                padding-right: 10px;
                float: left;

                &.muted {
                    color: rgba($muted, 0.10) !important;
                }
            }

            &-remove {
                cursor: pointer;
                padding-left: 10px;
                right: 12px;
                top: 6px !important;
                position: absolute !important;
                color: $color-danger !important;
                font-size: 20px !important;

                &:hover {
                    color: rgba($color-secondary, 0.5);
                }
            }
        }
    }

    &.form-tag-input {
        min-height: 230px !important;
        background-color: $white !important;
        border: 15px solid $white !important;
        -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
        -moz-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
        box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
        
        input {
            min-height: 40px !important;
            color: $color-secondary !important;
            background-color: $white !important;
            padding-left: 15px;
            font-size: $font-size-14;
            line-height: 40px;
    
            &::placeholder {
                font-weight: normal;
                font-style: italic;
                color: rgba($color-secondary, 0.60);
                opacity: 1;
            }
        }

        .custom-tag {
            position: relative;
            background-color: #F7F9FD;
            border-radius: 5px;
            padding: 10px 30px 10px 20px;
            margin-right: 10px;
            margin-bottom: 10px;

            span {
                top: 0px;
                position: relative;
                font-size: $font-size-14;
                color: $color-secondary;
            }
            
            .material-icons {
                padding-right: 10px;
                float: left;

                &.muted {
                    color: rgba($muted, 0.10) !important;
                }
            }

            &-remove {
                cursor: pointer;
                right: 10px;
                top: 7px !important;
                position: absolute !important;
                color: $color-danger !important;
                font-size: 20px !important;

                &:hover {
                    color: rgba($color-secondary, 0.5);
                }
            }
        }
    }

    &.form-tag-input-min-height {
        min-height: 30px !important;
        background-color: $white !important;
        border: 1px solid rgba($color-secondary, 0.10) !important;
        
        > div {
            border-top: 5px solid $white !important;
            border-left: 5px solid $white !important;
            border-right: 5px solid $white !important;
        }

        input {
            min-height: 40px !important;
            color: $color-secondary !important;
            background-color: $white !important;
            padding-left: 15px;
            font-size: $font-size-14;
            line-height: 40px;
    
            &::placeholder {
                font-weight: normal;
                font-style: italic;
                color: rgba($color-secondary, 0.60);
                opacity: 1;
            }
        }

        .custom-tag {
            position: relative;
            background-color: #F7F9FD;
            border-radius: 5px;
            padding: 10px 30px 10px 20px;
            margin-right: 10px;
            margin-bottom: 5px;

            span {
                top: 0px;
                position: relative;
                font-size: $font-size-14;
                color: $color-secondary;
            }
            
            .material-icons {
                padding-right: 10px;
                float: left;

                &.muted {
                    color: rgba($muted, 0.10) !important;
                }
            }

            &-remove {
                cursor: pointer;
                right: 10px;
                top: 7px !important;
                position: absolute !important;
                color: $color-danger !important;
                font-size: 20px !important;
                
                @include respond-below('lg') {
                    position: relative !important;
                    top: 2px !important;
                    right: -7px !important;
                }

                &:hover {
                    color: rgba($color-secondary, 0.5);
                }
            }
        }
    }
}