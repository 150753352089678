.reports-bin-out, .reports-bin-aging, .reports-transport-orders {
    // background-color: white;
}

.reports-layout {
    background-color: $bg-color;

    table {
        caption-side: top !important;
    }

    .menu-list-items {
        position: relative;
        height: calc(100vh - 150px);
        min-height: 360px;

        .MuiListItem-root {
            border-radius: 5px;

            .MuiListItemText-root {
                span {
                    font-size: $font-size-14;
                    color: #7F7F7F;
                    font-weight: bold;
                }
            }

            &:hover {
                background-color: transparent !important;

                .MuiListItemText-root {
                    span {
                        color: $main-color;
                    }
                }
            }

            &.active {
                // background-color: $color-active !important;

                .MuiListItemText-root {
                    span {
                        color: $main-color;
                    }
                }
            }
        }

        .bottom-list {
            position: absolute;
            bottom: 0;
            width: 100%;
        }
    }

    .icon-list-component {
        button {
            height: auto !important;
            padding: 10px;

            span {
                svg {
                    width: 15px;
                    height: 15px;

                    path {
                        opacity: 1;
                    }
                }
            }

            &:hover {
                span {
                    svg {
                        path {
                            opacity: 0.5;
                        }
                    }
                }
            }
        }
    }

    
    .reports-avatart {
        width: 200px;
        height: auto;
    }

    
    .reports-table {
        padding-top: 20px;
        overflow: auto;

        .no-border {
            border: 0px !important;
        }
        
        table {
            width: 100%;
            min-width: 600px;
            border: 0px;

            caption {
                font-weight: bold;
                text-align: left;
                padding-bottom: 10px;
            }

            thead {
                tr {
                    th {
                        font-weight: 400;
                        text-align: center;
                        padding: 5px !important;
                        border: 1px solid #D9D9D9;
                    }
                }
            }

            tbody {
                tr {
                    td {
                        padding: 5px !important;
                        text-align: center;
                        border: 1px solid #D9D9D9;
                    }
                }
            }

            tfoot {
                tr {
                    td {
                        font-weight: bold;
                        text-align: center;
                        padding: 5px !important;
                        border: 1px solid #D9D9D9;
                    }
                }
            }
        }
        
        .align-left {
            text-align: left !important;
        }

        .align-center {
           text-align: center !important;
        }

        .align-right {
           text-align: right !important;
        }
    }
}