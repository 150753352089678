.custom-rsuite-time-picker-component {
    .rs-picker:hover {
        border-color: #E4E6EF !important;
    }

    .rs-btn-subtle {
        box-shadow: none !important;
        padding-top: 0 !important;
        padding-bottom: 0 !important;
        padding-right: 0 !important;
        background-color: transparent !important;

        input, .rs-picker-toggle-value, .rs-picker-toggle-placeholder {
            font-size: 1.1rem !important;
            font-weight: 500 !important;
            line-height: 1.5 !important;
            color: $secondary;
        }

        input {
            padding-right: 0 !important;
        }

        .rs-picker-toggle-clean {
            right: 0px !important;
        }
        
        .rs-picker-toggle-caret {
            display: none !important;
        }
    }

    .rs-picker-read-only {
        .rs-picker-toggle-value, .rs-picker-toggle-placeholder {
            color: #A1A5B7 !important;
        }
    }

    .is-invalid {
        &.rs-picker:not(.rs-picker-disabled):hover {
            border-color: $color-danger !important;
        }
    }
}


.rs-picker-menu {
    .rs-calendar-time-dropdown-cell-active {
        background-color: $primary !important;
    }

    .rs-picker-toolbar-right {
        button {
            padding-left: 10px !important;
            padding-right: 10px !important;
            background-color: $primary !important;
        }
    }
}