.accordion {
    .accordion-item {
        border: 1px solid var(--bs-gray-300);
        border-radius: 6px;
        overflow: hidden;

        .accordion-header {
            .accordion-button {
                background-color: var(--bs-blue-focus1);
                padding: 12px 24px;
                box-shadow: unset;
                border-top-left-radius: 6px;
                border-top-right-radius: 6px;

                &::after {
                    width: 34px;
                    height: 34px;
                    background-size: 18px;
                    background-color: var(--bs-blue-focus3);
                    border-radius: 6px;
                    background-position: center;
                    padding: 4px;
                }
            }
        }

        .custom-button {
            background-color: var(--bs-blue-focus1);
            padding: 12px 12px 12px 24px;
            box-shadow: unset;
            border-top-left-radius: 6px;
            border-top-right-radius: 6px;
        }

        .accordion-collapse {
            .accordion-body {
                padding: 16px 24px;
            }
        }
    }
}