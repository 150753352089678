.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  position: relative;
  padding: 16px 12px;
  border-radius: 6px;
  background-color: $blue-focus1;
  border: 1px solid $blue-100;

  > div {
    width: 100%;
    
    > div {
      padding: 0px;
    }
  }

  &.is-invalid {
    border: 1px solid $red-100;
  }

  &.no-bg {
    background-color: transparent;
    border: 1px solid $gray-300;
  }
}

.card-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  position: relative;
  padding: 20px 16px;
  border-radius: 6px;
  background-color: $blue-focus1;

  > div {
    width: 100%;
    
    > div {
      padding: 0px;
    }
  }

  &.is-invalid {
    border: 1px solid $red-100;
  }
}

.card-table {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  position: relative;
  padding: 20px 16px;
  margin-bottom: 24px;
  border-radius: 12px;
  background-color: $blue-focus1;

  > div {
    width: 100%;
  }
}

.card-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  gap: 0;
  padding: 0px;
  border-radius: 6px;
  background-color: transparent;
  border: 1px solid $gray-300;
  box-shadow: $shadow1;

  .card-header {
    padding: 12px 16px;
    border-bottom: 1px solid $gray-300;
    background-color: $blue-focus1;

    
    line-height: 14px;
    font-weight: $font-weight-semibold;
    line-height: 20px;
    color: $gray-800;
  }

  .card-body {
    padding: 12px 16px;
  }

  .list-group {
    .list-group-item {
      padding: 12px 16px;
    }
  }
}