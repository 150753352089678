.custom-time-picker-component {
    position: relative;

    &.icon-left {
        .material-icons {
            position: absolute;
            top: 15px;
            left: 5px;
            z-index: 1;
    
            &.muted {
                color: rgba($color-secondary, 0.20) !important;
            }
        }
    }

    &.icon-right {
        .material-icons {
            position: absolute;
            top: 15px;
            right: 15px;
            z-index: 1;
    
            &.muted {
                color: rgba($color-secondary, 0.20) !important;
            }
        }
    }
    

    .rc-time-picker { 
        width: 100%;
    }

    .rc-time-picker-input {
        width: 100%;
        min-height: 53px;
        border: 0px;
        color: $color-secondary;
        background-color: #F7F9FD !important;
        padding-left: 15px;
        font-size: $font-size-14;
        line-height: 40px;

        &:focus, &:active {
            outline: none;
        }
        
        &::placeholder {
            font-weight: lighter;
            font-style: italic;
            color: rgba($color-secondary, 0.80);
        }

        .placeholder {
            font-size: $font-size-14;
            font-weight: lighter;
            font-style: italic;
            color: rgba($color-secondary, 0.40);
        }

        &[disabled] {
            background-color: #F7F9FD !important;
            color: #999a9d !important;
            cursor: default;
        }
    }

    
    &.error {
        input {
            border: 1px solid rgba($color-danger, 0.4) !important;
            border-radius: 0 !important;
        }
    }


    &.bg-white-underline {
        .rc-time-picker-input {
            width: 100%;
            min-height: 53px;
            border: 0px;
            border-radius: 0px;
            color: $color-secondary;
            background-color: #ffffff !important;
            border-bottom: 1px solid rgba($color-secondary, 0.1);
            padding-left: 15px;
            font-size: $font-size-14;
            line-height: 40px;
    
            &:focus, &:active {
                outline: none;
            }
            
            &::placeholder {
                font-weight: lighter;
                font-style: italic;
                color: rgba($color-secondary, 0.80);
            }
    
            .placeholder {
                font-size: $font-size-14;
                font-weight: lighter;
                font-style: italic;
                color: rgba($color-secondary, 0.40);
            }
    
            &[disabled] {
                background-color: #ffffff !important;
                border-bottom: 1px solid transparent !important;
                color: #999a9d !important;
                cursor: default;
            }
        }

        &.recurring {
            .rc-time-picker-input {
                padding-left: 0px;
            }
        }
    }

    &.has-icon {
        &.icon-left {
            .rc-time-picker-input {
                padding-left: 35px;
            }
        }
    }


    &.default-look {
        padding: 0;

        .rc-time-picker-input {
            height: unset !important;
            box-shadow: none !important;
            border: 0 !important;
            padding: $input-btn-padding-y $input-btn-padding-x !important;
            border-radius: $border-radius !important;
            background-color: $white !important;
            outline: unset !important;
            border-color: transparent !important;
            min-height: 41px;
            line-height: unset;

            // &[aria-expanded="true"] {
            //     border-color: $input-focus-border-color !important;
            // }
            
            .rs-picker-toggle-placeholder {
                font-size: $input-btn-font-size !important;
                font-weight: $font-weight-bold !important;
                line-height: $line-height-base !important;
                min-height: 20px;
            }


            .rs-picker-toggle-value {
                font-size: $input-btn-font-size !important;
                font-weight: $font-weight-bold !important;
                line-height: $line-height-base !important;
                color: $secondary !important;
                flex: 0 0 90%;
                display: flex;

                .rs-picker-value-list {
                    flex: 1;
                }

                .rs-picker-value-count {
                    flex: 0;
                }
            }
        }

        &.is-invalid {
            padding: 0px !important;
        }
    }
}