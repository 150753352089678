.classic-dropzone-component {
    .container {
        padding: 0px;
    }

    .dropzone-container {
        position: relative;
        min-height: 260px;
        
        @include respond-below('sm') {
            min-height: 360px;
        }

        cursor: pointer;
        background-repeat: no-repeat;
        background-position: center 20%;
        background-size: auto;

        background-color: $dropzone-bg;
        border-radius: 5px;

        -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
        -moz-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
        box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);

        .container-text {
            width: 100%;
            text-align: center;
            display: grid;
            align-items: end;
            padding-top: 15px;
            padding-bottom: 25px;
            font-size: 21px;
            font-weight: bold;
            color: rgba($color-secondary, 0.8);

            @include respond-below('lg') {
                font-size: $font-size-12;
            }
        }
    }

    .max-size {
        color: rgba($color-secondary, 0.6);
    }
    
    .dropzone-files-item {
        cursor: pointer;
        position: relative;
        height: 100%;
        overflow: hidden;

        &.grayscale {
            .dropzone-image {
                filter: grayscale(1);
                -webkit-filter: grayscale(1);
            }
        }
        &.blur {
            .dropzone-image {
                filter: blur(100px);
                -webkit-filter: blur(100px);
            }
        }

        .dropzone-remove-file {
            button {
                position: absolute;
                right: 5px;
                top: 5px;
                display: none;

                &:hover {
                    .material-icons {
                        color: $color-danger;
                    }
                }
            }
        }

        .dropzone-progress-bar {
            position: absolute;
            width: 100%;
            height: 100%;
            z-index: 1;
        }

        .dropzone-image {
            height: 100%;
            min-height: 200px;

            @for $i from 0 through 100 {
                &.grayscale-#{$i} {
                    filter: grayscale(round(percentage((100 - $i)/100))) !important;
                    -webkit-filter: grayscale(round(percentage((100 - $i)/100))) !important;
                }

                &.blur-#{$i} {
                    filter: blur((100 - $i) + px) !important;
                    -webkit-filter: blur((100 - $i) + px) !important;
                }
            }
        }

        .dropzone-details {
            position: absolute;
            font-size: 12px;
            bottom: 0;
            background-color: rgba($black, 0.4);
            color: $white;
            width: 100%;
            padding-left: 5px;
            padding-right: 5px;
            display: none;
        }

        &:hover {
            button {
                display: block;
            }
            .dropzone-details {
                display: block;
            }
        }
    }

    .bg-size-unset {
        background-size: unset !important;
    }

    &.disabled {
        cursor: unset;
        background-color: $table-td-bg;

        .dropzone-container {
            cursor: unset;
            
            .dropzone {
                cursor: unset;
                opacity: 0.3;
            }
        }
    }
}