.legend-menu-component {
    width: 300px;
    background-color: white;

    &:after {
        content: '';
        display: inline-block;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 13px 12px 0 12px;
        border-color: #ffffff transparent transparent transparent;
        position: absolute;
        margin: 0 auto;
        text-align: center;
        left: 0;
        right: 0;
    }

    .legend-menu-list {
        padding: 0px;

        .border-div {
            position: absolute;
            top: 0px;
            left: 0px;
            width: 6px;
            height: 100%;
            border-width: 1px;
            border-style: solid;
            border-color: transparent;
        }

        .MuiListItem-root {
            border-left: 0px solid transparent;
        }
    }
}