.custom-slider-component {
    min-width: 300px;

    &.ganttchart-slider {
        button {
            .MuiIconButton-label {
                i {
                    color: #7F7F7F;
                    font-size: 22px;
                }
            }
        }

        .MuiSlider-rail {
            background-color: #7F7F7F;
        }
        .MuiSlider-track {
            background-color: #7F7F7F;
        }
        .MuiSlider-thumb {
            width: 6px;
            height: 12px;
            background-color: white;
            border: 1px solid #707070;
        }
        .MuiSlider-thumb.Mui-focusVisible, .MuiSlider-thumb:hover {
            box-shadow: 0px 0px 0px 8px rgba(#707070, 0.16)
        }
    }
}