.MuiButton-outlined {
    min-height: 30px !important;
    border-radius: 0px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    
    .MuiButton-label {
        color: $color-secondary !important;
        text-transform: capitalize !important;
    }
}

.MuiButton-contained {
    box-shadow: 0px 0px 2px -1px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);

    border-radius: 0px !important;
    background-color: $white !important;
    border: 0px !important;
    
    .MuiButton-label {
        color: $color-secondary !important;
    
        padding-top: 3px;
        padding-bottom: 3px;
    }

    .material-icons {
        color: $color-secondary !important;
    }
}

.MuiButton-containedPrimary {
    border-radius: 0px !important;
    background-color: $color-active !important;

    .MuiButton-label {
        color: $white !important;
        min-width: 70px;
    }

    .material-icons {
        color: $white !important;
    }
}
.MuiButton-containedSuccess {
    border-radius: 0px !important;
    background-color: $color-active !important;

    .MuiButton-label {
        color: $white !important;
        min-width: 70px;
    }

    .material-icons {
        color: $white !important;
    }
}
.remove-icon-btn {
    &:hover, &:focus, &:active {
        .MuiIconButton-label {
            color: $color-danger !important;
        }
    }
}
.add-icon-btn {
    &:hover, &:focus, &:active {
        .MuiIconButton-label {
            color: $color-success !important;
        }
    }
}


.remove-icon-fill-btn {
    .MuiIconButton-label {
        color: $color-danger !important;
    }
}

.remove-icon-fill-btn-gray {
    .MuiIconButton-label {
        color: #7F7F7F !important;
    }

    &:hover {
        .MuiIconButton-label {
            color: $color-danger !important;
        }
    }
}

.add-icon-fill-btn {
    color: #06D6A0 !important;
    
    .MuiIconButton-label {
        color: #06D6A0 !important;

        .material-icons {
            color: #06D6A0 !important;
        }
    }

    &.p-7 {
        padding: 9px;
    }
}
.add-icon-fill-btn-blue {
    color: $color-active !important;
    
    .MuiIconButton-label {
        color: $color-active !important;

        .material-icons {
            color: $color-active !important;
        }
    }
}

.manage-outline-primary {
    border: 1px solid $color-active !important;
    background-color: $white !important;
    color: $color-active !important;
    border-radius: 5px !important;
    text-decoration: none !important;
    min-height: 30px !important;
    display: inline-block;
    padding-left: 25px;
    padding-right: 25px;
    line-height: 30px;

    &:hover {
        background-color: rgba($black, 0.03) !important;
    }
}

.manage-primary {
    border: 1px solid $color-active !important;
    background-color: $color-active !important;
    color: $white !important;
    border-radius: 0px !important;
    text-decoration: none !important;
    min-height: 40px !important;
    display: inline-block;
    padding-left: 25px;
    padding-right: 25px;
    line-height: 40px;

    &:hover {
        background-color: rgba($color-active, 0.8) !important;
    }

    &:disabled, .Mui-disabled {
        border: 1px solid rgba($color-secondary, 0.60) !important;
        background-color: rgba($color-secondary, 0.60) !important;
    }
}

.manage-success {
    border: 1px solid $color-success !important;
    background-color: $color-success !important;
    color: $white !important;
    border-radius: 0px !important;
    text-decoration: none !important;
    min-height: 40px !important;
    display: inline-block;
    padding-left: 25px;
    padding-right: 25px;
    line-height: 40px;

    &:hover {
        background-color: rgba($color-success, 0.8) !important;
    }

    &:disabled, .Mui-disabled {
        border: 1px solid rgba($color-secondary, 0.60) !important;
        background-color: rgba($color-secondary, 0.60) !important;
    }
}

.manage-default {
    border: 1px solid rgba($color-secondary, 0.10) !important;
    background-color: $table-td-bg !important;
    color: $white !important;
    border-radius: 0px !important;
    text-decoration: none !important;
    min-height: 40px !important;
    display: inline-block;
    padding-left: 25px;
    padding-right: 25px;
    line-height: 40px;

    &:hover {
        background-color: rgba($color-secondary, 0.08) !important;
    }

    &:disabled, .Mui-disabled {
        border: 1px solid rgba($color-secondary, 0.60) !important;
        background-color: rgba($color-secondary, 0.60) !important;
    }
}


.custom-outline-primary {
    border: 1px solid $color-active !important;
    background-color: $white !important;
    border-radius: 0px !important;
    text-decoration: none !important;
    min-height: 52px !important;
    display: inline-block;
    padding-left: 25px;
    padding-right: 25px;
    line-height: 52px;

    .MuiButton-label {
        color: $color-active !important;
        font-weight: bold;

        .material-icons {
            padding-left: 5px;
        }
    }

    &:hover {
        background-color: rgba($color-active, 0.1) !important;
    }

    &:disabled, .Mui-disabled {
        border: 1px solid rgba($color-secondary, 0.60) !important;
        background-color: rgba($color-secondary, 0.60) !important;
    }
}

.custom-outline-danger {
    border: 1px solid #EF476F !important;
    background-color: $white !important;
    border-radius: 5px !important;
    text-decoration: none !important;
    display: inline-block;
    padding-left: 25px;
    padding-right: 25px;
    height: 20px;
    min-height: 23px !important;
    font-size: 12px !important;
    min-height: 27px !important;

    .MuiButton-label {
        color: #EF476F !important;

        .material-icons {
            padding-left: 5px;
        }
    }

    &:hover {
        background-color: rgba(#EF476F, 0.1) !important;
    }

    &:disabled, .Mui-disabled {
        border: 1px solid rgba($color-secondary, 0.60) !important;
        background-color: rgba($color-secondary, 0.60) !important;
    }
}


.custom-outline-primary-btn {
    border: 1px solid $color-active !important;
    background-color: $white !important;
    border-radius: 5px !important;
    text-decoration: none !important;
    display: inline-block;
    padding-left: 25px;
    padding-right: 25px;
    height: 40px;
    min-height: 23px !important;
    font-size: 12px !important;
    min-height: 27px !important;

    .MuiButton-label {
        color: $color-active !important;

        .material-icons {
            padding-left: 5px;
        }
    }

    &:hover {
        background-color: rgba($color-active, 0.03) !important;
    }

    &:disabled, .Mui-disabled {
        border: 1px solid rgba($color-secondary, 0.60) !important;
        background-color: rgba($color-secondary, 0.60) !important;
    }
}

.custom-outline-danger-btn {
    border: 1px solid #EF476F !important;
    background-color: $white !important;
    border-radius: 5px !important;
    text-decoration: none !important;
    display: inline-block;
    padding-left: 25px;
    padding-right: 25px;
    height: 40px;
    min-height: 23px !important;
    font-size: 12px !important;
    min-height: 27px !important;

    .MuiButton-label {
        color: #EF476F !important;

        .material-icons {
            padding-left: 5px;
        }
    }

    &:hover {
        background-color: rgba(#EF476F, 0.03) !important;
    }

    &:disabled, .Mui-disabled {
        border: 1px solid rgba($color-secondary, 0.60) !important;
        background-color: rgba($color-secondary, 0.60) !important;
    }
}


.custom-outline-default {
    border: 1px solid $color-secondary !important;
    background-color: $transparent !important;
    border-radius: 5px !important;
    text-decoration: none !important;
    display: inline-block;
    padding-left: 25px;
    padding-right: 25px;
    height: 20px;
    min-height: 23px !important;
    font-size: 12px !important;
    min-height: 27px !important;

    .MuiButton-label {
        color: $color-secondary !important;

        .material-icons {
            padding-left: 5px;
        }
    }

    &:hover {
        background-color: rgba($color-secondary, 0.1) !important;
    }

    &:disabled, .Mui-disabled {
        border: 1px solid rgba($color-secondary, 0.60) !important;
        background-color: rgba($color-secondary, 0.60) !important;
    }
}


.jobs-default {
    border: 0px !important;
    background-color: rgba($color-secondary, 0.20) !important;
    color: $color-secondary !important;
    border-radius: 7px !important;
    text-decoration: none !important;
    min-height: 32px !important;
    display: inline-block;
    padding-left: 25px;
    padding-right: 25px;
    line-height: 32px;
    padding: 0 !important;
    min-width: 153px !important;

    .MuiButton-label {
        font-weight: bold;
    }

    &:hover {
        background-color: rgba($color-secondary, 0.8) !important;
    }

    &:disabled, .Mui-disabled {
        border: 1px solid rgba($color-secondary, 0.60) !important;
        background-color: rgba($color-secondary, 0.60) !important;
    }
}

.jobs-primary {
    border: 0px !important;
    background-color: $color-active !important;
    color: $white !important;
    border-radius: 7px !important;
    text-decoration: none !important;
    min-height: 32px !important;
    display: inline-block;
    padding-left: 25px;
    padding-right: 25px;
    line-height: 32px;
    padding: 0 !important;
    min-width: 153px !important;

    .MuiButton-label {
        font-weight: bold;
    }

    &:hover {
        background-color: rgba($color-active, 0.8) !important;
    }

    &:disabled, .Mui-disabled {
        border: 1px solid rgba($color-secondary, 0.60) !important;
        background-color: rgba($color-secondary, 0.60) !important;
    }
}

.jobs-danger {
    border: 0px !important;
    background-color: $color-danger !important;
    color: $white !important;
    border-radius: 7px !important;
    text-decoration: none !important;
    min-height: 32px !important;
    display: inline-block;
    padding-left: 25px;
    padding-right: 25px;
    line-height: 32px;
    padding: 0 !important;
    min-width: 153px !important;

    .MuiButton-label {
        font-weight: bold;
    }

    &:hover {
        background-color: rgba($color-danger, 0.8) !important;
    }

    &:disabled, .Mui-disabled {
        border: 1px solid rgba($color-secondary, 0.60) !important;
        background-color: rgba($color-secondary, 0.60) !important;
    }
}

.jobs-green {
    border: 0px !important;
    background-color: #06D6A0 !important;
    color: $white !important;
    border-radius: 7px !important;
    text-decoration: none !important;
    min-height: 32px !important;
    display: inline-block;
    padding-left: 25px;
    padding-right: 25px;
    line-height: 32px;
    padding: 0 !important;
    min-width: 153px !important;

    .MuiButton-label {
        font-weight: bold;
    }

    &:hover {
        background-color: rgba(#06D6A0, 0.8) !important;
    }

    &:disabled, .Mui-disabled {
        border: 1px solid rgba($color-secondary, 0.60) !important;
        background-color: rgba($color-secondary, 0.60) !important;
    }
}

.download-pdf {
    border: 0px !important;
    background-color: #06D6A0 !important;
    color: $white !important;
    border-radius: 0px !important;
    text-decoration: none !important;
    display: inline-block;

    .MuiButton-label {
        font-weight: bold;

        span {
            padding-left: 15px;
        }
    }

    &:hover {
        background-color: rgba(#06D6A0, 0.8) !important;
    }

    &:disabled, .Mui-disabled {
        border: 1px solid rgba($color-secondary, 0.60) !important;
        background-color: rgba($color-secondary, 0.60) !important;
    }
}

.link-green {
    color: #06D6A0 !important;
    text-decoration: unset !important;
    
    &:hover {
        color: rgba(#06D6A0, 0.6) !important;
    }
}
.link-secondary {
    color: $color-secondary !important;
    text-decoration: unset !important;
    
    &:hover {
        color: rgba($color-active, 0.6) !important;
    }
}
.link-primary {
    color: $color-active !important;
    text-decoration: unset !important;
    
    &:hover {
        color: rgba($color-active, 0.6) !important;
    }
}
.link-main {
    color: $main-color !important;
    text-decoration: unset !important;
    font-weight: bold;
    
    span {
        color: $main-color !important;
        text-decoration: unset !important;
        font-weight: bold;
    }

    &:hover {
        color: rgba($main-color, 0.6) !important;

        span {
            color: rgba($main-color, 0.6) !important;
        }
    
    }
}
.link-main-underline {
    color: $main-color !important;
    text-decoration: underline !important;
    font-weight: bold;
    
    &:hover {
        color: rgba($main-color, 0.6) !important;
    }
}
.link-defautl {
    color: $color-secondary !important;
    text-decoration: unset !important;
    
    &:hover, &.active {
        font-weight: bold;
    }
}

.link-dark {
    color: $color-secondary !important;
    text-decoration: unset !important;
    font-weight: bold;
    
    &:hover, &.active {
        color: rgba($color-secondary, 0.8) !important;
    }
}

.link-default {
    cursor: pointer !important;
    color: #7F7F7F !important;
    text-decoration: unset !important;
    
    i {
        cursor: pointer !important;
    }

    &:hover {
        color: rgba($color-active, 0.6) !important;
    }

    &.active {
        color: $color-active !important;
    }
}

.link-default-svg {
    position: relative;
    top: 4px;
    cursor: pointer !important;
    text-decoration: unset !important;
    opacity: 1 !important;

    svg {
        top: 0px;
        position: relative;
        opacity: 1 !important;

        path {
            opacity: 1 !important;
            fill: transparent !important;
            stroke: $color-active !important;
        }
    }

    &:hover {
        svg {
            path {
                opacity: 1 !important;
                stroke: $color-active !important;
                fill: $color-active !important;
            }
        }
    }

    &.active {
        svg {
            path {
                opacity: 1 !important;
                stroke: $color-active !important;
                fill: $color-active !important;
            }
        }
    }

    &.disabled {
        cursor: not-allowed !important;

        svg {
            path {
                opacity: 1 !important;
                stroke: transparent !important;
                fill: $color-secondary !important;
            }
        }
    }
}

.link-default-svg-fill {
    position: relative;
    top: 4px;
    cursor: pointer !important;
    text-decoration: unset !important;
    opacity: 1 !important;

    svg {
        top: 0px;
        position: relative;
        opacity: 1 !important;

        path {
            &:last-child {
                opacity: 1 !important;
                fill: $color-secondary !important;
                stroke: transparent !important;
            }
        }
    }

    &:hover {
        svg {
            path {
                &:last-child {
                    opacity: 1 !important;
                    stroke: transparent !important;
                    fill: $color-active !important;
                }
            }
        }
    }

    &.active {
        svg {
            path {
                &:last-child {
                    opacity: 1 !important;
                    stroke: transparent !important;
                    fill: $color-active !important;
                }
            }
        }
    }

    &.disabled {
        cursor: not-allowed !important;

        svg {
            path {
                &:last-child {
                    opacity: 1 !important;
                    stroke: transparent !important;
                    fill: $color-secondary !important;
                }
            }
        }
    }
}

.link-active-svg-fill {
    position: relative;
    top: 4px;
    cursor: pointer !important;
    text-decoration: unset !important;
    opacity: 1 !important;

    svg {
        top: 0px;
        position: relative;
        opacity: 1 !important;

        path {
            &:last-child {
                opacity: 1 !important;
                fill: $color-active !important;
                stroke: transparent !important;
            }
        }
    }

    &:hover {
        svg {
            path {
                &:last-child {
                    opacity: 0.6 !important;
                    stroke: transparent !important;
                    fill: $color-active !important;
                }
            }
        }
    }

    &.active {
        svg {
            path {
                &:last-child {
                    opacity: 1 !important;
                    stroke: transparent !important;
                    fill: $color-active !important;
                }
            }
        }
    }

    &.disabled {
        cursor: not-allowed !important;

        svg {
            path {
                &:last-child {
                    opacity: 1 !important;
                    stroke: transparent !important;
                    fill: $color-secondary !important;
                }
            }
        }
    }
}

.link-danger-svg-fill {
    position: relative;
    top: 4px;
    cursor: pointer !important;
    text-decoration: unset !important;
    opacity: 1 !important;
   
    display: flex;
    height: 35px !important;
    align-items: center;
    
    &:hover {
        svg {
            path {
                &:last-child {
                    stroke: transparent !important;
                    fill: $color-danger !important;
                }
            }
        }
    }

    &.active {
        svg {
            path {
                &:last-child {
                    stroke: transparent !important;
                    fill: $color-danger !important;
                }
            }
        }
    }

    &.disabled {
        cursor: not-allowed !important;

        svg {
            path {
                &:last-child {
                    opacity: 1 !important;
                    stroke: transparent !important;
                    fill: $color-secondary !important;
                }
            }
        }
    }
}

.link-default-icon {
    color: $color-secondary !important;
    text-decoration: unset !important;

    i {
        color: $color-secondary !important;
        font-size: 14px;
    }
    
    &:hover {
        color: rgba($color-secondary, 0.6) !important;
        
        i {
            color: rgba($color-secondary, 0.6) !important;
        }
    }
}

.link-danger {
    color: $color-danger !important;
    text-decoration: unset !important;
    
    &:hover {
        color: rgba($color-danger, 0.6) !important;
    }
}
.link-danger-icon {
    color: $color-danger !important;
    text-decoration: unset !important;

    i {
        color: $color-danger !important;
        font-size: 14px;
    }
    
    &:hover {
        color: rgba($color-danger, 0.6) !important;
        
        i {
            color: rgba($color-danger, 0.6) !important;
        }
    }
}
.link-danger-underline {
    color: $danger-active !important;
    text-decoration: underline !important;
    
    &:hover {
        color: rgba($danger-active, 0.6) !important;
    }
}

.link-warning-underline {
    color: $color-warning !important;
    text-decoration: underline !important;
    
    &:hover {
        color: rgba($color-warning, 0.6) !important;
    }
}

.link-primary-underline {
    color: $color-active !important;
    text-decoration: underline !important;
    
    &:hover {
        color: rgba($color-active, 0.6) !important;
    }
}
.link-primary-icon-underline {
    color: $color-active !important;
    text-decoration: underline !important;
    
    i {
        color: $color-active !important;
    }

    &:hover {
        color: rgba($color-active, 0.6) !important;

        i {
            color: rgba($color-active, 0.6) !important;
        }
    }
}

.link-default-underline {
    color: $color-secondary !important;
    text-decoration: underline !important;
    
    &:hover {
        color: rgba($color-secondary, 0.6) !important;
    }
}

.link-default-download-print-email {
    cursor: pointer !important;
    color: #7F7F7F !important;
    text-decoration: unset !important;
    align-items: center;
    justify-items: center;
    justify-content: center;
    display: flex;
    
    i {
        cursor: pointer !important;
    }

    &:hover {
        color: rgba($color-active, 0.6) !important;

        span {
            color: rgba($color-active, 0.6) !important;
        }
    }

    &.active {
        color: $color-active !important;

        span {
            color: rgba($color-active, 0.6) !important;
        }
    }
}

.edit-link {
    color: #7F7F7F !important;
    text-decoration: unset !important;
    padding-left: 5px;
    padding-right: 5px;
    font-size: $font-size-14;
    
    &:hover {
        color: $color-active !important;
    }
}
.copy-link {
    color: #7F7F7F !important;
    text-decoration: unset !important;
    padding-left: 5px;
    padding-right: 5px;
    
    &:hover {
        color: $color-active !important;
    }
}
.delete-link {
    color: #7F7F7F !important;
    text-decoration: unset !important;
    padding-left: 5px;
    padding-right: 5px;
    
    &:hover {
        color: $color-danger !important;
    }

    i {
        font-size: 24px;
    }
}

.active-link-i {
    color: #7F7F7F !important;
    text-decoration: unset !important;
    padding-left: 5px;
    padding-right: 5px;
    
    &:hover {
        i {
            color: $color-active !important;
        }
    }

    i {
        font-size: 24px;
    }
}

.danger-link-i {
    color: #7F7F7F !important;
    text-decoration: unset !important;
    padding-left: 5px;
    padding-right: 5px;
    
    &:hover {
        i {
            color: $color-danger !important;
        }
    }

    i {
        font-size: 24px;
    }
}

.gray-hover-active {
    span {
        i {
            color: rgba($color-secondary, 0.3) !important;
        }
    }

    &:hover {
        span {
            i {
                color: $color-active !important;
            }
        }
    }

    &.is-open {
        span {
            i {
                color: $color-active !important;
            }
        }
    }
}

.report-range-date-picker {
    border: 1px solid $color-active !important;
    border-radius: 5px !important;

    @include respond-below('xl') {
        width: 100%;
    }

    span {
        color: $color-secondary !important;
        text-transform: none !important;
        
        i {
            color: $color-active !important;
        }
    }

    &:hover {
        span {
            i {
                color: $color-active !important;
            }
        }
    }

    &.is-open {
        span {
            i {
                color: $color-active !important;
            }
        }
    }
}

.job-gray-hover-active {
    span {
        color: $color-secondary !important;

        i {
            color: $color-secondary !important;
        }
    }

    &:hover {
        span {
            color: $color-active !important;

            i {
                color: $color-active !important;
            }
        }
    }

    &.is-open {
        span {
            color: $color-active !important;

            i {
                color: $color-active !important;
            }
        }
    }
}

.a-gray-hover-active {
    color: $color-secondary !important;

    i {
        color: $color-secondary !important;
    }

    &:hover {
        color: $color-active !important;

        i {
            color: $color-active !important;
        }
    }

    &.is-open {
        color: $color-active !important;

        i {
            color: $color-active !important;
        }
    }
}


.job-status-outline-primary {
    border: 1px solid $color-active !important;
    background-color: $white !important;
    border-radius: 5px !important;
    text-decoration: none !important;
    min-height: 40px !important;
    line-height: 40px !important;
    display: inline-block;
    padding-left: 35px;
    padding-right: 35px;
    width: 100% !important;

    .MuiButton-label {
        color: $color-active !important;
    }

    &:hover {
        background-color: rgba($black, 0.02) !important;
    }
}

.job-status-link-outline-primary {
    border: 1px solid $color-active !important;
    background-color: $white !important;
    border-radius: 5px !important;
    text-decoration: none !important;
    min-height: 40px !important;
    line-height: 40px !important;
    display: inline-block;
    text-align: center;
    width: 100% !important;
    color: $color-active !important;
    
    &:hover {
        background-color: rgba($black, 0.02) !important;
    }
}

.job-status-outline-default {
    border: 1px solid #DADADA !important;
    background-color: $white !important;
    border-radius: 5px !important;
    text-decoration: none !important;
    min-height: 40px !important;
    line-height: 20px !important;
    display: inline-block;
    padding-left: 35px;
    padding-right: 35px;
    width: 100% !important;

    .MuiButton-label {
        color: $color-secondary !important;
    }

    &:hover {
        background-color: rgba($black, 0.02) !important;
    }
}
.job-status-outline-danger {
    border: 1px solid $color-danger !important;
    background-color: $white !important;
    border-radius: 5px !important;
    text-decoration: none !important;
    min-height: 40px !important;
    line-height: 15px !important;
    display: inline-block;
    padding-left: 35px;
    padding-right: 35px;
    width: 100% !important;

    .MuiButton-label {
        color: $color-danger !important;
    }

    &:hover {
        background-color: rgba($black, 0.02) !important;
    }
}

.link-outline-primary {
    border: 0px !important;
    background-color: transparent !important;
    border-radius: 0px !important;
    text-decoration: none !important;
    min-height: 40px !important;
    line-height: 40px !important;
    display: inline-block;
    text-align: center;
    width: 100% !important;
    color: $color-active !important;
    align-items: center;
    justify-content: center;
    display: flex;
    
    &:hover {
        background-color: transparent !important;
        
        i {
            color: rgba($color-active, 0.8) !important;
        }
    }
}

.batch-action-btn {
    border: 1px solid rgba($color-secondary, 0.2) !important;
    background-color: $white !important;
    border-radius: 5px !important;
    text-decoration: none !important;
    min-height: 50px !important;
    line-height: 50px !important;
    display: inline-block;
    padding-left: 35px;
    padding-right: 35px;
    width: 100% !important;
    -webkit-box-shadow: unset !important;
    -moz-box-shadow: unset !important;
    box-shadow: unset !important;

    &.mh-52 {
        height: 52px !important;
        min-height: 52px !important;
        line-height: 52px !important;
    }

    .MuiButton-label {
        color: rgba($color-secondary, 0.7) !important;

        .material-icons {
            color: rgba($color-secondary, 0.2) !important;
            padding-left: 10px;
        }
    }

    &:hover {
        background-color: rgba($black, 0.02) !important;
    }

    &.primary {
        background-color: $color-active !important;

        .MuiButton-label {
            color: $white !important;
    
            .material-icons {
                color: $white !important;
            }
        }

        &:hover {
            background-color: rgba($color-active, 0.8) !important;
        }
    }
}

.custom-footer-grantt-chart-btn {
    .MuiButton-label {
        span {
            color: #7F7F7F;
            padding-right: 10px;
            text-transform: capitalize;
        }

        i, svg {
            color: #7F7F7F;
        }
    }

    &.active {
        .MuiButton-label {
            span {
                color: #484848;
                padding-right: 10px;
                text-transform: capitalize;
            }

            i, svg {
                color: #484848;
            }
        }
    }
}



.billed-history-btn {
    // position: absolute;
    // right: 0px;
    top: 5px;

    button {
        padding: 5px;

        i {
            color: $color-secondary;
        }

        &:hover {
            i {
                color: $color-active;
            }
        }
    }
}
.custom-poper-billed-history {
    left: -20px !important;
    max-height: 300px !important;
    overflow: auto !important;
}



// .btn-danger {
//     border: 1px solid rgba(#EF476F, 0.10) !important;
//     background-color: #EF476F !important;
//     color: $white !important;
//     border-radius: 0px !important;
//     text-decoration: none !important;
//     min-height: 40px !important;
//     display: inline-block;
//     padding-left: 25px;
//     padding-right: 25px;
//     line-height: 40px;

//     .MuiButton-label {
//         color: $white !important;
//     }

//     &:disabled, .Mui-disabled {
//         border: 1px solid rgba($color-secondary, 0.60) !important;
//         background-color: rgba($color-secondary, 0.60) !important;
//     }
// }

.btn-blue {
    border: 1px solid rgba($color-active, 0.10) !important;
    background-color: $color-active !important;
    color: $white !important;
    border-radius: 0px !important;
    text-decoration: none !important;
    min-height: 40px !important;
    display: inline-block;
    padding-left: 25px;
    padding-right: 25px;
    line-height: 40px;

    .MuiButton-label {
        color: $white !important;
    }

    &:disabled, .Mui-disabled {
        border: 1px solid rgba($color-secondary, 0.60) !important;
        background-color: rgba($color-secondary, 0.60) !important;
    }
}

.btn-green {
    border: 1px solid rgba(#06D6A0, 0.10) !important;
    background-color: #06D6A0 !important;
    color: $white !important;
    border-radius: 0px !important;
    text-decoration: none !important;
    min-height: 40px !important;
    display: inline-block;
    padding-left: 25px;
    padding-right: 25px;
    line-height: 40px;

    .MuiButton-label {
        color: $white !important;
    }

    &:disabled, .Mui-disabled {
        border: 1px solid rgba($color-secondary, 0.60) !important;
        background-color: rgba($color-secondary, 0.60) !important;
    }
}
.ganttchart-btn-green {
    border: 1px solid rgba(#06D6A0, 0.10) !important;
    background-color: #06D6A0 !important;
    color: $white !important;
    border-radius: 5px !important;
    text-decoration: none !important;
    min-height: 40px !important;
    display: inline-block;
    padding-left: 25px;
    padding-right: 25px;
    line-height: 40px;

    .MuiButton-label {
        color: $white !important;
        text-transform: none;
    }

    &:disabled, .Mui-disabled {
        border: 1px solid rgba($color-secondary, 0.60) !important;
        background-color: rgba($color-secondary, 0.60) !important;
    }
}
.ganttchart-btn-blue {
    border: 1px solid rgba($color-active, 0.10) !important;
    background-color: $color-active !important;
    color: $white !important;
    border-radius: 5px !important;
    text-decoration: none !important;
    min-height: 40px !important;
    display: inline-block;
    padding-left: 25px;
    padding-right: 25px;
    line-height: 40px;

    .MuiButton-label {
        color: $white !important;
        text-transform: none;
    }

    &:disabled, .Mui-disabled {
        border: 1px solid rgba($color-secondary, 0.60) !important;
        background-color: rgba($color-secondary, 0.60) !important;
    }
}

.link-btn-active {
    text-decoration: none;
    color: $color-active !important;
    font-weight: bold;
    
    &:hover {
        color: rgba($color-active, 0.6) !important;
    }
}


.custom-expand-icon-btn {
    padding: 6px !important;
    background-color: $color-active !important;

    .MuiIconButton-label {
        i {
            position: relative;
            top: 1px;
            color: white !important;
        }
    }

    &:hover {
        background-color: rgba($color-active, 0.5) !important;
    }
}

.custom-remove-icon-btn {
    padding: 6px !important;

    .MuiIconButton-label {
        i {
            position: relative;
            top: 1px;
            color: $color-danger !important;
        }
    }
}


.strip-pay-btn {
    border: 0px !important;
    background-color: $color-active !important;
    color: $white !important;
    border-radius: 15px !important;
    text-decoration: none !important;
    text-transform: none !important;
    min-height: 35px !important;
    display: inline-block;
    padding-left: 25px;
    padding-right: 25px;
    line-height: 35px;

    .MuiButton-label {
        padding-left: 15px;
        padding-right: 15px;
    }

    &:hover {
        background-color: rgba($color-active, 0.8) !important;
    }

    &:disabled, .Mui-disabled {
        background-color: rgba($color-secondary, 0.60) !important;
    }
}


.customers-sites-new-btn {
    border: 1px solid $color-active !important;
    background-color: transparent !important;
    color: $color-active !important;
    border-radius: 5px !important;
    text-decoration: none !important;
    min-height: 40px !important;
    display: inline-block;
    padding-left: 25px;
    padding-right: 25px;
    line-height: 40px;
    min-width: 100px !important;
    box-shadow: none !important;

    .MuiButton-label {
        color: $color-active !important;
    }

    &:hover {
        background-color: $color-active !important;

        
        .MuiButton-label {
            color: $white !important;
        }
    }

    &:disabled, .Mui-disabled {
        border: 1px solid rgba($color-secondary, 0.60) !important;
        background-color: rgba($color-secondary, 0.60) !important;
    }
}

.customers-services-new-btn {
    border: 1px solid $color-active !important;
    background-color: $color-active !important;
    color: $white !important;
    border-radius: 5px !important;
    text-decoration: none !important;
    min-height: 40px !important;
    display: inline-block;
    padding-left: 25px;
    padding-right: 25px;
    line-height: 40px;
    min-width: 100px !important;

    &:hover {
        background-color: rgba($color-active, 0.8) !important;
    }

    &:disabled, .Mui-disabled {
        border: 1px solid rgba($color-secondary, 0.60) !important;
        background-color: rgba($color-secondary, 0.60) !important;
    }
}

.icon-fill-btn-blue {
    padding: 5px;
    color: $color-active !important;

    .MuiIconButton-label {
        color: $color-active !important;

        .material-icons {
            color: $color-active !important;
            font-size: 21px !important;
        }
    }

    svg {
        top: 0px;
        position: relative;
        opacity: 1 !important;

        path {
            opacity: 1 !important;
            fill: transparent !important;
            stroke: $color-active !important;
        }
    }

    &.no-p {
        padding: 0px !important;
    }
}


.reports-primary-btn {
    border: 1px solid $color-primary !important;
    background-color: $color-primary !important;
    color: $white !important;
    border-radius: 10px !important;
    text-decoration: none !important;
    min-height: 40px !important;
    display: inline-block;
    line-height: 40px;

    .MuiButton-label {
        padding-left: 15px;
        padding-right: 15px;
        text-transform: none;
    }

    &:hover {
        background-color: rgba($color-primary, 0.8) !important;
    }

    &:disabled, .Mui-disabled {
        border: 1px solid rgba($color-secondary, 0.60) !important;
        background-color: rgba($color-secondary, 0.60) !important;
    }

    &.p-lr-35 {
        .MuiButton-label {
            padding-left: 35px;
            padding-right: 35px;
        }
    }
}

.manage-link-default {
    color: $color-secondary !important;
    text-decoration: unset !important;
    font-weight: bold;
    
    &:hover, &.active {
        color: $color-active !important;
    }
}

.manage-icon-btn {
    svg {
        width: 15px;
        height: 15px;
    }

    i {
        color: $color-active;
        font-size: 18px;
    }

    &.Mui-disabled {
        svg {
            path {
                fill: $color-secondary;
            }
        }

        &:not(.fill){
            svg {
                path {
                    stroke: $color-secondary;
                }
            }
        }

        i {
            color: $color-secondary;
        }
    }

    &.audit-trail {
        &.Mui-disabled {
            svg {
                path {
                    fill: transparent;

                    &:first-child {
                        fill: transparent;
                    }
                }
            }

            &:not(.fill){
                svg {
                    path {
                        stroke: $color-secondary;
                        
                        &:first-child {
                            stroke: transparent;
                        }
                    }
                }
            }
        }
    }
}

.rounded-btn {
    border-radius: 20px !important;

    .MuiButton-label {
        text-transform: capitalize !important;
    }

    &.Mui-disabled {
        background-color: rgba($color-secondary, 0.30) !important;
    }
}

.rounded-outline-btn {
    border-radius: 20px !important;
    border: 1px solid $color-active !important;
    background-color: transparent !important;

    .MuiButton-label {
        color: $color-active !important;
        text-transform: capitalize !important;
    }

    &.Mui-disabled {
        background-color: rgba($color-secondary, 0.30) !important;
        border: 1px solid transparent !important;

        .MuiButton-label {
            color: white !important;
        }
    }
}

.link-default-btn {
    box-shadow: unset !important;
    
    .MuiButton-label {
        text-transform: capitalize !important;
    }

    &:hover {
        span {
            font-weight: bold;
        }
    }
}

.link-default-outline-btn {
    box-shadow: unset !important;
    border: 2px solid $color-secondary !important;
    border-radius: 25px !important;
    padding-left: 25px !important;
    padding-right: 25px !important;
    line-height: 1.65 !important;

    .MuiButton-label {
        text-transform: capitalize !important;
        font-weight: bold;
    }

    &:hover {
        -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16) !important;
        -moz-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16) !important;
        box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16) !important;
    }
}

.job-status-types-btn {
    box-shadow: unset !important;
    border-radius: 5px !important;
    background-color: transparent !important;
    min-width: 40px !important;
    padding-left: 5px !important;
    padding-right: 5px !important;


    .MuiButton-label {
        text-transform: capitalize !important;
        color: $color-secondary !important;
        font-weight: bold;
    }

    &:hover, &.active {
        background-color: rgba($color-active, 0.10) !important;
    }

    &.Mui-disabled {
        background-color: rgba($color-secondary, 0.30) !important;
    }
}

.primary-btn {
    border-radius: 5px !important;
    border: 1px solid $color-active !important;
    text-transform: capitalize !important;

    &:hover {
        background-color: rgba($color-active, 0.8) !important;
    }

    &.Mui-disabled {
        background-color: rgba($color-secondary, 0.8) !important;
    }
}

.success-btn{
    border-radius: 5px !important;
    border: 1px solid #06D6A0 !important;
    text-transform: capitalize !important;
    color: $white !important;
    background-color: rgba(6,214,160, 0.8) !important;
    span{
        color: $white !important;
    }
    

    &:hover {
        background-color:rgba(6,214,160, 0.8) !important;
    }

    &.Mui-disabled {
        background-color:rgba($color-secondary, 0.8) !important;
    }
}

.suu-btn {
    border-radius: 5px !important;
    border: 1px solid $color-active !important;
    text-transform: capitalize !important;

    &:hover {
        background-color: rgba($color-active, 0.8) !important;
    }

    &.Mui-disabled {
        background-color: rgba($color-secondary, 0.8) !important;
    }
}
.default-btn {
    border-radius: 5px !important;
    border: 1px solid $color-secondary !important;
    text-transform: capitalize !important;

    &:hover {
        background-color: rgba($color-secondary, 0.1) !important;
    }

    &.Mui-disabled {
        background-color: rgba($color-secondary, 0.8) !important;
    }
}


.MuiButton-containedSecondary {
    background-color: $color-danger !important;
    .MuiButton-label {
        color: white !important;
    }
}


.btn.btn-secondary {
    color: white;
}