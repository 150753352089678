.grip-handle {
    width: 0px;
    
    .grip-resizable {
        table-layout: auto !important;
        opacity: 1 !important;
        width: 0px !important;
        height: 80% !important;
        background-color: transparent !important;
        margin-left: 2px;
        margin-right: 2px;
        border-left: 2px dashed rgba(72, 72, 72, 0.1);
        z-index: 1;
        display: none;

        &:hover {
            border-left: 2px dashed rgba(72, 72, 72, 0.6);
        }
    }

    &.grip-drag {
        border-left: 0px !important;
    }

    .custom-grip {
        cursor: col-resize;
        table-layout: auto !important;
        opacity: 1 !important;
        width: 0px !important;
        height: 80% !important;
        background-color: transparent !important;
        margin-left: 2px;
        margin-right: 2px;
        // border-left: 2px dashed rgba(72, 72, 72, 0.1);
        border-left: 2px dashed transparent;
        z-index: 1;

        &:hover {
            border-left: 2px dashed rgba(72, 72, 72, 0.6);
        }

        @include respond-below('md') {
            // background-color: rgba(72, 72, 72, 0.6) !important;
            
            border-width: 20px !important;
            left: -5px !important;
            font-size: 25px;
            color:rgba(72, 72, 72, 0.6) !important;
            
            text-align: center !important;
            align-items: center !important;
            align-content: center !important;
            display: flex !important;

            font-family: "Font Awesome 5 Free";
            -moz-osx-font-smoothing: grayscale;
            -webkit-font-smoothing: antialiased;
            display: inline-block;
            font-weight: 900;
            font-style: normal;
            font-feature-settings: normal;
            font-variant: normal;
            text-rendering: auto;
            line-height: 1;
            &:before {
                content: "";
                left: -15px;
                position: relative;
            }

            &:hover {
                i {
                    opacity: 0.5 !important;
                }
            }
        }
    }
}
.grip-container {
    position: sticky !important;
    top: 0px !important;
    z-index: 1 !important;
}

.billing, .jobs {
    .grip-container {
        position: relative !important;
        top: 0px !important;
    }
}