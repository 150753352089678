.custom-chat-component {
    background-color: #F7F9FD;

    .message-list {
        .rce-mlist {
            height: 300px;

            .rce-mbox {
                padding: 15px;
                box-shadow: unset;
                margin-top: 10px;
                margin-bottom: 10px;
                background-color: transparent;

                svg {
                    display: none;
                }

                &.rce-mbox-right {
                    border-top-right-radius: 15px;
                    border-top-left-radius: 15px;
                    border-bottom-right-radius: 0px;
                    border-bottom-left-radius: 15px;
                    background-color: #D3EBFA;
                }

                // .rce-mbox-body {
                //     .rce-mbox-time  {
                //         display: none;
                //     }
                // }
            }
        }
    }

    .rce-container-input {
        background-color: transparent;

        textarea {
            height: 45px;
            background-color: transparent;
            padding-left: 25px;
            padding-bottom: 0px;
            box-sizing: unset;

            &[disabled] {
                cursor: not-allowed !important;
            }
        }

        .rce-input-buttons {
            i {
                color:$color-active;
            }

            button {
                &.Mui-disabled {
                    i {
                        color: #999a9d !important;
                    }
                }
            }
        }
    }


    &.job-form-chat {
        background-color: white;
        
        .message-list {
            .rce-mlist {
                height: 200px;
            }
        }

        .custom-wrapper {
            padding: 10px;
        }
        .rce-container-input{
            background-color: #F4F8FF;
            border-radius: 6px;
        }
    }
}