.jobs-form {
  .page {
    margin-top: 64px;

    @include respond-below("lg") {
      margin-top: 109px;
    }

    @include respond-below("md") {
      margin-top: 109px;
    }

    @include respond-below("sm") {
      margin-top: 109px;
    }

    @include respond-below("xs") {
      margin-top: 109px;
    }
  }
}

.jobs-view-layout {
  background-color: #f0f3fa;

  .top-section {
    position: sticky;
    background-color: white;
    border-top: 1px solid #f4f5f8;
    z-index: 1;

    &.small-screen {
      position: relative;
      top: 0px !important;
    }

    -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
    -moz-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);

    @include respond-below("md") {
      margin-bottom: 10px;
      position: relative;
      top: 0 !important;
    }

    .custom-close-btn {
      padding: 0px;
      padding-top: 5px;

      .MuiIconButton-label {
        i {
          color: rgba($color-secondary, 0.4);
          font-size: 35px;
        }
      }

      &:hover {
        background-color: transparent !important;

        .MuiIconButton-label {
          i {
            color: #f4f5f8;
          }
        }
      }

      .MuiTouchRipple-root {
        display: none;
      }
    }
  }

  .job-statuses-types-list-wrapper {

    .custom-tabs {
      overflow: unset !important;
    }

    .MuiTabs-scrollable {
      overflow: unset !important;
    }

    // margin-left: -5%;
    // @media (max-width: 959px) {
    //   background-color: white;
    // }

    .MuiTabs-scroller {
      // overflow-x: auto !important;

      button {
        // cursor: default !important;
        padding-left: 0px !important;
        padding-right: 0px !important;
      }
    }

    .MuiTabs-indicator {
      background-color: transparent !important;
    }
  }

  .custom-job-split-btn-dropdown {
    display: inline-block;

    > div {
      width: auto !important;
      border: 1px solid $color-active !important;
      border-radius: 5px !important;
      overflow: hidden;
    }

    button {
      padding: 10px;
      background-color: white !important;

      .MuiButton-label {
        color: $color-active !important;
        font-weight: bold;

        i {
          color: $color-active !important;
        }
      }

      &:first-child {
        border-right: 1px solid $color-active !important;
      }

      &.Mui-disabled {
        pointer-events: auto !important;
        cursor: not-allowed !important;
        background-color: rgba($color-secondary, 0.1) !important;

        .MuiButton-label {
          color: $color-secondary !important;
          font-weight: bold;

          i {
            color: $color-secondary !important;
          }
        }
      }
    }
  }
}

// @media all and (min-width: 1325px) {
//   .job-statuses-types-list-wrapper {
//     margin-left: -5% !important;

//   }

// }

.billing-page {
  .custom-job-split-btn-dropdown {
    display: inline-block;

    > div {
      width: auto !important;
      border: 1px solid $color-active !important;
      border-radius: 5px !important;
      overflow: hidden;
    }

    button {
      padding: 10px;
      background-color: white !important;

      .MuiButton-label {
        color: $color-active !important;
        font-weight: bold;

        i {
          color: $color-active !important;
        }
      }

      &:first-child {
        border-right: 1px solid $color-active !important;
      }

      &.Mui-disabled {
        pointer-events: auto !important;
        cursor: not-allowed !important;
        background-color: rgba($color-secondary, 0.1) !important;

        .MuiButton-label {
          color: $color-secondary !important;
          font-weight: bold;

          i {
            color: $color-secondary !important;
          }
        }
      }
    }
  }
}


@media all and (min-width: 1025px)and (max-width: 1325px)  {
  // .job-statuses-types-list-wrapper {
  //   margin-left: -5% !important;
   
  // }
  .MuiGrid-grid-lg-8{
    max-width: 71.666667% !important
  }
}
// @media all and (max-width: 1023px) {
//   .job-statuses-types-list-wrapper {
//     margin-left: -0% !important;
   
//   }
  
// }
