.job-form-tabs-component {
    width: 100%;

    @include respond-below('md') {
        display: block !important;
    }

    button {
        border: 0;
        border-bottom: 0px;
        height: 100%;
        width: 100%;

        &:only-child {
            width: unset;
        }
        
        .MuiButton-label {
            text-transform: capitalize !important;
        }

        @include respond-below('md') {
            width: 50%;
        }

        &:hover {
            border-bottom: 0px;
            background-color: transparent;

            span {
                color: $color-secondary !important;
                font-weight: bold;
            }
        }

        &.active {
            span {
                color: $color-secondary !important;
                font-weight: bold;
            }
        }

        &.Mui-disabled {
            border: 0px !important;

            .MuiButton-label {
                color: rgba($color-secondary, 0.5) !important;
                font-weight: normal;
            }
        }

        &[disabled] {
            cursor: not-allowed !important;
            pointer-events: all !important;
        }
    }
}