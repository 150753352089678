.custom-loading-overlay {
    display: none;

    &.active {
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        display: flex;
        z-index: 9999;

        background-color: rgba(#000000, 0.4);

        justify-content: center;
        align-items: center;

        .content {
            text-align: center;

            .spinner-border {
                z-index: 1;
                width: 2rem;
                height: 2rem;
            }
    
            .text {
                color: white;
                font-size: 16px;
            }
        }
    }
}