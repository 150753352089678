.custom-check-input-component {
    position: relative;

    input {
        padding-right: 55px;
        padding-left: 10px !important;
        padding-top: 0px !important;
        padding-bottom: 0px !important;
        min-height: 40px !important;
    }

    textarea {
        line-height: normal;
    }

    &.number {
        min-width: 40px;

        input {
            padding-right: 0px;
        }
    }
    
    .custom-end-adornment {
        position: inherit;
        right: 0;
        left: 0;
        margin: 0 auto;
        text-align: center;
        background-color: #F7F9FD;
        bottom: 7px !important;

        // &.number {
        //     right: 18px;
        // }

        .custom-check-icon {
            width: 20px;
            font-size: 20px;
            margin-right: 2px;
            cursor: pointer;

            color: #06D6A0;

            &:hover {
                color: rgba(#06D6A0, 0.6);
            }
        }

        .custom-x-icon {
            width: 20px;
            font-size: 20px;
            cursor: pointer;

            color: #7F7F7F;

            &:hover {
                color: rgba(#7F7F7F, 0.6);
            }

            filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=0.5);
            -webkit-transform: rotate(-45deg);
            -moz-transform: rotate(-45deg);
            -ms-transform: rotate(-45deg);
            -o-transform: rotate(-45deg);
            transform: rotate(-45deg);
        }
    }
}