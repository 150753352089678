.custom-dialog-component {
    &.default {
        .MuiDialog-paper {
            background-color: $color-default !important;
        }
    }

    &.no-padding {
        .MuiDialog-paper {
            padding: 0px !important;

            .custom-paper {
                padding: 0px !important;
            }
        }
    }

    &.min-width-500 {
        .MuiDialog-paper {
            min-width: 500px;
        }
    }
    &.max-width-1366 {
        .MuiDialog-paper {
            max-width: 1366px;
        }
    }
    
    .MuiDialog-paper {
        // width: 95vw !important;
        max-width: 1366px;
    }

    &.max-width-100 {
        .MuiDialog-paper {
            max-width: 100%;
        }
    }

    &.max-w-350 {
        .MuiDialog-paper {
            max-width: 350px !important;
        }
    }
    &.max-w-720 {
        .MuiDialog-paper {
            max-width: 720px !important;
        }
    }
    @include respond-below('lg') {
        .MuiDialog-paper {
            margin: 30px 10px 10px 10px;
        }
    }

    .MuiDialog-paper {
        overflow-y: visible;
    }

    .dialog-close-btn-out {
        position: absolute;
        top: -40px;
        right: -40px;
        
        span {
            color: $white;

            svg {
                font-size: 30px;
            }
        }
    }

    .dialog-close-btn-in {
        position: absolute;
        top: 3px;
        right: 3px;
    
        span {
            color: rgba($color-secondary, 0.10);

            svg {
                font-size: 30px;
            }
        }
    }


    &.billing-site-dialog {
        .MuiDialogTitle-root {
            background-color: #F4F5F8;

            .dialog-close-btn-in {
                position: unset !important;
                top: auto !important;
                right: auto !important;
                
                .MuiIconButton-label {
                    i {
                        color: #7F7F7F;
                    }
                }
            }
        }
    }
}