.custom-up-down-component {
    position: relative;

    .custom-wrapper {
        align-items: center;
        justify-content: center;
        display: flex;
        width: 53px;
        min-width: 80px;
        min-height: 33px;

        padding: 0px 15px;
        
        border-radius: 20px;
        border: 1px solid $color-secondary;

        .custom-text {
            display: inline-block;
            user-select: none;
            color: $color-secondary;
            width: 40px;
            padding-right: 10px;
        }

        .custom-btns {
            display: inline-block;
            color: $color-secondary;
            cursor: pointer;
            position: absolute;
            right: 8px;

            i {
                position: relative;
                font-size: 18px;

                &:hover {
                    opacity: 0.6;
                }
            }

            .custom-up {
                position: relative;
                display: flex;

                i {
                    top: 4px;
                }
            }

            .custom-down {
                position: relative;
                display: flex;

                i {
                    bottom: 4px;
                }
            }
        }
    }

    &.disabled {
        .custom-wrapper {
            cursor: not-allowed;
            border: 1px solid #CCCCCC;

            .custom-btns {
                i {
                    &:hover {
                        opacity: 1;
                    }
                }
            }
        }
    }
}