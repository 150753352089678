/* SCROLL */
::-webkit-scrollbar {
    display: block;
    width: 8px;
    height: 16px;
}

::-webkit-scrollbar-thumb {
    background: $gray;
}

.hideScroll::-webkit-scrollbar {
    display: none;
}

.hideScroll ::-moz-scrollbar {
    display: none;

}

.hideScroll {
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-color: transparent transparent !important; /*just hides the scrollbar for firefox */
}
/* END SCROLL */


/* BORDER */
.no-border {
    border: 0 !important;
}
.custom-border {
    border: 1px solid rgba($color-secondary, 0.3) !important;
}
.responsive-border-right {
    border-right: 1px solid $bg-color;

    @include respond-below('lg') {
        border-right: 0px;
    }
}
/* END BORDER */


/* RADIUS */
.no-border-radius {
    border-radius: 0 !important;
}

.square-border {
    border-radius: 0 !important;
}

.circle {
    border-radius: 50%;
}

.border-radius-50 {
    border-radius: 50px;
}

.border-radius-5 {
    border-radius: 5px;
}
/* END RADIUS */


/* MARGIN */
.m-0-auto {
    margin: 0 auto;
}
.mobile-m-0-auto {
    @include respond-below('md') {
        margin: 0 auto;
    }
}
/* END MARGIN */


/* POSITIONS */
.position-relative {
    position: relative !important;
}
.position-absolute {
    position: absolute !important;
}
.jumbotron-center {
    position: absolute;
    z-index: 1;
    top: 0;
}
/* END POSITIONS */


/* CURSOR */
.cursor-pointer, 
.cursor-hand {
    cursor: pointer !important;
}

.cursor-grab {
    cursor: grab !important;
}

.no-cursor {
    cursor: default;
}

.cursor-not-allowed {
    cursor: not-allowed !important;
}
/* END CURSOR */


/* UNDERLINE */
.no-underline {
    text-decoration: none !important;
}
/* END UNDERLINE */


/* OVERFLOW */
.overflow-hidden {
    overflow: hidden;
}
/* END OVERFLOW */


/* Z INDEX */
.no-z-index {
    z-index: unset !important;
}

.z-index-1 {
    z-index: 1;
}
/* END Z INDEX */


/* DIV IMG */
.div-img-contain {
    background-repeat: no-repeat !important;
    background-position: center center !important;
    background-size: contain !important;
}

.div-img-cover {
    background-repeat: no-repeat !important;
    background-position: center center !important;
    background-size: cover !important;
}
/* END DIV IMG */


/* VERTICAL ALIGN */
.v-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.v-left-center {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.v-right-center {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.v-bottom-right {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
}
/* END VERTICAL ALIGN */


/* DOTS */
.dots {
    display: inline-block;
    width: 100%;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    line-height: 15px;
    font-size: 13px;
    font-weight: 500 !important;
}
/* END DOTS */


/* ON HOVER */
.on-hover {
    cursor: pointer !important;
    
    &:hover {
        color: $color-primary !important;

        * {
            color: $color-primary !important;
        }
    }
}
/* ON HOVER */


/* KBD */
kbd {
    background-color: #eee;
    border-radius: 3px;
    border: 1px solid #b4b4b4;
    color: #333;
    display: inline-block;
    font-size: .85em;
    font-weight: 700;
    padding: 2px 4px;
    white-space: nowrap;
}
/* END KBD */


/* ELLIPSIS */
.ellipsis-1 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.ellipsis-2 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.ellipsis-3 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.ellipsis-4 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
}

.ellipsis-5 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
}
/* END ELLIPSIS */


/* ANIM ROTATE */
.rotate {
    -webkit-transition: all .3s linear;
    -moz-transition: all .3s linear;
    -o-transition: all .3s linear;
    -ms-transition: all .3s linear;
    transition: all .3s linear;
}

.rotate.down {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
}

.rotate-180 {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
}
/* END ANIM ROTATE */

/* HIGHLIGHT ANIM */
.highlight-anim {
    -moz-animation: blinkingAnim 0.7s 3;
    -o-animation: blinkingAnim 0.7s 3;
    -webkit-animation: blinkingAnim 0.7s 3;
    animation: blinkingAnim 0.7s 3;
}

.highlight-anim-yellow {
    -moz-animation: blinkingAnimYellow 0.7s 3;
    -o-animation: blinkingAnimYellow 0.7s 3;
    -webkit-animation: blinkingAnimYellow 0.7s 3;
    animation: blinkingAnimYellow 0.7s 3;
}

.highlight-anim-red {
    -moz-animation: blinkingAnimRed 0.7s 3;
    -o-animation: blinkingAnimRed 0.7s 3;
    -webkit-animation: blinkingAnimRed 0.7s 3;
    animation: blinkingAnimRed 0.7s 3;
}
/* END HIGHLIGHT ANIM */

/* LIST */
.border-top-list {
    > div {
        border-top: 1px solid $bg-color;
    }
}

.border-right-list {
    > div {
        border-right: 1px solid $bg-color;
    }
}
/* END LIST */


/* SPIN */
@keyframes spinAnim {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
}

.spin {
  animation-name: spinAnim;
  animation-duration: 1000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
/* END SPIN */


/* SPLIT PANEL */
.custom-split-panel {
    &.mobile-layout {
        @include respond-above('md') {
            display: none;
        }
    }

    &.desktop-layout {
        .splitter-layout {
            height: calc(100% - 73px);
            left: 0px;

            .layout-pane {
                &:first-child {
                    margin-right: 10px;
                    overflow-y: hidden;
                }

                &:last-child {
                    margin-left: 10px;
                }
            }

            .layout-splitter {
                background-color: rgba($color-secondary, 0.2) !important;
                position: relative;

                &::after {
                    content: '|||';
                    width: 15px;
                    height: 60px;
                    position: absolute;
                    top: calc(50% - 60px);
                    left: -5px;
                    z-index: 1;
                    text-align: center;
                    line-height: 50px;
                    font-weight: 100;
                    font-family: 'Material Icons';

                    background-color: white;
                    color: rgba($color-secondary, 0.6);
                    border: 1px solid rgba($color-secondary, 0.2);
                    border-radius: 10px;
                    box-shadow: 0px 0px 2px $bg-color;
                }

                &:hover {
                    background-color: rgba($color-active, 0.3) !important;

                    &::after {
                        color: rgba($color-active, 1);
                        border: 0;
                        box-shadow: 0px 0px 4px $color-active;
                    }
                }
            }

            &.layout-changing {
                .layout-splitter {
                    background-color: rgba($color-active, 0.3) !important;

                    &::after {
                        color: rgba($color-active, 1);
                        border: 0;
                        box-shadow: 0px 0px 4px $color-active;
                    }
                }
            }
        }

        .desktop-layout-min-width {
            min-width: 800px;
        }

        @include respond-below('md') {
            display: none;
        }
    }
}
/* END SPLIT PANEL */