.custom-timeline-component {
    .MuiTimelineItem-missingOppositeContent {
        &::before {
            content: unset;
        }
    }
    &.min-width-110 {
        ul {
            li {
                .MuiTimelineOppositeContent-root {
                    min-width: 110px !important;
                }
            }
        }
    }
    &.min-width-120 {
        ul {
            li {
                .MuiTimelineOppositeContent-root {
                    min-width: 120px !important;

                    @include respond-below('sm') {
                        min-width: 80px !important;
                    }
                }
            }
        }
    }
    
    ul {
        @include respond-below('sm') {
            padding: 0px;
        }

        li {
            .MuiTimelineOppositeContent-root {
                flex: 0;
                min-width: 120px;
                font-weight: bold;

                @include respond-below('sm') {
                    min-width: 60px;
                }
            }

            .MuiTimelineSeparator-root {
                padding-top: 3px;

                .MuiTimelineDot-root {
                    margin: 0px;
                    box-shadow: unset !important;

                    svg {
                        font-size: 15px;
                    }

                    &.MuiTimelineDot-defaultPrimary {
                        background-color: $color-active;
                    }

                    &.MuiTimelineDot-defaultGrey {
                        background-color: #E6E6E6;
                    }
                }

                .MuiTimelineConnector-root {
                    width: auto;
                    background-color: transparent;
                    border-right: 2px dashed #E6E6E6;
                }
            }

            .MuiTimelineContent-root {
                .custom-timeline-item-title {
                    align-items: center;
                    display: flex;
                    padding-bottom: 5px;

                    > div {
                        display: flex;
                    }

                    font-weight: bold;

                    i {
                        font-size: 21px;
                    }
                }

                .custom-timeline-item-desc {
                    word-break: break-all;
                    padding-bottom: 20px;

                    b {
                        color: $color-active;
                    }

                    &.pb-0 {
                        padding-bottom: 0px;
                    }
                    &.pb-5 {
                        padding-bottom: 5px;
                    }
                    &.pb-10 {
                        padding-bottom: 10px;
                    }
                }
            }

            .arr-diff-list {
                max-height: 100px;
                overflow: auto;
                border: 1px solid rgba(0, 0, 0, 0.12);
                padding: 5px;
            }

            .highlight {
                background-color: #fff2a8;
                color: black;
                padding-left: 5px;
                padding-right: 5px;
                cursor: help;
            }
            .highlight-green {
                background-color: #a8ffaf;
                color: black;
                padding-left: 5px;
                padding-right: 5px;
            }
            .highlight-red {
                background-color: #ffa8a8;
                color: black;
                padding-left: 5px;
                padding-right: 5px;
            }

            .highlight-row {
                background-color: #fff2a8;
                color: black;
                cursor: help;
                padding-top: 3px;
                padding-bottom: 3px;
            }
            .highlight-row-green {
                background-color: #a8ffaf;
                color: black;
                padding-top: 3px;
                padding-bottom: 3px;
            }
            .highlight-row-red {
                background-color: #ffa8a8;
                color: black;
                padding-top: 3px;
                padding-bottom: 3px;
            }
        }
    }
}

.highlight {
    background-color: #fff2a8;
    color: black;
    padding-left: 5px;
    padding-right: 5px;
    cursor: help;
}
.highlight-green {
    background-color: #a8ffaf;
    color: black;
    padding-left: 5px;
    padding-right: 5px;
}
.highlight-red {
    background-color: #ffa8a8;
    color: black;
    padding-left: 5px;
    padding-right: 5px;
}

.highlight-row {
    background-color: #fff2a8;
    color: black;
    cursor: help;
    padding-top: 3px;
    padding-bottom: 3px;
}
.highlight-row-green {
    background-color: #a8ffaf;
    color: black;
    padding-top: 3px;
    padding-bottom: 3px;
}
.highlight-row-red {
    background-color: #ffa8a8;
    color: black;
    padding-top: 3px;
    padding-bottom: 3px;
}