.nav {
    gap: 4px;
    
    a {
        border: 0px;
        border-radius: $border-radius;
        background-color: white;
        padding: 8px 12px;
        color: $gray-700;
        font-weight: 500;

        &.active {
            color: $blue-600;
        }

        &:focus, &.active {
            color: $blue-500;
            background-color: $blue-focus1;
        }

        &.disabled {
            color: $gray-500;
        }
    }
}