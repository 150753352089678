.split-button-component {
    &.right {
        text-align: right;
    }

    &.h-34 {
        button {
            height: 34px;
        }
    }

    &.h-39 {
        button {
            height: 44px;
        }
    }

    > div {
        z-index: 1;

        > button {
            padding: 5px;
            height: auto;

            &:first-child {
                border-top-left-radius: 5px !important;
                border-bottom-left-radius: 5px !important;
                border-right: 1px solid $white !important;
                padding-left: 15px;
                padding-right: 15px;

                &.p-5 {
                    padding-left: 5px;
                    padding-right: 5px;
                }
            }
    
            &:last-child {
                border-top-right-radius: 5px !important;
                border-bottom-right-radius: 5px !important;
            }
        }

        .custom-date-picker-component {
            .react-datepicker-wrapper {
                height: 100%;

                .react-datepicker__input-container {
                    height: 100%;
                    
                    button {
                        height: 100%;
                        background-color: $color-active !important;
                        padding: 5px;
                        
                        span {
                            color: $white !important;
                        }

                        &:first-child {
                            border-top-left-radius: 0px !important;
                            border-bottom-left-radius: 0px !important;
                            border-top-right-radius: 5px !important;
                            border-bottom-right-radius: 5px !important;
                            border-right: 0px solid $white !important;
                            padding-left: 15px;
                            padding-right: 15px;
                        }
    
                        .material-icons {
                            position: relative !important;
                            top: 2px !important;
                            color: $white !important;
                        }
                    }
                }
            }
        }
    }

    button {
        .MuiButton-label {
            font-size: 12px;
            text-transform: none;
            padding: 0px;
            min-width: unset;
        }
    }

    &.p-5 {
        > div {
            button {
                &:first-child {
                    padding-left: 5px;
                    padding-right: 5px;
                }
            }
        }
    }

    &.default {
        .custom-date-picker-component {
            .react-datepicker-wrapper {
                .react-datepicker__input-container {
                    button {
                        background-color: rgba($color-secondary, 0.20) !important;
                        
                        span {
                            color: $white !important;
                        }
                    }
                }
            }
        }

        button {
            background-color: rgba($color-secondary, 0.20) !important;
            
            span {
                color: $white !important;
            }
        }
    }

    &.outline-primary {
        border: 1px solid $color-active !important;
        border-radius: 5px !important;

        .MuiButtonGroup-contained {
            box-shadow: none !important;
        }

        button {
            background-color: rgba($white, 0.20) !important;
            box-shadow: none !important;
            
            .MuiButton-label {
                font-size: $font-size-14;
                text-transform: none;
                padding: 0px;
                min-width: unset;
            }

            span {
                color: $color-active !important;
            }

            &:first-child {
                border-right: 0px solid $white !important;
            }

            .material-icons {
                color: $color-active !important;
            }
            
            &:hover {
                background-color: $white !important;
            }
        }
    }

    &.custom-billing-dropdown {
        border: 0px !important;
        border-radius: 0px !important;

        .MuiButtonGroup-contained {
            box-shadow: none !important;
        }

        button {
            background-color: transparent !important;
            box-shadow: none !important;
            padding: 0px !important;
            
            .MuiButton-label {
                font-size: $font-size-14;
                text-transform: none;
                padding: 0px;
                min-width: unset;
            }

            span {
                color: #EF476F !important;
            }

            &:first-child {
                border-right: 1px solid #7F7F7F !important;
            }

            .material-icons {
                color: #484848 !important;
                font-size: 34px;
                padding: 0px !important;
            }
            
            &:hover {
                background-color: transparent !important;

                span {
                    text-decoration: underline;
                }
            }
        }
    }


    &.job-status-tab-btn {
        > div {
            -webkit-box-shadow: unset !important;
            -moz-box-shadow: unset !important;
            box-shadow: unset !important;
            
            > button {
                border: 0px !important;
                background-color: transparent !important;
                border-radius: 0px !important;
                text-decoration: none !important;
                padding: 0px !important;
                height: auto !important;
                display: block !important;
                -webkit-box-shadow: unset !important;
                -moz-box-shadow: unset !important;
                box-shadow: unset !important;

                &:first-child {
                    border-top-left-radius: 0px !important;
                    border-bottom-left-radius: 0px !important;
                    border-right: 0px !important;
                    padding-left: 0px;
                    padding-right: 0px;
                    cursor: pointer !important;
                }
        
                &:last-child {
                    border-top-right-radius: 50pxpx !important;
                    border-bottom-right-radius: 0px !important;
                    cursor: pointer !important;
                }
    
                .MuiButton-label {
                    padding: 0px !important;
                    display: flex;
                    justify-content: flex-start;
                    
                    .material-icons {
                        color: rgba($color-secondary, 0.2) !important;
                    }
                }
        
                &:hover {
                    background-color: transparent !important;
                }
            }
        }
    }

    &.disabled {
        button {
            background-color: rgba($color-secondary, 0.7) !important;
        }
    }
}