.custom-dropdown-select-component {
  .react-dropdown-select {
    border: 0px;
    padding: 5px 15px;
    background-color: #f4f8ff !important;
    color: #484848 !important;
    border-radius: 5px;
    padding-top: 0px;
    padding-bottom: 0px;
    min-height: 50px;

    &:focus {
      outline: none;
      box-shadow: unset;
    }

    input {
      &::placeholder {
        color: rgba(#484848, 0.6) !important;
        font-weight: normal !important;
        font-style: italic;
        color: rgba($color-secondary, 0.6) !important;
        font-size: 14px;
      }

      &[disabled] {
        cursor: not-allowed !important;
        pointer-events: all !important;
      }
    }

    .react-dropdown-select-option {
      position: relative;
      background-color: white;
      min-height: 35px;
      padding-right: 40px;
      margin: 5px;
      display: flex;
      border-radius: 5px;

      -webkit-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.16);
      -moz-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.16);
      box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.16);

      .react-dropdown-select-option-label {
        position: relative;
        border-radius: 5px;
        color: $color-secondary;
        font-weight: bold;
        font-size: 13px;
        display: flex;
        align-items: center;
        padding-left: 10px;
      }

      .react-dropdown-select-option-remove {
        position: absolute;
        color: #ef476f;
        font-weight: bold;
        font-size: 18px;
        right: 5px;
        display: flex;
        align-items: center;
        height: 100%;

        &:hover {
          opacity: 0.5;
        }
      }

      &:hover {
        opacity: 1;

        .react-dropdown-select-option-label {
          opacity: 1;
        }
      }

      &.custom-waste-type-list-item {
        background-color: $color-active;

        .react-dropdown-select-option-label {
          color: white;
        }

        .react-dropdown-select-option-remove {
          color: white;
        }
      }
      &.custom-waste-type-list-item-red {
        background-color: $color-danger;

        .react-dropdown-select-option-label {
          color: white;
        }

        .react-dropdown-select-option-remove {
          color: white;
        }
      }
    }

    .react-dropdown-select-type-multi {
      input {
        height: 35px;
        margin: 5px;

        &[disabled] {
          cursor: not-allowed !important;
          pointer-events: all !important;
        }
      }
    }
  }

  input,
  div {
    outline: none !important;
    box-shadow: unset !important;
  }

  &.disabled {
    > div {
      cursor: not-allowed !important;
    }

    .react-dropdown-select {
      opacity: 1 !important;
    }
  }

  &.job-type-form {
    .react-dropdown-select {
      background-color: #f7f9fd !important;
      border-radius: 0px;
      min-height: 54px;
    }
    .react-dropdown-select-item-selected {
      span,
      i {
        color: white;
      }
    }

    &.disabled {
      > div {
        cursor: not-allowed !important;
      }

      .react-dropdown-select {
        opacity: 1 !important;
      }
    }
  }

  &.job-form-bin-waste {
    .react-dropdown-select-item-selected {
      span,
      i {
        color: white;
      }
    }

    &.disabled {
      > div {
        cursor: not-allowed !important;
      }

      .react-dropdown-select {
        opacity: 1 !important;
      }
    }
  }

  &.job-form-step-in {
    .react-dropdown-select-item-selected {
      span,
      i {
        color: white;
      }
    }

    &.disabled {
      > div {
        cursor: not-allowed !important;
      }

      .react-dropdown-select {
        opacity: 1 !important;
      }
    }
  }

  &.job-form-method {
    .react-dropdown-select {
      background-color: white !important;
      font-weight: bold;
      border-bottom: 1px solid #e5e5e5;
    }

    .react-dropdown-select-item-selected {
      span,
      i {
        color: white;
      }
    }

    &.disabled {
      > div {
        cursor: not-allowed !important;
      }

      .react-dropdown-select {
        opacity: 1 !important;
      }
    }
  }

  &.customer-form-immediately {
    width: 150px;

    .react-dropdown-select {
      background-color: white !important;
      font-weight: bold;
      border-radius: 0px !important;
      border-bottom: 1px solid #e5e5e5;
      min-height: 40px !important;
    }

    .custom-close-icon {
      top: 10px !important;
    }

    .react-dropdown-select-item-selected {
      span,
      i {
        color: white;
      }
    }

    &.disabled {
      > div {
        cursor: not-allowed !important;
      }

      .custom-close-icon {
        color: rgba(#7f7f7f, 0.6) !important;
      }

      .react-dropdown-select {
        opacity: 1 !important;

        .react-dropdown-select-content {
          color: rgba(#7f7f7f, 0.6) !important;
        }
      }
    }
  }
}

//  versi transparant background
.custom-dropdown-select-component-transparant {
  .react-dropdown-select {
    border: 0px;
    padding: 5px 5px;
    background-color: inherit !important;
    color: #484848 !important;
    border-radius: 5px;
    padding-top: 0px;
    padding-bottom: 0px;
    min-height: 50px;

    &:focus {
      outline: none;
      box-shadow: unset;
    }
    :nth-child(0) {
      background-color: #fff !important;
    }
    input {
      &::placeholder {
        color: rgba(#484848, 0.6) !important;
        font-weight: normal !important;
        font-style: italic;
        color: rgba($color-secondary, 0.6) !important;
        font-size: 14px;
      }

      &[disabled] {
        cursor: not-allowed !important;
        pointer-events: all !important;
      }
    }

    .react-dropdown-select-option {
      position: relative;
      background-color: white;
      min-height: 35px;
      padding-right: 40px;
      margin: 5px;
      display: flex;
      border-radius: 5px;

      -webkit-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.16);
      -moz-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.16);
      box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.16);

      .react-dropdown-select-option-label {
        position: relative;
        border-radius: 5px;
        color: $color-secondary;
        font-weight: bold;
        font-size: 13px;
        display: flex;
        align-items: center;
        padding-left: 10px;
      }

      .react-dropdown-select-option-remove {
        position: absolute;
        color: #ef476f;
        font-weight: bold;
        font-size: 18px;
        right: 5px;
        display: flex;
        align-items: center;
        height: 100%;

        &:hover {
          opacity: 0.5;
        }
      }

      &:hover {
        opacity: 1;

        .react-dropdown-select-option-label {
          opacity: 1;
        }
      }

      &.custom-waste-type-list-item:nth-child(1) {
        background-color: white !important;
        span {
          color: $color-secondary !important;
        }
      }

      &.custom-waste-type-list-item {
        background-color: $color-active;

        .react-dropdown-select-option-label {
          color: white;
        }

        .react-dropdown-select-option-remove {
          color: white;
        }
      }
      &.custom-waste-type-list-item-red {
        background-color: $color-danger;

        .react-dropdown-select-option-label {
          color: white;
        }

        .react-dropdown-select-option-remove {
          color: white;
        }
      }
    }

    .react-dropdown-select-type-multi {
      input {
        height: 35px;
        margin: 5px;

        &[disabled] {
          cursor: not-allowed !important;
          pointer-events: all !important;
        }
      }
    }
  }

  input,
  div {
    outline: none !important;
    box-shadow: unset !important;
  }

  &.disabled {
    > div {
      cursor: not-allowed !important;
    }

    .react-dropdown-select {
      opacity: 1 !important;
    }
  }

  &.job-type-form {
    .react-dropdown-select {
      background-color: #f7f9fd !important;
      border-radius: 0px;
      min-height: 54px;
    }
    .react-dropdown-select-item-selected {
      span,
      i {
        color: white;
      }
    }

    &.disabled {
      > div {
        cursor: not-allowed !important;
      }

      .react-dropdown-select {
        opacity: 1 !important;
      }
    }
  }

  &.job-form-bin-waste {
    .react-dropdown-select-item-selected {
      span,
      i {
        color: white;
      }
    }

    &.disabled {
      > div {
        cursor: not-allowed !important;
      }

      .react-dropdown-select {
        opacity: 1 !important;
      }
    }
  }

  &.job-form-step-in {
    .react-dropdown-select-item-selected {
      span,
      i {
        color: white;
      }
    }

    &.disabled {
      > div {
        cursor: not-allowed !important;
      }

      .react-dropdown-select {
        opacity: 1 !important;
      }
    }
  }

  &.job-form-method {
    .react-dropdown-select {
      background-color: white !important;
      font-weight: bold;
      border-bottom: 1px solid #e5e5e5;
    }

    .react-dropdown-select-item-selected {
      span,
      i {
        color: white;
      }
    }

    &.disabled {
      > div {
        cursor: not-allowed !important;
      }

      .react-dropdown-select {
        opacity: 1 !important;
      }
    }
  }

  &.customer-form-immediately {
    width: 150px;

    .react-dropdown-select {
      background-color: white !important;
      font-weight: bold;
      border-radius: 0px !important;
      border-bottom: 1px solid #e5e5e5;
      min-height: 40px !important;
    }

    .custom-close-icon {
      top: 10px !important;
    }

    .react-dropdown-select-item-selected {
      span,
      i {
        color: white;
      }
    }

    &.disabled {
      > div {
        cursor: not-allowed !important;
      }

      .custom-close-icon {
        color: rgba(#7f7f7f, 0.6) !important;
      }

      .react-dropdown-select {
        opacity: 1 !important;

        .react-dropdown-select-content {
          color: rgba(#7f7f7f, 0.6) !important;
        }
      }
    }
  }
}

.job-form-custom-label {
  color: #7f7f7f;
  font-size: 12px !important;
  font-weight: normal !important;

  .custom-star {
    color: #ef476f !important;
  }
}

.job-form-custom-text {
  color: #7f7f7f;
  font-weight: normal !important;
}
.job-form-custom-text-bold {
  color: #7f7f7f;
  font-weight: bold !important;
}

.job-form-custom-separator {
  &::after {
    content: "";
    position: absolute;
    top: 10px;
    width: 1px;
    height: 60%;
    background-color: #dee9ff;
  }
}

.react-dropdown-select-dropdown {
  width: auto !important;
  min-width: 350px !important;

}

.custom-close-icon {
  position: absolute;
  right: 16px;
  top: 15px;
  left: unset !important;
  z-index: 1;
  color: rgba($color-secondary, 0.65);
  cursor: pointer;

  &:hover {
    color: rgba($color-secondary, 1);
  }
}

.custom-dropdown-icon {
  position: absolute;
  right: 15px;
  top: 8px;
  left: unset !important;
  z-index: 1;
  color: $color-secondary;
  font-size: 32px !important;
  cursor: pointer;

  &:hover {
    color: rgba($color-secondary, 0.8);
  }
}

.custom-search-icon {
  position: absolute;
  right: 16px;
  top: 12px !important;
  left: unset !important;
  width: 27px !important;
  height: 27px !important;
  z-index: 1;
}

.custom-dot-icon {
  font-size: 9px;
  padding-left: 10px;
  padding-right: 10px;
  color: $color-active;
}

.reports-form-multi-tags {
  .react-dropdown-select {
    background-color: white !important;
    border-radius: 0px !important;
  }
}
