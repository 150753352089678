.nav-tabs {
    gap: 16px;
    border-bottom: 2px solid $gray-100;
    
    .nav-item {
        .nav-link {
            border: 0px;
            border-radius: 0px;
            background-color: white;
            padding: 4px 4px 16px 4px;
            border-bottom: 2px solid transparent;
            color: $gray-600;
            font-weight: 500;

            &:hover, &.active {
                border-bottom: 2px solid $blue-500;
                color: $blue-500;
            }

            &.disabled {
                border-bottom: 2px solid transparent;
                color: $gray-300;
            }
        }
    }
}
.tab-content {
    .tab-pane {

    }
}