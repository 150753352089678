.dropdown-toggle {
  &::after {
    border: unset;
    content: '';
    display: inline-block;
    width: 16px;
    height: 16px; 
    background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"%3E%3Cpath d="M6 9L12 15L18 9" stroke="%23181C32" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/%3E%3C/svg%3E');
    background-repeat: no-repeat;
    background-size: contain;
    margin-left: 8px;
    vertical-align: sub;
  }
}

.dropdown {
  &.no-arrow {
    .btn {
      padding: 6px 10px;

      &::after {
        display: none;
      }
    }
  }

  span {
    &.no-arrow {
      user-select: none;
      cursor: pointer;
      color: $gray-800;

      svg {
        width: 16px;
        height: 16px;
        position: relative;
        top: 1px;

        path {
          stroke: $gray-800;
        }
      }

      &::after {
        display: none;
      }

      &:hover {
        color: $gray-500;

        
        svg {
          path {
            stroke: $gray-500;
          }
        }
      }
    }
  }

  &.btn-group {
    > button {
      box-shadow: unset !important;
    }

    .dropdown-toggle-split {
      border-left-color: transparent;

      &:hover {
        border-left-color: initial;
      }

      &::before {
        content: '';
        position: absolute;
        left: 0px;
        top: 20%;
        width: 1px;
        height: 60%;
        background-color: var(--bs-gray-800);
      }

      &.btn-custom-primary {
        &::before {
          background-color: white;
        }
        &::after {
          filter: invert(100%) brightness(100%);
        }
      }
      &.btn-custom-danger {
        &::before {
          background-color: white;
        }
        &::after {
          filter: invert(100%) brightness(100%);
        }
      }
      &.btn-custom-success {
        &::before {
          background-color: white;
        }
        &::after {
          filter: invert(100%) brightness(100%);
        }
      }
      &.btn-custom-none-primary {
        &::before {
          background-color: transparent;
        }
      }
    }
  }

  .dropdown-toggle {
    &.btn-custom-primary {
      &::after {
        filter: invert(100%) brightness(100%);
      }
    }
    &.btn-custom-danger {
      &::after {
        filter: invert(100%) brightness(100%);
      }
    }
    &.btn-custom-success {
      &::after {
        filter: invert(100%) brightness(100%);
      }
    }
  }
}

.dropdown-menu {
  .dropdown-item {
    &:hover, &.active {
      background-color: $blue-focus1;
      text-decoration: none;
    }

    &-header {
      &:hover, &.active {
        background-color: transparent;
      }
    }
  }
}