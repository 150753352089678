.datatable-action-menu-component {
    max-width: 240px;

    padding: 8px 12px 12px !important;
    * {
        text-transform: capitalize !important;
    }
    
    &:focus {
        outline: none !important;
    }

    .custom-action-select-style {
        height: 40px;
    }
}