.custom-autosuggest-component {
    position: relative;
    margin: 1px;

    .custom-recent-icon {
        position: relative;
        display: inline-block;
        top: 7px;
        padding-right: 10px;
        
        i {
            color: rgba($color-secondary, 0.60)
        }
    }

    .custom-search {
        position: absolute;
        right: 16px;
        top: 12px !important;
        left: unset !important;
        z-index: 1;
    }

    .custom-dropdown {
        position: absolute;
        right: 12px !important;
        top: 13px !important;
        left: unset !important;
        font-size: 32px;
        z-index: 1;
        color: $color-active !important;
    }

    .react-autosuggest__input--focused {
        outline: none;
    }
    
    .react-autosuggest__input--open {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }
    
    .react-autosuggest__suggestions-container {
        display: none;
    }
    
    .react-autosuggest__suggestions-container--open {
        display: block;
        position: absolute;
        width: 100%;
        border: 1px solid #aaa;
        background-color: #fff;
        max-height: 250px;
        overflow-y: auto;
        z-index: 2;
    }
    
    .react-autosuggest__suggestions-list {
        margin: 0;
        padding: 0;
        list-style-type: none;
    }
    
    .react-autosuggest__suggestion {
        cursor: pointer;
        padding: 10px 20px;
    }
    
    .react-autosuggest__suggestion--highlighted {
        background-color: #ddd;
    }
    
    .Mui-error {
        border: 1px solid rgba($color-danger, 0.40) !important;
    }

    .custom-autosuggest-input-component {
        position: relative;
        z-index: 0;

        .custom-autosuggest-progress {
            position: absolute;
            right: 15px;
            top: 13px;
            z-index: 1;
        }
        
        .custom-autosuggest-close {
            position: absolute;
            right: 16px;
            top: 15px;
            left: unset !important;
            z-index: 1;
            cursor: pointer;

            &:hover {
                color: rgba($color-secondary, 1);
            }
        }

        .custom-autosuggest-search {
            position: absolute;
            right: 16px;
            top: 17px !important;
            left: unset !important;
            z-index: 1;
        }

        .material-icons {
            position: absolute;
            top: 15px;
            left: 10px;
            z-index: 1;
            color: rgba($color-secondary, 0.60);
        }

        input:-webkit-autofill,
        input:-webkit-autofill:hover, 
        input:-webkit-autofill:focus,
        textarea:-webkit-autofill,
        textarea:-webkit-autofill:hover,
        textarea:-webkit-autofill:focus,
        select:-webkit-autofill,
        select:-webkit-autofill:hover,
        select:-webkit-autofill:focus { 
            -webkit-box-shadow: 0 0 0px 1000px #F7F9FD inset;
        }

        .custom-label {
            label {
                font-weight: normal !important;
                color: rgba($color-secondary, 0.60) !important;

                &.Mui-error {
                    border: 0px !important;
                    color: $color-danger !important;
                }
            }
        }

        input {
            min-height: 40px !important;
            background-color: #F7F9FD !important;
            padding-left: 40px !important;
            font-size: $font-size-14;
            line-height: 40px;
    
            &::placeholder {
                font-weight: normal;
                font-style: italic;
                color: rgba($color-secondary, 0.60);
                opacity: 1;
            }
        }
    
        .MuiAutosuggest-endAdornment {
            background-color: #F7F9FD !important;
            right: 5px;
            position: absolute;
        }

        .MuiInput-underline {
            background-color: #F7F9FD !important;
        }

        .MuiInput-underline:before, .MuiInput-underline:after {
            content: none !important;
        }
    
        .MuiInputLabel-asterisk {
            display: none;
        }
    }

    
    
    &.no-icon {
        .custom-autosuggest-input-component {
            input {
                padding-left: 15px !important;
            }
        }
    }

    &.bg-white {
        input {
            background-color: $white !important;
        }
    }

    &.blue {
        .react-autosuggest__suggestions-container--open {
            
        }
        
        .react-autosuggest__suggestion--highlighted {
            
        }
        
        .custom-autosuggest-input-component {
            .custom-autosuggest-progress {
                color: $white;
            }
            
            .custom-autosuggest-close {
                color: $white;

                &:hover {
                    color: rgba($color-secondary, 1);
                }
            }
    
            .custom-autosuggest-search {
                color: $white;
            }
    
            input:-webkit-autofill,
            input:-webkit-autofill:hover, 
            input:-webkit-autofill:focus,
            textarea:-webkit-autofill,
            textarea:-webkit-autofill:hover,
            textarea:-webkit-autofill:focus,
            select:-webkit-autofill,
            select:-webkit-autofill:hover,
            select:-webkit-autofill:focus { 
                -webkit-box-shadow: 0 0 0px 1000px $color-active inset;
            }
    
            input {
                font-weight: bold;
                background-color: $color-active !important;
                color: $white;
        
                &::placeholder {
                    color: $white;
                }

                &.Mui-disabled {
                    background-color: #F7F9FD !important;
                    color: #999a9d !important;
                }
            }
        
            .MuiAutosuggest-endAdornment {
                background-color: $color-active !important;
            }
    
            .MuiInput-underline {
                background-color: $color-active !important;
            }
        }
    }

    &.job-form-outline-blue {
        .react-autosuggest__suggestions-container--open {
            
        }
        
        .react-autosuggest__suggestion--highlighted {
            
        }
        
        .custom-autosuggest-input-component {
            .custom-autosuggest-progress {
                color: $color-active;
            }
            
            .custom-autosuggest-close {
                color: $color-active;

                &:hover {
                    color: rgba($color-secondary, 1);
                }
            }
    
            .custom-autosuggest-search {
                color: $color-active;
            }
    
            input:-webkit-autofill,
            input:-webkit-autofill:hover, 
            input:-webkit-autofill:focus,
            textarea:-webkit-autofill,
            textarea:-webkit-autofill:hover,
            textarea:-webkit-autofill:focus,
            select:-webkit-autofill,
            select:-webkit-autofill:hover,
            select:-webkit-autofill:focus { 
                -webkit-box-shadow: 0 0 0px 1000px $white inset;
            }

            .MuiInput-underline {
                border: 2px solid $color-active;
                border-radius: 6px;
                overflow: hidden;
                background-color: $white !important;
                
                -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
                -moz-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
                box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
            }
    
            input {
                font-weight: bold;
                background-color: $white !important;
                color: $color-active;
        
                &::placeholder {
                    color: $color-active;
                }

                &.Mui-disabled {
                    background-color: #F7F9FD !important;
                    color: #999a9d !important;
                }
            }
        
            .MuiAutosuggest-endAdornment {
                background-color: $white !important;
            }
    
            .Mui-error {
                border: 2px solid rgba($color-danger, 0.40) !important;
            }

            .custom-autosuggest-close {
                top: 18px !important;
                right: 17px !important;
            }
            .custom-autosuggest-progress {
                top: 16px !important;
            }
        }
    }


    &.integration-default {
        .custom-autosuggest-input-component {
            .custom-autosuggest-search {
                color: $white;
            }
    
            input:-webkit-autofill,
            input:-webkit-autofill:hover, 
            input:-webkit-autofill:focus,
            textarea:-webkit-autofill,
            textarea:-webkit-autofill:hover,
            textarea:-webkit-autofill:focus,
            select:-webkit-autofill,
            select:-webkit-autofill:hover,
            select:-webkit-autofill:focus { 
                -webkit-box-shadow: 0 0 0px 1000px $color-active inset;
            }
    
            input {
                border: 1px solid rgba(0, 0, 0, 0.23);
                padding: 5px 15px;
                background-color: white !important;
                color: #484848 !important;
                border-radius: 5px;
                padding-top: 0px;
                padding-bottom: 0px;
        
                &::placeholder {
                    color: #484848 !important;
                }
            }
        
            .MuiAutosuggest-endAdornment {
                background-color: transparent !important;
            }
    
            .MuiInput-underline {
                background-color: transparent !important;
            }

            .custom-autosuggest-close {
                top: 10px;
            }
            .custom-autosuggest-progress {
                top: 10px;
            }
        }
    }


    &.job-form-autosuggest {
        .custom-autosuggest-input-component {
            input {
                border: 0px;
                padding: 5px 15px;
                background-color: #F4F8FF !important;
                color: #484848 !important;
                border-radius: 5px;
                padding-top: 0px;
                padding-bottom: 0px;
                height: 50px;
        
                &::placeholder {
                    color: rgba(#484848, 0.6) !important;
                }
                
                &[disabled] {
                    cursor: not-allowed !important;
                    pointer-events: all !important;
                }
            }
        
            .MuiAutosuggest-endAdornment {
                background-color: transparent !important;
            }
    
            .MuiInput-underline {
                background-color: transparent !important;
            }

            .custom-autosuggest-close {
                top: 15px;
                right: 18px;
            }
            .custom-autosuggest-progress {
                top: 13px;
            }
        }
    }

    &.reports-form-autosuggest {
        .custom-autosuggest-input-component {
            input {
                border: 0px;
                padding: 5px 15px;
                background-color: white !important;
                color: #484848 !important;
                border-radius: 0px;
                padding-top: 0px;
                padding-bottom: 0px;
                height: 50px;
        
                &::placeholder {
                    color: rgba(#484848, 0.6) !important;
                }
                
                &[disabled] {
                    cursor: not-allowed !important;
                    pointer-events: all !important;
                }
            }
        
            .MuiAutosuggest-endAdornment {
                background-color: transparent !important;
            }
    
            .MuiInput-underline {
                background-color: transparent !important;
            }

            .custom-autosuggest-close {
                top: 15px;
                right: 18px;
            }
            .custom-autosuggest-progress {
                top: 13px;
            }
        }
    }

    &.job-form-autosuggest-driver-vehicle {
        .custom-autosuggest-input-component {
            input {
                border: 0px;
                padding: 5px 15px;
                background-color: #FFFFFF !important;
                color: #484848 !important;
                border-radius: 5px;
                padding-top: 0px;
                padding-bottom: 0px;
                height: 50px;
        
                &::placeholder {
                    color: rgba(#484848, 0.6) !important;
                }
                
                &[disabled] {
                    cursor: not-allowed !important;
                    pointer-events: all !important;
                }
            }
        
            .MuiAutosuggest-endAdornment {
                background-color: transparent !important;
            }
    
            .MuiInput-underline {
                background-color: transparent !important;
            }

            .custom-autosuggest-close {
                top: 15px;
                right: 18px;
            }
            .custom-autosuggest-progress {
                top: 13px;
            }
        }
    }

    .add-btn {
        padding: 5px;

        button {
            background-color: $table-td-bg !important;
            padding-bottom: 0;
            padding-top: 10px;
            padding-bottom: 5px;

            font-size: $font-size-14;
            color: $color-secondary;
            font-weight: normal;

            i {
                position: relative;
                color: $color-active;
                top: 2px;
            }

            div {
                color: $color-active;
                font-weight: 600;
            }
        }
    }
    
    .delete-btn {
        padding: 5px;
        
        &:hover {
            span {
                i {
                    color: $color-danger;
                }
            }
        }
    }

    .view-more-btn {
        padding: 15px 20px 15px 20px;

        a {
            text-decoration: none;
            font-size: $font-size-14;
            color: $color-active;
            font-weight: normal;

            &:hover {
                color: $color-secondary; 
            }
        }
    }

    .custom-placeholder {
        font-style: italic;
        font-size: 12px;
        cursor: default;
    }
    .custom-placeholder-bg {
        background-color: transparent;
        cursor: default;

        &:hover {
            background-color: transparent !important;
        }
    }


    &.default-look {
        padding: 0;
        overflow: hidden;

        .react-autosuggest__input {
            background-color: transparent;

            input {
                height: unset !important;
                box-shadow: none !important;
                border: 0 !important;
                padding: $input-btn-padding-y $input-btn-padding-x;
                border-radius: $border-radius !important;
                background-color: $white !important;
                outline: unset !important;
                border-color: transparent !important;

                min-height: 41px;
                // padding: $input-btn-padding-y $input-btn-padding-x;
                padding: 1px;
                border-radius: $border-radius !important;
                background-color: $white !important;

                &::placeholder {
                    font-size: $input-btn-font-size !important;
                    font-weight: $font-weight-bold !important;
                    line-height: $line-height-base !important;
                    min-height: 20px;
                }
            }

            .custom-autosuggest-progress {
                top: 0px;
            }
        }

        &.is-invalid {
            padding: 0px !important;
        }
    }
}


.custom_suggestions-container {
    max-height: 264px;
    overflow: auto;
    background-color: #ffffff;
    box-shadow: 0 4px 16px 0 rgba(0,0,0,.15), 0 1px 2px 0 rgba(0,0,0,.07), 0 0 1px 0 rgba(0,0,0,.2);

    .react-autosuggest__suggestions-list {
        list-style-type: none;
        padding: 0px;
        margin: 0px;

        .react-autosuggest__suggestion {
            padding: 10px;
        }

        .react-autosuggest__suggestion--highlighted {
            cursor: pointer;
            background-color: rgba($color-active, 0.2);
        }
    }
}

body > div[role="tooltip"] {
    z-index: 999999;
}