.manage-input-component, 
.custom-check-input-component {
    margin: 1px;

    &.bg {
        background-color: $table-td-bg;
    }

    &.bg-color {
        input{
            background-color: $bg-color !important;
        }
    }

    label {
        // font-size: $font-size-14;
        font-weight: bold;
        color: $color-secondary;
        margin-bottom: 10px;
        width: auto;
        display: inline-block;
        font-size: 15px !important;
    }

    &.label-flex {
        label {
            align-items: center;
            display: flex;

            label {
                margin: 0px;
            }
        }
    }

    &.text-danger {
        label {
            color: $color-danger;
        }
    }

    &.xs {
        label {
            font-size: 12px !important;
        }
    }
    &.sm {
        label {
            // font-size: $font-size-14 !important;
        }
    }
    &.md {
        label {
            font-size: 16px !important;
        }
    }
    &.lg {
        label {
            // font-size: $font-size-14 !important;
        }
    }
    &.xl {
        label {
            font-size: 25px !important;

            @include respond-below('lg') {
                // font-size: $font-size-21 !important;
            }
        }
    }


    &.light {
        label {
            font-weight: 300 !important;
        }
    }
    &.normal {
        label {
            font-weight: normal !important;
        }
    }
    &.medium {
        label {
            font-weight: 600 !important;
        }
    }
    &.bold {
        label {
            font-weight: bold !important;
        }
    }
    &.bold-input {
        input {
            font-weight: bold !important;
        }
    }

    &.normal {
        label {
            font-style: normal !important;
        }
    }
    &.italic {
        label {
            font-style: italic !important;
        }
    }


    &.left {
        text-align: left !important;
        input {
            text-align: left !important;
        }
    }
    &.center {
        text-align: center !important;
        input {
            text-align: center !important;
        }
    }
    &.right {
        text-align: right !important;
        input {
            text-align: right !important;
        }
    }

    &.lineHeight {
        textarea {
            line-height: 20px !important;
        }

        &.min-h-82 {
            textarea {
                min-height: 82px !important;
            }
        }
    }
    &.p-10 {
        textarea {
            padding-top: 10px;
            padding-bottom: 10px;
        }
    }

    &.resize {
        textarea {
            resize: vertical !important;
        }
    }

    
    &.min-w-50 {
        input {
            min-width: 55px !important;
            width: 55px !important;
        }
    }

    
    &.min-h-50 {
        input, .MuiSelect-select, textarea {
            // height: auto !important;
            min-height: 53px !important;
            line-height: 18px !important;
            padding-top: 0px !important;
            padding-bottom: 0px !important;
        }

        .custom-input {
            padding-top: 0px !important;
            padding-bottom: 0px !important;
        }
    }


    input:-webkit-autofill,
    input:-webkit-autofill:hover, 
    input:-webkit-autofill:focus,
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover,
    textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus { 
        -webkit-box-shadow: 0 0 0px 1000px #F7F9FD inset;
    }

    .MuiInputBase-adornedEnd {
        background-color: #F7F9FD !important;

        i {
            right: 7px;
            position: relative;
            width: 30px;
            cursor: default;
        }
    }

    &.notes {
        .MuiInputBase-adornedEnd {
            background-color: #F7F9FD !important;
    
            i {
                right: 0px;
                position: relative;
                width: 30px;
                cursor: default;
            }
        }
    }

    .MuiInputBase-adornedStart {
        background-color: #F7F9FD !important;

        i {
            left: 7px;
            position: relative;
            width: 30px;
            cursor: pointer;
        }

        input, .MuiSelect-select, textarea {
            padding-left: 22px !important;
        }
    }

    .Mui-error {
        border: 1px solid rgba(207, 62, 62, 0.4) !important;
        
        input, .MuiSelect-select, textarea {
            border: 0px !important;
        }
    }

    label {
        &.Mui-error {
            border: 0px !important;
        }
    }

    input, .MuiSelect-select, textarea {
        min-height: 40px !important;
        color: $color-secondary;
        background-color: #F7F9FD !important;
        padding-left: 10px;
        // font-size: $font-size-14;
        line-height: 40px;

        &::placeholder {
            font-weight: normal;
            font-style: italic;
            color: rgba($color-secondary, 0.60);
            opacity: 1;
        }

        .placeholder {
            // font-size: $font-size-14;
            font-weight: normal;
            font-style: italic;
            color: rgba($color-secondary, 0.60);
        }

        &[aria-invalid="true"] {
            border: 1px solid rgba($color-danger, 0.40);
        }

        &.Mui-disabled {
            background-color: #F7F9FD !important;
            color: #999a9d !important;
            cursor: not-allowed !important;
        }
    }

    .MuiInput-underline:before, .MuiInput-underline:after {
        content: none !important;
    }

    .MuiInputLabel-asterisk {
        display: none;
    }

    &.label-margin-0 {
        label {
            margin: 0px !important;
        }
    }
    
    &.muted-label {
        label {
            font-weight: bold !important;
            color: rgba($color-secondary, 0.60) !important;
        }
        
        .custom-label {
            label {
                font-weight: bold !important;
                color: rgba($color-secondary, 0.60) !important;
            }
        }
    }

    &.default {
        .custom-label {
            label {
                font-weight: normal !important;
                color: rgba($color-secondary, 0.60) !important;

                &.Mui-error {
                    border: 0px !important;
                    color: $color-danger !important;
                }
            }
        }

        .custom-input {
            border: 1px solid rgba($color-secondary, 0.10);
            -webkit-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.10);
            -moz-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.10);
            box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.10);

            .MuiInputBase-adornedEnd {
                background-color: $white !important;
        
                i {
                    right: 7px;
                    position: relative;
                    width: 30px;
                    cursor: default;
                }
            }
    
            input, .MuiSelect-select, textarea {
                min-height: 40px !important;
                color: $color-secondary;
                background-color: $white !important;
                padding-left: 15px;
                // font-size: $font-size-14;
                line-height: 40px;
        
                &::placeholder {
                    font-weight: normal;
                    font-style: italic;
                    color: rgba($color-secondary, 0.60);
                    opacity: 1;
                }
        
                .placeholder {
                    // font-size: $font-size-14;
                    font-weight: normal;
                    font-style: italic;
                    color: rgba($color-secondary, 0.60);
                }
            }
            
            .Mui-error {
                &[aria-invalid="true"] {
                    border: 1px solid rgba($color-danger, 0.40) !important;
                }
            }
        }
    }

    &.job-form-contact {
        border-radius: 0px;
        border: 0px;
        overflow: hidden;
        
        .custom-input {
            border-bottom: 1px solid #E5E5E5;
            
            .MuiInputBase-adornedEnd {
                background-color: $white !important;
        
                i {
                    right: 7px;
                    position: relative;
                    width: 30px;
                    cursor: default;
                }
            }
    
            input, .MuiSelect-select, textarea {
                min-height: 35px !important;
                color: $color-secondary;
                background-color: $white !important;
                padding: 0px;
                // font-size: $font-size-14;
                line-height: 35px;
        
                &::placeholder {
                    font-weight: normal;
                    font-style: italic;
                    color: rgba($color-secondary, 0.60);
                    opacity: 1;
                }
        
                .placeholder {
                    // font-size: $font-size-14;
                    font-weight: normal;
                    font-style: italic;
                    color: rgba($color-secondary, 0.60);
                }
            }
            
            .Mui-error {
                &[aria-invalid="true"] {
                    border: 1px solid rgba($color-danger, 0.40) !important;
                }
            }
        }

        &.custom-site-instruction {
            input, .MuiSelect-select, textarea {
                line-height: 15px !important;
            }
        }
    }

    &.job-form-step-google-input {
        border-radius: 0px;
        border: 0px;
        overflow: hidden;

        &.mw-300 {
            input {
                min-width: 300px;
            }
        }
        
        .Mui-disabled {
            cursor: not-allowed !important;

            button {
                cursor: not-allowed !important;
            }
        }

        .custom-input {
            background-color: transparent !important;
            border-bottom: 1px solid #E5E5E5;
            
            .MuiInputBase-adornedEnd {
                background-color: transparent !important;
        
                i {
                    right: 7px;
                    position: relative;
                    width: 30px;
                    cursor: default;
                }
            }
    
            input, .MuiSelect-select, textarea {
                min-height: 35px !important;
                color: $color-secondary;
                background-color: $white !important;
                padding: 0px;
                // font-size: $font-size-14;
                line-height: 35px;
        
                &::placeholder {
                    font-weight: normal;
                    font-style: normal;
                    color: $color-active;
                    opacity: 1;
                }
        
                .placeholder {
                    // font-size: $font-size-14;
                    font-weight: normal;
                    font-style: normal;
                    color: $color-active;
                }
            }
            
            .Mui-error {
                &[aria-invalid="true"] {
                    border: 1px solid rgba($color-danger, 0.40) !important;
                }
            }
        }
    }

    &.job-form-tag {
        border-radius: 0px;
        border: 0px;
        overflow: hidden;
        
        .custom-input {
            background-color: transparent !important;
            border-bottom: 0px !important;
            
            input, .MuiSelect-select, textarea {
                min-height: 35px !important;
                color: $color-secondary;
                background-color: $white !important;
                padding: 0px !important;
                // font-size: $font-size-16;
                font-weight: bold;
                line-height: 35px;
        
                &::placeholder {
                    font-weight: normal;
                    font-style: normal;
                    color: $color-secondary;
                    opacity: 1;
                }
        
                .placeholder {
                    // font-size: $font-size-14;
                    font-weight: normal;
                    font-style: normal;
                    color: $color-secondary;
                }
            }
            
            .Mui-error {
                &[aria-invalid="true"] {
                    border: 1px solid rgba($color-danger, 0.40) !important;
                }
            }
        }
    }

    &.job-form-collect-payment {
        border-radius: 6px;
        border: 1px solid $color-active;
        overflow: hidden;
        max-width: 200px;
        
        .custom-input {
            
            .MuiInputBase-adornedEnd {
                background-color: $white !important;
        
                i {
                    right: 7px;
                    position: relative;
                    width: 30px;
                    cursor: default;
                }
            }
    
            input, .MuiSelect-select, textarea {
                min-height: 40px !important;
                color: $color-secondary;
                background-color: $white !important;
                padding-left: 15px;
                // font-size: $font-size-14;
                line-height: 40px;
        
                &::placeholder {
                    font-weight: normal;
                    font-style: italic;
                    color: rgba($color-secondary, 0.60);
                    opacity: 1;
                }
        
                .placeholder {
                    // font-size: $font-size-14;
                    font-weight: normal;
                    font-style: italic;
                    color: rgba($color-secondary, 0.60);
                }
            }
            
            .Mui-error {
                &[aria-invalid="true"] {
                    border: 1px solid rgba($color-danger, 0.40) !important;
                }
            }
        }
    }


    &.job-form-input {
        
        .custom-input {
            
            .MuiInputBase-adornedEnd {
                background-color: #F4F8FF !important;
        
                i {
                    right: 7px;
                    position: relative;
                    width: 30px;
                    cursor: default;
                }
            }
    
            input, .MuiSelect-select, textarea {
                min-height: 50px !important;
                color: $color-secondary;
                background-color: #F4F8FF !important;
                border-radius: 5px;
                padding-left: 15px;
                padding-top: 0px;
                padding-bottom: 0px;
                // font-size: $font-size-14;
                line-height: 40px;
        
                &::placeholder {
                    font-weight: normal;
                    font-style: italic;
                    color: rgba($color-secondary, 0.60);
                    opacity: 1;
                }
        
                .placeholder {
                    // font-size: $font-size-14;
                    font-weight: normal;
                    font-style: italic;
                    color: rgba($color-secondary, 0.60);
                }
            }
            
            .Mui-error {
                &[aria-invalid="true"] {
                    border: 1px solid rgba($color-danger, 0.40) !important;
                }
            }
        }
    }

    &.billing-group-input {
        &.bold {
            input, .MuiSelect-select, textarea {
                font-weight: bold !important;
            }
        }

        .custom-label {
            label {
                font-weight: normal !important;
                color: rgba($color-secondary, 0.60) !important;

                &.Mui-error {
                    border: 0px !important;
                    color: $color-danger !important;
                }
            }
        }

        .custom-input {
            border: 0px;
            border-bottom: 1px solid rgba($color-secondary, 0.20);

            .MuiInputBase-adornedEnd {
                background-color: transparent !important;
        
                i {
                    right: 7px;
                    position: relative;
                    width: 30px;
                    cursor: default;
                }
            }
    
            input, .MuiSelect-select, textarea {
                min-height: 40px !important;
                color: $color-secondary;
                background-color: transparent !important;
                padding-top: 0px;
                padding-left: 0px;
                padding-bottom: 0px;
                // font-size: $font-size-14;
                line-height: 40px;
        
                &::placeholder {
                    font-weight: normal;
                    font-style: italic;
                    color: rgba($color-secondary, 0.60);
                    opacity: 1;
                }
        
                .placeholder {
                    // font-size: $font-size-14;
                    font-weight: normal;
                    font-style: italic;
                    color: rgba($color-secondary, 0.60);
                }
            }
            
            .Mui-error {
                &[aria-invalid="true"] {
                    border: 1px solid rgba($color-danger, 0.40) !important;
                }
            }
        }
    }

    &.default-no-shadow-bold {
        .custom-label {
            label {
                font-weight: normal !important;
                color: rgba($color-secondary, 0.60) !important;
            }
        }

        &.muted-label {
            label {
                font-weight: bold !important;
                color: rgba($color-secondary, 0.60) !important;
            }
            
            .custom-label {
                label {
                    font-weight: bold !important;
                    color: rgba($color-secondary, 0.60) !important;
                }
            }
        }

        .custom-input {
            border: 1px solid rgba($color-secondary, 0.10) !important;

            .MuiInputBase-adornedEnd {
                background-color: $white !important;
        
                i {
                    right: 7px;
                    position: relative;
                    width: 30px;
                    cursor: default;
                }
            }
    
            input, .MuiSelect-select, textarea {
                min-height: 40px !important;
                color: $color-secondary;
                background-color: $white !important;
                padding-left: 15px;
                // font-size: $font-size-14;
                line-height: 40px;
                font-weight: bold;
        
                &::placeholder {
                    font-weight: bold;
                    font-style: normal;
                    color: $color-secondary;
                    opacity: 1;
                }
        
                .placeholder {
                    // font-size: $font-size-14;
                    font-weight: bold;
                    font-style: normal;
                    color: $color-secondary;
                }
            }
        }
    }

    
    &.custom-google-search {
        input, .MuiSelect-select, textarea {
            min-width: 350px;
            background-color: $white !important;
            border-radius: 5px !important;
            color: #484848;

            &::placeholder {
                font-weight: normal;
                font-style: normal;
                color: #7F7F7F;
                opacity: 1;
            }

            @include respond-below('xl') {
                min-width: 200px;
                max-width: 250px;
            }
        }

        .MuiInputBase-adornedEnd {
            background-color: $white !important;
            border-radius: 5px !important;
        }
    
        .MuiInputBase-adornedStart {
            background-color: $white !important;
            border-radius: 5px !important;
        }
    }

    &.bg-white-job-type-select {
        input, .MuiSelect-select, textarea {
            background-color: $white !important;
            font-weight: bold;
            padding: 0px;
            padding-right: 25px;
        }

        .MuiSelect-icon {
            top: 10px;
        }

        .MuiInputBase-adornedEnd {
            background-color: $white !important;
        }
    
        .MuiInputBase-adornedStart {
            background-color: $white !important;
        }
    }

    &.bg-white {
        input, .MuiSelect-select, textarea {
            background-color: $white !important;
        }

        .MuiInputBase-adornedEnd {
            background-color: $white !important;
        }
    
        .MuiInputBase-adornedStart {
            background-color: $white !important;
        }
    }

    &.bg-white-underline {
        &.max-w-300 {
            input, .MuiSelect-select, textarea {
                max-width: 300px;
            }
        }
        input, .MuiSelect-select, textarea {
            background-color: $white !important;
            border-bottom: 1px solid rgba(#484848, 0.10) !important;
            height: 40px;
        }

        .MuiInputBase-adornedEnd {
            background-color: $white !important;
        }
    
        .MuiInputBase-adornedStart {
            background-color: $white !important;
        }

        &.recurring {
            input {
                padding-left: 0px;
            }
        }
    }
    
    &.mh-20 {
        input, .MuiSelect-select, textarea {
            min-height: 20px !important;
        }
    }

    .end-icon {
        position: absolute;
        height: 100%;
        right: 0;
        top: 17px;
    }

    
    &.no-padding {
        .custom-input {
            padding: 0px !important;
        }

        textarea {
            padding: 0px !important;
        }
    }

    &.no-margin {
        label {
            margin: 0px !important;
        }
    }

    &.lp-10 {
        textarea {
            padding-left: 10px !important;
        }
    }

    &.plr-10 {
        textarea {
            padding-left: 10px !important;
            padding-right: 10px !important;
        }
    }

    &.lh-20 {
        textarea {
            line-height: 20px !important;
        }
    }

    &.lh-25 {
        textarea {
            line-height: 25px !important;
        }
    }

    &.mh-300 {
        textarea {
            min-height: 250px !important;
        }
    }

    &.mh-200 {
        textarea {
            min-height: 200px !important;
        }
    }
    &.pt-10 {
        textarea {
            padding-top: 10px !important;
        }
    }

    &.mobile-pt-6 {
        @include respond-below('xl') {
            padding-top: 6px;
        }
    }
    &.mobile-pt-2 {
        @include respond-below('xl') {
            padding-top: 2px;
        }
    }
}

.custom-manage-select-checkbox-menu {
    z-index: 9999 !important;
}

.custom-label-18 {
    font-size: 18px;
    font-weight: bold;
    color: $color-secondary;
    padding-right: 15px;
}

.bg-white-underline-label {
    font-size: 12px;
    font-weight: normal;
    color: #7F7F7F;
    padding-right: 15px;

    img {
        top: 2px;
        position: relative;
    }
}

.max-w-120 {
    input, .MuiSelect-select, textarea {
        max-width: 120px;
    }
}

.max-w-100 {
    input, .MuiSelect-select, textarea {
        max-width: 100px;
    }
}

.max-w-80 {
    input, .MuiSelect-select, textarea {
        max-width: 80px;
    }
}

.max-w-60 {
    input, .MuiSelect-select, textarea {
        max-width: 60px;
    }
}

.max-w-50 {
    input, .MuiSelect-select, textarea {
        max-width: 50px;
    }
}


/* CUSTOM LABEL */
.custom-label-text {
    // font-size: $font-size-14 !important;
    color: #484848 !important;
    font-weight: normal !important;
    margin-bottom: 0px !important;
    font-style: italic;

    span {
        color: #EF476F;
    }
}
/* END CUSTOM LABEL */