/* FONT */
$font-size-18: 18px;
$font-size-14: 14px;
$font-size-10: 10px;
$font-size-12: 12px;
$font-size-16: 16px;
$font-size-12: 12px;
$font-size-21: 21px;
/* END FONT */



/* COLORS */
$color-primary: #185cff;
$color-secondary: #484848;
$color-success: #28a745;
$color-danger: #CF3E3E;
$color-warning: #FFA200;
$color-info: #4375CD;
$color-default: #F7F9FD;

$color-active: #185cff;
$main-color: #185cff;
$bg-color: #F0F3FA;
$color-hover: #DDEBF9;

$table-td-bg: #F7F9FD;
$dropzone-bg: #F3FFFB;

$muted: rgba(#484848, 20);
$gray: rgba(#484848, 60);
$black: #000000;
$white: #ffffff;
$transparent: transparent;

$skeleton-gray: #CCCCCC;

$facebook: #3b5998;
$whatsapp: #25D366;
$linkedin: #4875B4;
$twitter: #33CCFF;
$viber: #7360f2;
$telegram: #0088cc;

$word: #0180EF;
$excel: #00784B;
$pdf: #CD4141;

$draft: #7B7B7B;
$unassigned: #7B7B7B;
$assigned: #7B7B7B;
$dispatched: #7B7B7B;
$acknowledged: #7B7B7B;
$started: #185CFF;
$inprogress: #D318FF;
$completed: #00CB4A;
$unsuccessful: #EF476F;

$color-F7F7F7: #F7F7F7;
/* END COLORS */


$custom-variable-prefix: "custom";
$custom-theme-colors: (
    "color-primary": $color-primary,
    "color-secondary": $color-secondary,
    "color-success": $color-success,
    "color-danger": $color-danger,
    "color-warning": $color-warning,
    "color-info": $color-info,
    "color-default": $color-default,

    "color-active": $color-active,
    "main-color": $main-color,
    "bg-color": $bg-color,
    "color-hover": $color-hover,

    "table-td-bg": $table-td-bg,
    "dropzone-bg": $dropzone-bg,

    "muted": $muted,
    "gray": $gray,
    "black": $black,
    "white": $white,
    "transparent": $transparent,

    "skeleton-gray": $skeleton-gray,

    "facebook": $facebook,
    "whatsapp": $whatsapp,
    "linkedin": $linkedin,
    "twitter": $twitter,
    "viber": $viber,
    "telegram": $telegram,

    "word": $word,
    "excel": $excel,
    "pdf": $pdf,

    "unassigned": $unassigned,
    "assigned": $assigned,
    "dispatched": $dispatched,
    "acknowledged": $acknowledged,
    "started": $started,
    "inprogress": $inprogress,
    "completed": $completed,
    "unsuccessful": $unsuccessful,

    "color-F7F7F7": $color-F7F7F7,
) !default;


/* RESPONSIVE -> It is also necessary to change at AuthDataProvider.jsx and enums.jsx */
// $breakpoints: ( 
//     xs: 320px, 
//     sm: 320px, 
//     md: 320px, 
//     lg: 768px, 
//     xl: 1024px 
// );
$breakpoints: ( 
    xs: 320px, 
    sm: 768px, 
    md: 992px, 
    lg: 1024px, 
    xl: 1280px, 
    xxl: 1440px, 
    xxxl: 1920px 
);

//Respond above
@mixin respond-above($breakpoint) {
    @if map-has-key($breakpoints, $breakpoint) {
        $breakpoint-value: map-get($breakpoints, $breakpoint);

        @media (min-width: $breakpoint-value) {
            @content;
        }
    }
}

//Respond below
@mixin respond-below($breakpoint) {
    @if map-has-key($breakpoints, $breakpoint) {
        $breakpoint-value: map-get($breakpoints, $breakpoint);

        @media (max-width: ($breakpoint-value - 1)) {
            @content;
        }
    }
}

//Respond between
@mixin respond-between($lower, $upper) {
    @if map-has-key($breakpoints, $lower) and map-has-key($breakpoints, $upper) {
        $lower-breakpoint: map-get($breakpoints, $lower);
        $upper-breakpoint: map-get($breakpoints, $upper);

        @media (min-width: $lower-breakpoint) and (max-width: ($upper-breakpoint - 1)) {
            @content;
        }
    }
}
/* END RESPONSIVE */


:root {
    @each $name, $value in $custom-theme-colors {
        --#{$custom-variable-prefix}-#{$name}:#{$value};
    }
}