.modal-dialog {
    .modal-content {
        border: 1px solid $gray-300;
        border-radius: $border-radius;
        box-shadow: $shadow2; 

        .modal-header {
            padding: 24px 24px 0px 24px;
            border-bottom: 0px;

            .btn-close {
                color: $gray-900;
                background-size: 10px;
                opacity: 1;

                &:hover {
                    opacity: 0.5;
                }

                &:focus, &:focus-visible {
                    box-shadow: unset !important;
                }

                &.btn-close-white {
                    filter: unset !important;
                    color: $gray-100 !important;
                    opacity: 0.3;
                }
            }
        }
        
        .modal-body {
            padding: 12px 24px 16px 24px;
            color: $gray-700;
        }

        .modal-footer {
            padding: 12px 24px 12px 24px;
            border-top: 0px;
            background-color: $blue-focus1;
        }
    }
}