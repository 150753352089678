.custom-rsuite-checktree-picker {
    .rs-picker-toggle-wrapper {
        width: 100%;
        display: grid;

        .rs-btn-default {
            height: 50px;
            // display: flex !important;
            align-items: center !important;
            border-radius: 0px;
            outline: none;
            border: 0px;
            background-color: $table-td-bg !important;

            .rs-picker-toggle-value {
                color: $color-secondary !important;
                width: 91%;
            }
            .rs-picker-toggle-caret {
                top: 10px !important;
            }
            .rs-picker-toggle-clean {
                top: 10px !important;
            }
        }
    }

    .rs-checkbox-wrapper::before {
        border: 1px solid $color-active !important;
    }
    
    &.bg-color {
        .rs-picker-toggle-wrapper {
            .rs-btn-default {
                background: $bg-color !important;
            }
        }
    }

    &.bg-transparent {
        .rs-picker-toggle-wrapper {
            .rs-btn-default {
                background: transparent !important;
            }
        }
    }

    &.error {
        .rs-picker-toggle-wrapper {
            .rs-btn-default {
                border: 1px solid rgba(207, 62, 62, 0.4) !important;
            }
        }
    }


    &.default-look {
        padding: 0;

        > div {
            height: 100% !important;
            max-height: 100% !important;

            .custom-expand-icon-wrapper {
                display: flex;

                i {
                    left: -5px;
                    position: relative;
                }
            }
        }

        .rs-picker-toggle-textbox {
            height: unset !important;
            box-shadow: none !important;
            border: 0 !important;
            padding: $input-btn-padding-y $input-btn-padding-x;
            border-radius: $border-radius !important;
            background-color: $white !important;
            outline: unset !important;
            border-color: transparent !important;
        }

        .rs-picker-toggle-wrapper {
            .rs-btn-default {
                height: unset !important;
                box-shadow: none !important;
                min-height: 41px;
                padding: $input-btn-padding-y $input-btn-padding-x;
                border-radius: $border-radius !important;
                background-color: $white !important;

                // &[aria-expanded="true"] {
                //     border-color: $input-focus-border-color !important;
                // }
                
                .rs-picker-toggle-placeholder {
                    font-size: $input-btn-font-size !important;
                    font-weight: $font-weight-bold !important;
                    line-height: $line-height-base !important;
                    min-height: 20px;
                }


                .rs-picker-toggle-value {
                    font-size: $input-btn-font-size !important;
                    font-weight: $font-weight-bold !important;
                    line-height: $line-height-base !important;
                    color: $secondary !important;
                    flex: 0 0 90%;
                    display: flex;

                    .rs-picker-value-list {
                        flex: 1;
                    }

                    .rs-picker-value-count {
                        flex: 0;
                        margin-right: 35px;
                    }
                }
            }
        }

        &.is-invalid {
            padding: 0px !important;
        }
    }

    &.read-only {
        // border-color: transparent !important;
        cursor: default !important;
        * {
            color: #A1A5B7 !important;
        }
        .rs-btn-default {
            cursor: default !important;
            // padding: $input-btn-padding-y 0 !important;
        }

        .rs-picker-toggle-caret {
            display: none !important;
        }
    }


    &.custom-roles-tree {
        overflow: hidden;
        
        .custom-expand-icon-wrapper {
            display: none !important;
        }

        .rs-check-tree-node[aria-level="1"] {
            padding-left: 0px !important;
        }

        .rs-check-tree-node[aria-level="2"] {
            padding-left: 40% !important;
            
            @include media-breakpoint-down(md) {
                padding-left: 15px !important;
            }
        }
    }

}

.rs-picker-select-menu, .rs-picker-menu {
    z-index: 1300 !important;
    border-radius: 0px !important;

    button {
        padding: 3px !important;
    }

    .add-btn {
        button {
            background-color: $table-td-bg !important;
            padding-bottom: 0;
            padding-top: 10px;
            padding-bottom: 5px;

            font-size: $font-size-14;
            color: $color-secondary;
            font-weight: normal;

            i {
                position: relative;
                color: $color-active;
                top: 3px;
            }

            div {
                color: $color-active;
                font-weight: 600;
            }

            &:hover {
                background-color: rgba($color-active, 0.1) !important;
            }

            &.Mui-disabled {
                cursor: not-allowed !important;
                background-color: rgba($color-secondary, 0.1) !important;

                i {
                    color: $color-secondary;
                }
    
                div {
                    color: $color-secondary;
                }
            }
        }
    }
    
    .delete-btn {
        &:hover {
            span {
                i {
                    color: $color-danger;
                }
            }
        }
    }


    &.underline-select-menu {
        > div[role="list"] {
            max-height: 200px !important;

            div[role="listitem"] {
                border-bottom: 1px solid rgba($color-secondary, 0.1);

                &:last-child {
                    border-bottom: 0px;
                }
            }
        }
    }

    &.custom-menu-checktree {
        height: unset !important;
        .rs-check-tree {
            height: unset !important;
            .ReactVirtualized__List {
                height: unset !important;
            }
        }
    }

    .custom-select-all-cb {
        .rs-checkbox-checker {
            label {
                width: 100%;
                padding-right: 12px;
            }
        }
    }
}

.rs-picker-check-menu-items {
    > div {
        > div {
            height: 100% !important;
        }
    }
}

// .rs-picker-menu {
//     width: 200px;
// }