.grantt-chart-component {
  .ganttchart-icon-btn {
    .MuiIconButton-label {
      i {
        color: #484848;
      }
    }
  }

  .gantt-schedule-timeline-calendar__chart {
    padding-bottom: 200px;
  }
  .gantt-schedule-timeline-calendar {
    background-color: transparent;
  }
  .gantt-schedule-timeline-calendar__chart-timeline {
    background-color: white;
  }
  .gantt-schedule-timeline-calendar__list-column-rows {
    border-right: 10px solid #F0F3FA;
  }
  .gantt-schedule-timeline-calendar__list-column-rows--scroll-compensation {
    background-color: #F0F3FA;
  }
  .gantt-schedule-timeline-calendar__list-column-row {
    border: 0px;
  }
  .gantt-schedule-timeline-calendar__list-column-row-content {
    padding-left: 0px;
    border: 0px;
  }
  .gantt-schedule-timeline-caledar__plugin-selection-rect {
    border: 1px solid #DEE9FF;
  }
  .gantt-schedule-timeline-calendar__chart-calendar{
    border: 1px solid #DEE9FF;
    
    * {
      border-color: #DEE9FF !important;
    }
  }
  .gantt-schedule-timeline-calendar__chart-timeline-grid {
    background-color:  #F0F3FA;
    margin-top: 1px;
  }
  .gantt-schedule-timeline-calendar__chart-timeline-grid-row {
    background-color:  white;
  }
  .gantt-schedule-timeline-calendar__list-column-header-resizer-container {
    padding-left: 0px;
  }
  .gantt-schedule-timeline-calendar__chart-calendar-dates {
    height: 100%;
  }
  .gantt-schedule-timeline-calendar__chart-calendar-dates--level-1 {
    display: none;
  }
  .gantt-schedule-timeline-calendar__chart-calendar-date--level-0 {
    justify-content: center;
  }

  .gantt-schedule-timeline-calendar__chart-timeline-items-row-item-label{
    margin-left: 0px
  }

  .gantt-schedule-timeline-calendar__list, 
  .gantt-schedule-timeline-calendar__list-column,
  .gantt-schedule-timeline-calendar__list-column-rows,
  .gantt-schedule-timeline-calendar__list-column-rows--scroll-compensation,
  .gantt-schedule-timeline-calendar__vertical-scroll,
  .gantt-schedule-timeline-calendar__vertical-scroll > div,
  .gantt-schedule-timeline-calendar__chart-timeline,
  .gantt-schedule-timeline-calendar__chart-timeline-inner {
    position: relative;
    // height: 100% !important;
  }

  &.period-hours {
    .gantt-schedule-timeline-calendar__chart-timeline-grid-row-block {
      position: relative;
      
      &.current {
        background-color: rgba(241,196,15,0.06);
      }

      // &:after {
      //   content: '';
      //   position: absolute;
      //   width: 1px;
      //   height: 100%;
      //   background-color: #DEE9FF;
      // }
    }
  }

  .gantt-schedule-timeline-calendar__chart-calendar-date {
    // border-right: 0px solid transparent;
  }
  .gantt-schedule-timeline-calendar__chart-timeline-grid-row-block {
    // border-right: 0px solid transparent;
    border-color: #DEE9FF;
  }
  .gantt-schedule-timeline-calendar__list-column-header {
    background-color: #F0F3FA;
  }
  .gantt-schedule-timeline-calendar__chart-calendar {
    background-color: white;
    border-bottom: 10px solid #F0F3FA;
    cursor: default !important;
  }

  .gantt-schedule-timeline-calendar__horizontal-scroll {
    position: relative;
    // z-index: 1;
    position: fixed;
    bottom: 52px;
  }

  .gantt-schedule-timeline-calendar__vertical-scroll {
    height: 0px !important;
  }

  .gantt-schedule-timeline-calendar__chart-timeline {
    overflow: unset;

    .gantt-schedule-timeline-calendar__chart-timeline-inner {
      overflow: unset;

      .gantt-schedule-timeline-calendar__chart-timeline-grid {
        overflow: unset;
      }
      
      .gantt-schedule-timeline-calendar__chart-timeline-items {
        overflow: unset;
        .gantt-schedule-timeline-calendar__chart-timeline-items-row {
          overflow: unset;
        }
      }
    }
  }

  .selected-row {
    z-index: 1;
    position: relative;
  }

  /* RESIZING */
  .resizing {
    background: rgba($color-active, 0.4) !important;
    * {
      color: white;
    }
  }
  /* RESIZING */


  /* END ITEM */
  .gantt-schedule-timeline-calendar__chart-timeline-items-row {
    .gantt-schedule-timeline-calendar__chart-timeline-items-row-item {
      background-color: #F6F6F6;
      line-height: unset;
      border-radius: 5px;
      display: flex;
      align-items: center;
      overflow: unset;
      margin-top: 15px;
      height: auto !important;
      color: $color-secondary;
  
      &--selected {
        z-index: 1;
      }
  
      &.actual-wrapper-item {
        background-color: transparent;
        line-height: unset;
        border-radius: 5px;
        display: flex;
        align-items: center;
        overflow: unset;
        margin-top: 10px;
        padding: 0;
        height: 60px !important;
        z-index: 2;

        // .gantt-schedule-timeline-calendar__chart-timeline-items-row-item-resizer {
        //   display: none;
        // }
      }

      .custom-context-menu {
        position: fixed;
      }
  
      .custom-resizer {
        background-color: rgba(white, 0.7);
        position: absolute;
        width: 10px;
        height: 80%;
        right: 3px;
        top: 5px;
        border-radius: 5px;
        border: 1px solid rgba(#C2C2C2, 0.5);
  
        &:hover {
          background-color: white;
        }
      }
  
      .custom-item {
        overflow: unset;
        height: auto !important;
  
        .custom-time {
          padding-right: 15px;
        }
  
        .custom-circle {
          position: relative;
          display: inline-block;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background-color: #484848;
          margin-left: 10px;
          margin-right: 10px;
        }
  
        .custom-watch {
          position: relative;
          top: 5px;
          font-size: 21px;
          color: #EF476F;
          padding-right: 10px;
        }

        &.actual-item {
          border-left: 0px !important;
          border-width: 2px;
          border-style: dashed;
          border-top-right-radius: 6px;
          border-bottom-right-radius: 6px;
          padding: 4px;
          
          height: 60px !important;
        }
      }
  
      .custom-item-info-center {
        display: none;
        position: absolute;
        bottom: 70px;
        min-width: 300px;
        left: 0px;
  
        text-align: left;
        margin: 0 auto;
        max-width: 250px;
        white-space: break-spaces;
        z-index: unset;

        .custom-item-info-tooltip {
          font-size: 12px;

          .custom-tooltip-title {
            text-align: center;
            font-weight: bold;
            font-size: 12px;
            padding-bottom: 5px;
          }
          .custom-tooltip-address {
            font-size: 12px;
          }
          .custom-tooltip-info {
            padding-top: 5px;
            font-size: 12px;
          }
          .custom-tooltip-time {
            padding-top: 5px;

            .custom-tooltip-time-left {
              font-size: 12px;
            }

            .custom-tooltip-time-right {
              font-size: 12px;
              float: right;
            }
          }
        }
      }

      .custom-item-info-left {
        display: none;
        position: absolute;
        bottom: 70px;
  
        text-align: center;
        margin: 0 auto;
        left: -30px;
        max-width: 250px;
        white-space: break-spaces;
        z-index: unset;
      }
  
      .custom-item-info-right {
        display: none;
        position: absolute;
        bottom: 70px;
  
        text-align: center;
        margin: 0 auto;
        right: -30px;
        max-width: 250px;
        white-space: break-spaces;
        z-index: unset;
      }
      
      .custom-item-context-menu {
        display: none;
        position: absolute;
        right: -136px;
        width: 200px;
        white-space: break-spaces;
        z-index: unset;
        bottom: -74px;
  
        .custom-item-context-submenu {
          left: 192px;
          position: absolute;
          width: 150px;
          top: 0px;
          display: none;
          z-index: unset;
        }
  
        ul {
          padding: 0px;
          margin: 0px;
  
          li {
            position: relative;
            list-style-type: none;
            padding: 10px 15px;
            color: #484848;
            cursor: pointer;
  
            &.muted {
              color: rgba($muted, 0.5) !important;
              cursor: default !important;
            }

            &:hover {
              color: #222;
              background-color: rgba(#484848, 0.2);
  
              > .custom-item-context-submenu {
                display: block;
              }
            }
  
            .custom-arrow-drop-down {
              position: absolute;
              top: -5px;
              right: 0px;
              font-size: 35px;
              color: #484848;
              padding-right: 10px;
  
              -webkit-transform: rotate(-90deg);
              -moz-transform: rotate(-90deg);
              -ms-transform: rotate(-90deg);
              -o-transform: rotate(-90deg);
              transform: rotate(-90deg);
            }
          }
        }
      }
  
      
      &.custom-hover {
        cursor: help;

        .custom-item-info-center {
          display: block;
        }
      }

      &.visible, &.visible-moving, &.visible-resizing {
        .custom-item-info-left,
        .custom-item-info-right {
          display: block;
  
          div {
            color: black !important;
          }
        }

        .custom-item-info-center {
          display: none;
        }
      }
  
      &.visible-context-menu {
        cursor: default;
        
        .custom-item-context-menu {
          display: block;
        }
        .custom-item-info-center {
          display: none;
        }
      }
    }

    
    &:first-child {
      &.custom-hover-row {
        z-index: 1;
        position: relative;
      }

      .gantt-schedule-timeline-calendar__chart-timeline-items-row-item {
        .custom-item-info-center {
          bottom: -105px;
        }
      }
    }
  }
  /* END ITEM */


  /* LIST COLUMN ROW */
  .custom-list-column-row {
    display: flex;
    align-items: center;
    background-color: white;
    line-height: unset;
    border: 1px solid #DEE9FF;

    * {
      line-height: unset;
    }

    .custom-menu {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: move;

      i {
        font-size: 18px;
        color: rgba(#484848, 0.10);
      }

      &:hover {
        i {
          color: rgba(#484848, 0.30);
        }
      }
    }

    .custom-label {
      font-weight: bold;
      padding-bottom: 5px;
      font-size: 14px;
    }

    .custom-desc {
      font-weight: normal;
      padding-bottom: 5px;
      font-size: 13px !important;
    }

    .custom-count {
      background-color: #ECEEF5;
      border-radius: 2px;
      padding: 0px 5px;
      font-size: 12px;
      position: relative;
      cursor: help;
    }

    .custom-done {
      cursor: help;

      i {
        font-size: 16px;
        position: relative;
        color: #06D6A0;
      }
    }

    .custom-watch {
      cursor: help;

      i {
        font-size: 16px;
        position: relative;
        color: #EF476F;
      }
    }
  }
  /* END LIST COLUMN ROW */


  /* SEARCH ROW */
  .custom-search-row {
    display: flex;
    align-items: center;
    line-height: unset;

    * {
      line-height: unset;
    }

    .custom-search-field {
      .MuiInput-underline {
        &:before {
          content: unset;
        }
        &:after {
          content: unset;
        }
      }

      button {
        padding: 5px;
      }

      input {
        font-size: 14px;

        background-color: #F0F3FA;
        border: 1px solid #F0F3FA;
      }
    }
  }
  /* END SEARCH ROW */

  
  /* VEHICLES DRIVRS ROW */
  .custom-vehicles-drivers-row {
    background-color: white;
    display: flex;
    align-items: center;
    text-align: center;
    line-height: unset;
    width: 100%;
    margin-bottom: 11px;
    min-height: 63px;
    border: 1px solid #DEE9FF;

    * {
      line-height: unset;
    }

    .custom-icon-button {
      font-size: 30px;
      color: #7F7F7F;

      &:first-child {
        margin-right: 20px;
      }

      &.active, &:hover {
        color: #484848;
      }
    }
  }
  /* END VEHICLES DRIVRS ROW */
}