.rich-text-editor-component {
    position: relative;
    overflow: hidden;

    .editor-class {
        min-height: 160px;
        background-color: $blue-focus1;
        padding: 10px;

        .public-DraftStyleDefault-block {
            margin: 0px !important;
        }

        .public-DraftEditorPlaceholder-inner {
            color: $gray-500 !important;
        }

        &:has(.public-DraftEditor-content[contenteditable="false"]){
            background-color: $gray-200 !important;
            color: $gray-500 !important;
            cursor: not-allowed !important;
        }
    }

    .rdw-link-modal {
        height: auto !important;
    }
}