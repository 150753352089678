.custom-bin-type-in-dropdown {
    .custom-header {
        cursor: default !important;

        .custom-col {
            padding: 5px 10px;
            border-bottom: 1px solid #E5E5E5;
            border-right: 1px solid #E5E5E5;
            color: #7F7F7F;
            font-size: $font-size-12;

            &:last-child {
                border-right: 0px;
            }
        }
    }

    .custom-body {
        max-height: 155px;
        overflow: auto;

        .custom-item {
            padding: 5px 10px;

            &.selected {
                background-color: $color-active;
                color: white;

                div {
                    color: white;
                }
            }

            &:hover {
                background-color: rgba($color-active, 0.2);
                color: $color-secondary;
                
                div {
                    color: $color-secondary;
                }
            }
        }
    }
    
    .custom-footer {
        padding: 5px 10px;
        border-top: 1px solid #E5E5E5;
        cursor: default !important;

        .custom-cel {
            color: #7F7F7F;
            font-size: $font-size-12;

            &.end {    
                display: inline-flex;
                justify-content: flex-end;
            }

            .link-default {
                display: inline-flex;
                align-items: center;
            }
        }
    }
}


.custom-radio-component {
    .custom-radio-label {
        cursor: pointer;
        display: inline-flex;
        color: #7F7F7F;
        font-size: $font-size-12;
        
        @include respond-below('md') {
            display: block;
        }
    }
}

.custom-bin-type-in-dropdown {
    .react-dropdown-select-dropdown {
        border-radius: 6px !important;
        overflow: hidden !important;

        @include respond-above('md') {
            min-width: 600px !important;
        }
    }
}