@mixin btn-custom-variant($background, $color, $border, $bg-hover, $hover, $border-hover, $bg-active, $active, $border-active, $bg-disabled, $disabled, $border-disabled, $shadow, $shadow-focus-color) {
  box-shadow: $shadow;
  border-radius: $border-radius;
  padding: 6px 16px;
  font-weight: 500;

  background-color: $background;
  color: $color;
  border-color: $border;

  &.btn-sm {
    padding: 4px 10px;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
  }

  &.btn-lg {
    padding: 8px 16px;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
  }

  svg {
    position: relative;
    top: -1px;
    
    path {
      stroke: $color;
    }
  }

  &:hover, &.active {
    background-color: $bg-hover !important;
    color: $hover !important;
    border-color: $border-hover !important;
    
    svg {
      path {
        stroke: $hover;
      }
    }
  }

  &:focus, &:focus-visible {
    box-shadow: 0 0 0 0.25rem $shadow-focus-color !important;

    background-color: $bg-hover !important;
    color: $hover !important;
    border-color: $border-hover !important;
  }
  
  &:active {
    background-color: $bg-active !important;
    color: $active !important;
    border-color: $border-active !important;
    
    svg {
      path {
        stroke: $active;
      }
    }
  }

  &:disabled {
    pointer-events: auto !important;
    cursor: not-allowed !important;

    background-color: $bg-disabled !important;
    color: $disabled !important;
    border-color: $border-disabled !important;
    
    svg {
      path {
        stroke: $disabled;
      }
    }
  }
}

@mixin btn-label-variant($background, $color, $border, $bg-hover, $hover, $border-hover, $bg-active, $active, $border-active, $bg-disabled, $disabled, $border-disabled, $shadow, $shadow-focus-color) {
  box-shadow: $shadow;
  border-radius: $border-radius;
  padding: 6px 16px;
  font-weight: 500;

  background-color: $background;
  color: $color;
  border-color: $border;

  &.btn-sm {
    padding: 4px 10px;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
  }

  &.btn-lg {
    padding: 8px 16px;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
  }

  svg {
    position: relative;
    top: -1px;
    
    path {
      stroke: $color;
    }
  }

  &:hover, &.active {
    background-color: $bg-hover !important;
    color: $hover !important;
    border-color: $border-hover !important;
    
    svg {
      path {
        stroke: $hover;
      }
    }
  }

  &:focus, &:focus-visible {
    // box-shadow: 0 0 0 0.25rem $shadow-focus-color !important;

    background-color: $background !important;
    color: $color !important;
    border-color: $border !important;
  }
  
  &:active, &.active {
    background-color: $bg-active !important;
    color: $active !important;
    border-color: $border-active !important;
    
    svg {
      path {
        stroke: $active;
      }
    }
  }

  &:disabled {
    pointer-events: auto !important;
    cursor: not-allowed !important;

    background-color: $bg-disabled !important;
    color: $disabled !important;
    border-color: $border-disabled !important;
    
    &.active {
      border-color: $border-active !important;
    }

    svg {
      path {
        stroke: $disabled;
      }
    }
  }
}

@mixin btn-none-variant($background, $color, $border, $bg-hover, $hover, $border-hover, $bg-active, $active, $border-active, $bg-disabled, $disabled, $border-disabled, $shadow, $shadow-focus-color) {
  display: inline-flex;
  align-items: center;

  box-shadow: $shadow;
  border-radius: $border-radius;
  padding: 0px;

  background-color: $background;
  color: $color;
  border-color: $border;

  svg {
    width: 15px !important;
    height: 17px !important;
  }

  &.btn-sm {
    svg {
      width: 8px !important;
      height: 10px !important;
    }
  }

  &.btn-lg {
    svg {
      width: 20px !important;
      height: 20px !important;
    }
  }

  svg {
    path {
      stroke: $color;
    }
  }

  &:hover, &.active {
    background-color: $bg-hover !important;
    color: $hover !important;
    border-color: $border-hover !important;
    
    svg {
      path {
        stroke: $hover;
      }
    }
  }

  &:focus, &:focus-visible {
    box-shadow: 0 0 0 0.25rem $shadow-focus-color !important;

    background-color: $bg-hover !important;
    color: $color !important;
    border-color: $border-hover !important;
    
    svg {
      path {
        stroke: $color;
      }
    }
  }
  
  &:active {
    background-color: $bg-active !important;
    color: $active !important;
    border-color: $border-active !important;
    
    svg {
      path {
        stroke: $active;
      }
    }
  }

  &:disabled {
    pointer-events: auto !important;
    cursor: not-allowed !important;

    background-color: $bg-disabled !important;
    color: $disabled !important;
    border-color: $border-disabled !important;
    
    svg {
      path {
        stroke: $disabled;
      }
    }
  }
}

@mixin btn-icon-variant($background, $color, $border, $bg-hover, $hover, $border-hover, $bg-active, $active, $border-active, $bg-disabled, $disabled, $border-disabled, $shadow, $shadow-focus-color) {
  display: inline-flex;
  align-items: center;

  box-shadow: $shadow;
  border-radius: $border-radius;
  padding: 0px;

  background-color: $background;
  color: $color;
  border-color: $border;

  svg {
    width: 15px !important;
    height: 17px !important;
    
    path {
      stroke: $color;
    }
  }

  &.btn-sm {
    svg {
      width: 10px !important;
      height: 10px !important;
    }
  }

  &.btn-lg {
    svg {
      width: 20px !important;
      height: 20px !important;
    }
  }

  &:hover, &.active {
    background-color: $bg-hover !important;
    color: $hover !important;
    border-color: $border-hover !important;
    
    svg {
      path {
        stroke: $hover;
      }
    }
  }

  &:focus, &:focus-visible {
    box-shadow: 0 0 0 0.25rem $shadow-focus-color !important;

    background-color: $bg-hover !important;
    color: $color !important;
    border-color: $border-hover !important;
    
    svg {
      path {
        stroke: $color;
      }
    }
  }
  
  &:active {
    background-color: $bg-active !important;
    color: $active !important;
    border-color: $border-active !important;
    
    svg {
      path {
        stroke: $active;
      }
    }
  }

  &:disabled {
    pointer-events: auto !important;
    cursor: not-allowed !important;

    background-color: $bg-disabled !important;
    color: $disabled !important;
    border-color: $border-disabled !important;
    
    svg {
      path {
        stroke: $disabled;
      }
    }
  }
}


$custom-primary-bg-color: $blue-500;
$custom-primary-color: $white;
$custom-primary-border: $blue-500;
$custom-primary-bg-hover: $blue-600;
$custom-primary-hover: $white;
$custom-primary-border-hover: $blue-500;
$custom-primary-bg-active: $blue-800;
$custom-primary-active: $white;
$custom-primary-border-active: $blue-800;
$custom-primary-bg-disabled: $gray-200;
$custom-primary-disabled: $gray-500;
$custom-primary-border-disabled: $gray-300;
$custom-primary-shadow: $shadow1;
$custom-primary-shadow-focus-color: $blue-focus2;
.btn-#{$custom-prefix}-primary {
  @include btn-custom-variant(
    $custom-primary-bg-color,
    $custom-primary-color,
    $custom-primary-border,
    $custom-primary-bg-hover,
    $custom-primary-hover,
    $custom-primary-border-hover,
    $custom-primary-bg-active,
    $custom-primary-active,
    $custom-primary-border-active,
    $custom-primary-bg-disabled,
    $custom-primary-disabled,
    $custom-primary-border-disabled,
    $custom-primary-shadow,
    $custom-primary-shadow-focus-color,
  );
}


$custom-secondary-bg-color: $blue-focus1;
$custom-secondary-color: $blue-500;
$custom-secondary-border: $blue-focus1;
$custom-secondary-bg-hover: $blue-focus1;
$custom-secondary-hover: $blue-600;
$custom-secondary-border-hover: $blue-focus1;
$custom-secondary-bg-active: $blue-focus1;
$custom-secondary-active: $blue-800;
$custom-secondary-border-active: $blue-focus1;
$custom-secondary-bg-disabled: $gray-200;
$custom-secondary-disabled: $gray-500;
$custom-secondary-border-disabled: $gray-300;
$custom-secondary-shadow: $shadow1;
$custom-secondary-shadow-focus-color: $blue-focus2;
.btn-#{$custom-prefix}-secondary {
  @include btn-custom-variant(
    $custom-secondary-bg-color,
    $custom-secondary-color,
    $custom-secondary-border,
    $custom-secondary-bg-hover,
    $custom-secondary-hover,
    $custom-secondary-border-hover,
    $custom-secondary-bg-active,
    $custom-secondary-active,
    $custom-secondary-border-active,
    $custom-secondary-bg-disabled,
    $custom-secondary-disabled,
    $custom-secondary-border-disabled,
    $custom-secondary-shadow,
    $custom-secondary-shadow-focus-color,
  );
}


$custom-danger-bg-color: $red-500;
$custom-danger-color: $white;
$custom-danger-border: $red-500;
$custom-danger-bg-hover: $red-600;
$custom-danger-hover: $white;
$custom-danger-border-hover: $red-500;
$custom-danger-bg-active: $red-800;
$custom-danger-active: $white;
$custom-danger-border-active: $red-800;
$custom-danger-bg-disabled: $gray-200;
$custom-danger-disabled: $gray-500;
$custom-danger-border-disabled: $gray-300;
$custom-danger-shadow: $shadow1;
$custom-danger-shadow-focus-color: $blue-focus2;
.btn-#{$custom-prefix}-danger {
  @include btn-custom-variant(
    $custom-danger-bg-color,
    $custom-danger-color,
    $custom-danger-border,
    $custom-danger-bg-hover,
    $custom-danger-hover,
    $custom-danger-border-hover,
    $custom-danger-bg-active,
    $custom-danger-active,
    $custom-danger-border-active,
    $custom-danger-bg-disabled,
    $custom-danger-disabled,
    $custom-danger-border-disabled,
    $custom-danger-shadow,
    $custom-danger-shadow-focus-color,
  );
}


$custom-success-bg-color: $green-500;
$custom-success-color: $white;
$custom-success-border: $green-500;
$custom-success-bg-hover: $green-600;
$custom-success-hover: $white;
$custom-success-border-hover: $green-500;
$custom-success-bg-active: $green-800;
$custom-success-active: $white;
$custom-success-border-active: $green-800;
$custom-success-bg-disabled: $gray-200;
$custom-success-disabled: $gray-500;
$custom-success-border-disabled: $gray-300;
$custom-success-shadow: $shadow1;
$custom-success-shadow-focus-color: $blue-focus2;
.btn-#{$custom-prefix}-success {
  @include btn-custom-variant(
    $custom-success-bg-color,
    $custom-success-color,
    $custom-success-border,
    $custom-success-bg-hover,
    $custom-success-hover,
    $custom-success-border-hover,
    $custom-success-bg-active,
    $custom-success-active,
    $custom-success-border-active,
    $custom-success-bg-disabled,
    $custom-success-disabled,
    $custom-success-border-disabled,
    $custom-success-shadow,
    $custom-success-shadow-focus-color,
  );
}


$custom-outlined-bg-color: $white;
$custom-outlined-color: $gray-800;
$custom-outlined-border: $gray-300;
$custom-outlined-bg-hover: $gray-50;
$custom-outlined-hover: $gray-800;
$custom-outlined-border-hover: $blue-500;
$custom-outlined-bg-active: $gray-100;
$custom-outlined-active: $gray-800;
$custom-outlined-border-active: $gray-400;
$custom-outlined-bg-disabled: $gray-200;
$custom-outlined-disabled: $gray-500;
$custom-outlined-border-disabled: $gray-300;
$custom-outlined-shadow: $shadow1;
$custom-outlined-shadow-focus-color: $blue-focus2;
.btn-#{$custom-prefix}-outlined {
  @include btn-custom-variant(
    $custom-outlined-bg-color,
    $custom-outlined-color,
    $custom-outlined-border,
    $custom-outlined-bg-hover,
    $custom-outlined-hover,
    $custom-outlined-border-hover,
    $custom-outlined-bg-active,
    $custom-outlined-active,
    $custom-outlined-border-active,
    $custom-outlined-bg-disabled,
    $custom-outlined-disabled,
    $custom-outlined-border-disabled,
    $custom-outlined-shadow,
    $custom-outlined-shadow-focus-color,
  );
}

$custom-label-outlined-bg-color: $white;
$custom-label-outlined-color: $gray-800;
$custom-label-outlined-border: $gray-300;
$custom-label-outlined-bg-hover: $white;
$custom-label-outlined-hover: $gray-800;
$custom-label-outlined-border-hover: $blue-500;
$custom-label-outlined-bg-active: $blue-focus3;
$custom-label-outlined-active: $blue-500;
$custom-label-outlined-border-active: $blue-500;
$custom-label-outlined-bg-disabled: $gray-200;
$custom-label-outlined-disabled: $gray-500;
$custom-label-outlined-border-disabled: $gray-300;
$custom-label-outlined-shadow: $shadow1;
$custom-label-outlined-shadow-focus-color: $transparent;
.btn-#{$custom-prefix}-label-outlined {
  @include btn-label-variant(
    $custom-label-outlined-bg-color,
    $custom-label-outlined-color,
    $custom-label-outlined-border,
    $custom-label-outlined-bg-hover,
    $custom-label-outlined-hover,
    $custom-label-outlined-border-hover,
    $custom-label-outlined-bg-active,
    $custom-label-outlined-active,
    $custom-label-outlined-border-active,
    $custom-label-outlined-bg-disabled,
    $custom-label-outlined-disabled,
    $custom-label-outlined-border-disabled,
    $custom-label-outlined-shadow,
    $custom-label-outlined-shadow-focus-color,
  );
}


$custom-text-bg-color: $white;
$custom-text-color: $gray-700;
$custom-text-border: $white;
$custom-text-bg-hover: $white;
$custom-text-hover: $blue-600;
$custom-text-border-hover: $white;
$custom-text-bg-active: $white;
$custom-text-active: $blue-800;
$custom-text-border-active: $white;
$custom-text-bg-disabled: $transparent;
$custom-text-disabled: $gray-500;
$custom-text-border-disabled: $transparent;
$custom-text-shadow: unset;
$custom-text-shadow-focus-color: $blue-focus2;
.btn-#{$custom-prefix}-text {
  @include btn-custom-variant(
    $custom-text-bg-color,
    $custom-text-color,
    $custom-text-border,
    $custom-text-bg-hover,
    $custom-text-hover,
    $custom-text-border-hover,
    $custom-text-bg-active,
    $custom-text-active,
    $custom-text-border-active,
    $custom-text-bg-disabled,
    $custom-text-disabled,
    $custom-text-border-disabled,
    $custom-text-shadow,
    $custom-text-shadow-focus-color,
  );
}


$custom-text-primary-bg-color: $white;
$custom-text-primary-color: $blue-500;
$custom-text-primary-border: $white;
$custom-text-primary-bg-hover: $white;
$custom-text-primary-hover: $blue-600;
$custom-text-primary-border-hover: $white;
$custom-text-primary-bg-active: $white;
$custom-text-primary-active: $blue-800;
$custom-text-primary-border-active: $white;
$custom-text-primary-bg-disabled: $transparent;
$custom-text-primary-disabled: $gray-500;
$custom-text-primary-border-disabled: $transparent;
$custom-text-primary-shadow: unset;
$custom-text-primary-shadow-focus-color: $blue-focus2;
.btn-#{$custom-prefix}-text-primary {
  @include btn-custom-variant(
    $custom-text-primary-bg-color,
    $custom-text-primary-color,
    $custom-text-primary-border,
    $custom-text-primary-bg-hover,
    $custom-text-primary-hover,
    $custom-text-primary-border-hover,
    $custom-text-primary-bg-active,
    $custom-text-primary-active,
    $custom-text-primary-border-active,
    $custom-text-primary-bg-disabled,
    $custom-text-primary-disabled,
    $custom-text-primary-border-disabled,
    $custom-text-primary-shadow,
    $custom-text-primary-shadow-focus-color,
  );
}


$custom-secondary-danger-bg-color: $red-focus1;
$custom-secondary-danger-color: $red-500;
$custom-secondary-danger-border: $red-focus1;
$custom-secondary-danger-bg-hover: $red-focus1;
$custom-secondary-danger-hover: $red-600;
$custom-secondary-danger-border-hover: $red-focus1;
$custom-secondary-danger-bg-active: $red-focus1;
$custom-secondary-danger-active: $red-800;
$custom-secondary-danger-border-active: $red-focus1;
$custom-secondary-danger-bg-disabled: $gray-200;
$custom-secondary-danger-disabled: $gray-500;
$custom-secondary-danger-border-disabled: $gray-300;
$custom-secondary-danger-shadow: unset;
$custom-secondary-danger-shadow-focus-color: $blue-focus2;
.btn-#{$custom-prefix}-secondary-danger {
  @include btn-custom-variant(
    $custom-secondary-danger-bg-color,
    $custom-secondary-danger-color,
    $custom-secondary-danger-border,
    $custom-secondary-danger-bg-hover,
    $custom-secondary-danger-hover,
    $custom-secondary-danger-border-hover,
    $custom-secondary-danger-bg-active,
    $custom-secondary-danger-active,
    $custom-secondary-danger-border-active,
    $custom-secondary-danger-bg-disabled,
    $custom-secondary-danger-disabled,
    $custom-secondary-danger-border-disabled,
    $custom-secondary-danger-shadow,
    $custom-secondary-danger-shadow-focus-color,
  );
}


$custom-none-primary-bg-color: $transparent;
$custom-none-primary-color: $gray-800;
$custom-none-primary-border: $transparent;
$custom-none-primary-bg-hover: $transparent;
$custom-none-primary-hover: $blue-500;
$custom-none-primary-border-hover: $transparent;
$custom-none-primary-bg-active: $transparent;
$custom-none-primary-active: $blue-800;
$custom-none-primary-border-active: $transparent;
$custom-none-primary-bg-disabled: $transparent;
$custom-none-primary-disabled: $gray-500;
$custom-none-primary-border-disabled: $transparent;
$custom-none-primary-shadow: unset;
$custom-none-primary-shadow-focus-color: unset;
.btn-#{$custom-prefix}-none-primary {
  @include btn-none-variant(
    $custom-none-primary-bg-color,
    $custom-none-primary-color,
    $custom-none-primary-border,
    $custom-none-primary-bg-hover,
    $custom-none-primary-hover,
    $custom-none-primary-border-hover,
    $custom-none-primary-bg-active,
    $custom-none-primary-active,
    $custom-none-primary-border-active,
    $custom-none-primary-bg-disabled,
    $custom-none-primary-disabled,
    $custom-none-primary-border-disabled,
    $custom-none-primary-shadow,
    $custom-none-primary-shadow-focus-color,
  );
}



$custom-none-secondary-bg-color: $transparent;
$custom-none-secondary-color: $gray-400;
$custom-none-secondary-border: $transparent;
$custom-none-secondary-bg-hover: $transparent;
$custom-none-secondary-hover: $gray-700;
$custom-none-secondary-border-hover: $transparent;
$custom-none-secondary-bg-active: $transparent;
$custom-none-secondary-active: $gray-800;
$custom-none-secondary-border-active: $transparent;
$custom-none-secondary-bg-disabled: $transparent;
$custom-none-secondary-disabled: $gray-500;
$custom-none-secondary-border-disabled: $transparent;
$custom-none-secondary-shadow: unset;
$custom-none-secondary-shadow-focus-color: unset;
.btn-#{$custom-prefix}-none-secondary {
  @include btn-none-variant(
    $custom-none-secondary-bg-color,
    $custom-none-secondary-color,
    $custom-none-secondary-border,
    $custom-none-secondary-bg-hover,
    $custom-none-secondary-hover,
    $custom-none-secondary-border-hover,
    $custom-none-secondary-bg-active,
    $custom-none-secondary-active,
    $custom-none-secondary-border-active,
    $custom-none-secondary-bg-disabled,
    $custom-none-secondary-disabled,
    $custom-none-secondary-border-disabled,
    $custom-none-secondary-shadow,
    $custom-none-secondary-shadow-focus-color,
  );
}



$custom-icon-primary-bg-color: $transparent;
$custom-icon-primary-color: $blue-500;
$custom-icon-primary-border: $transparent;
$custom-icon-primary-bg-hover: $transparent;
$custom-icon-primary-hover: $gray-800;
$custom-icon-primary-border-hover: $transparent;
$custom-icon-primary-bg-active: $transparent;
$custom-icon-primary-active: $blue-800;
$custom-icon-primary-border-active: $transparent;
$custom-icon-primary-bg-disabled: $transparent;
$custom-icon-primary-disabled: $gray-500;
$custom-icon-primary-border-disabled: $transparent;
$custom-icon-primary-shadow: unset;
$custom-icon-primary-shadow-focus-color: unset;
.btn-#{$custom-prefix}-icon-primary {
  @include btn-icon-variant(
    $custom-icon-primary-bg-color,
    $custom-icon-primary-color,
    $custom-icon-primary-border,
    $custom-icon-primary-bg-hover,
    $custom-icon-primary-hover,
    $custom-icon-primary-border-hover,
    $custom-icon-primary-bg-active,
    $custom-icon-primary-active,
    $custom-icon-primary-border-active,
    $custom-icon-primary-bg-disabled,
    $custom-icon-primary-disabled,
    $custom-icon-primary-border-disabled,
    $custom-icon-primary-shadow,
    $custom-icon-primary-shadow-focus-color,
  );
}


$custom-icon-danger-bg-color: $transparent;
$custom-icon-danger-color: $red-500;
$custom-icon-danger-border: $transparent;
$custom-icon-danger-bg-hover: $transparent;
$custom-icon-danger-hover: $gray-800;
$custom-icon-danger-border-hover: $transparent;
$custom-icon-danger-bg-active: $transparent;
$custom-icon-danger-active: $red-800;
$custom-icon-danger-border-active: $transparent;
$custom-icon-danger-bg-disabled: $transparent;
$custom-icon-danger-disabled: $gray-500;
$custom-icon-danger-border-disabled: $transparent;
$custom-icon-danger-shadow: unset;
$custom-icon-danger-shadow-focus-color: unset;
.btn-#{$custom-prefix}-icon-danger {
  @include btn-icon-variant(
    $custom-icon-danger-bg-color,
    $custom-icon-danger-color,
    $custom-icon-danger-border,
    $custom-icon-danger-bg-hover,
    $custom-icon-danger-hover,
    $custom-icon-danger-border-hover,
    $custom-icon-danger-bg-active,
    $custom-icon-danger-active,
    $custom-icon-danger-border-active,
    $custom-icon-danger-bg-disabled,
    $custom-icon-danger-disabled,
    $custom-icon-danger-border-disabled,
    $custom-icon-danger-shadow,
    $custom-icon-danger-shadow-focus-color,
  );
}