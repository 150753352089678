.custom-rsuite-date-range-picker {
    position: relative;
    .rs-picker-toggle {
        box-shadow: none;
        z-index: 1 !important;
    }
    .rs-picker-toggle-value {
        font-weight: 500;
    }
    .rs-picker-toggle-caret {
        display: none;
    }
    i {
        color: $color-active;
        position: absolute;
        right: 5px;
        top: 10px;
        z-index: 5;
    }
    &.reports-date-picker {
        .rs-picker-daterange {
            height: unset !important;
            box-shadow: none !important;
            padding: 2px 0px;
            border-radius: $border-radius !important;
            background-color: $white !important;
            font-size: $input-btn-font-size !important;
            font-weight: $font-weight-bold !important;
            line-height: $line-height-base !important;
        }

        a {
            padding: 7px !important;
            &:hover,
            &.active {
                background-color: transparent !important;
            }
        }
    }

    &.default-look {
        padding: 0;
        .rs-picker-toggle {
            z-index: 1 !important;
            background-color: transparent !important;
        }
        .rs-picker-date, .rs-picker-daterange {
            height: unset !important;
            box-shadow: none !important;
            padding: $input-btn-padding-y $input-btn-padding-x;
            border-radius: $border-radius;
            background-color: $white;
            font-size: $input-btn-font-size !important;
            font-weight: $font-weight-bold !important;
            line-height: $line-height-base !important;
            background-color: transparent !important;
            .rs-picker-toggle {
                padding: 0;
                box-shadow: none !important;
            }
            .rs-picker-toggle-placeholder,
            input {
                font-size: $input-btn-font-size !important;
                font-weight: $font-weight-bold !important;
                line-height: $line-height-base !important;
                color: $input-placeholder-color;
            }
            .rs-picker-toggle-value {
                font-size: $input-btn-font-size !important;
                font-weight: $font-weight-bold !important;
                line-height: $line-height-base !important;
                color: $secondary;
            }
            .rs-picker-toggle-clean {
                top: 2px !important;
                right: 0px !important;
            }
        }
        &.read-only {
            // border-color: transparent !important;
            * {
                color: #A1A5B7 !important;
            }
            cursor: default !important;
            .rs-picker-toggle-value,
            .rs-picker-toggle {
                cursor: default !important;
                color: #A1A5B7 !important;
            }
            .rs-picker-toggle-caret {
                display: none !important;
            }
        }
    }
}

.custom-rsuite-date-picker {
    position: relative;
    .rs-picker-toggle-value {
        font-weight: bold;
    }
    .rs-picker-toggle-caret {
        display: none;
    }
    i {
        color: $color-active;
        position: absolute;
        right: 5px;
        top: 10px;
        z-index: 99;
    }
    &.public-holiday-date-picker {
        i,
        svg {
            position: absolute;
            left: 10px;
            top: 7px;
            z-index: 6;
        }
        a {
            padding: 7px 7px 7px 40px !important;
            background-color: $bg-color;
            .rs-picker-toggle-value {
                color: $color-secondary;
            }
        }
    }
    &.default-look {
        padding: 0;
        .rs-picker-toggle {
            z-index: 1 !important;
        }
        .rs-picker-date {
            height: unset !important;
            box-shadow: none !important;
            padding: $input-btn-padding-y $input-btn-padding-x;
            border-radius: $border-radius;
            background-color: $white;
            font-size: $input-btn-font-size !important;
            font-weight: $font-weight-bold !important;
            line-height: $line-height-base !important;
            .rs-picker-toggle {
                padding: 0;
                box-shadow: none !important;
                background-color: transparent !important;
            }
            .rs-picker-toggle-placeholder,
            input {
                font-size: $input-btn-font-size !important;
                font-weight: $font-weight-bold !important;
                line-height: $line-height-base !important;
                color: $input-placeholder-color;
            }
            .rs-picker-toggle-value {
                font-size: $input-btn-font-size !important;
                font-weight: $font-weight-bold !important;
                line-height: $line-height-base !important;
                color: $secondary;
            }
            .rs-picker-toggle-clean {
                top: 2px !important;
                right: 0px !important;
            }
        }
        &.read-only {
            // border-color: transparent !important;
            * {
                color: #A1A5B7 !important;
            }
            cursor: default !important;
            .rs-picker-toggle-value,
            .rs-picker-toggle {
                cursor: default !important;
                color: #A1A5B7 !important;
            }
            .rs-picker-toggle-caret {
                display: none !important;
            }
        }
    }
    &.read-only {
        // border-color: transparent !important;
        * {
            color: #A1A5B7 !important;
        }
        cursor: default !important;
        .rs-picker-toggle-value,
        .rs-picker-toggle {
            cursor: default !important;
            color: #A1A5B7 !important;
        }
        .rs-picker-toggle-caret {
            display: none !important;
        }
    }
}

.rs-picker-daterange-menu {
    z-index: 9999 !important;
    position: absolute !important;
    @include respond-below('md') {
        overflow: auto !important;
        height: 400px;
    }
    .rs-calendar-table-cell-selected .rs-calendar-table-cell-content,
    .rs-calendar-month-dropdown-cell-active .rs-calendar-month-dropdown-cell-content {
        border-color: $color-active !important;
        background-color: $color-active !important;
        .rs-calendar-table-cell-day {
            color: white !important;
        }
    }
    .rs-calendar-month-dropdown-year-active,
    .rs-calendar-header-title-date {
        color: $color-active !important;
    }
    .rs-picker-daterange-panel {
        flex-flow: row wrap;
        display: inline-flex;
        padding-top: 40px;
        padding-bottom: 55px;
        @include respond-below('md') {
            flex-flow: column;
            padding-top: 0px;
            padding-bottom: 0px;
        }
        .rs-picker-daterange-content {
            order: 2;
            border-left: 1px solid #e5e5ea;
            display: flex;
            align-items: center;
            .rs-picker-daterange-header {
                position: absolute;
                top: 0px;
                left: 0px;
                width: 100%;
                span {
                    font-weight: normal;
                }
                @include respond-below('md') {
                    display: none;
                }
            }
            .rs-picker-daterange-calendar-group {
                .rs-calendar {
                    @include respond-below('md') {
                        display: block;
                        border-color: transparent;
                    }
                }
                @include respond-below('md') {
                    min-width: 300px !important;
                    height: auto !important;
                }
            }
        }
        .rs-picker-toolbar {
            order: 1;
            border-top: 0px;
            margin-top: 0px;
            // padding: 5px 10px;
            // width: min-content;
            @include respond-below('md') {
                width: auto !important;
                max-width: 300px !important;
                display: block !important;
            }
            .rs-picker-toolbar-ranges {
                margin-top: 0px;
                margin-left: 0px;
                // @include respond-below('md') {
                //     display: flex;
                //     justify-content: space-between;
                //     flex-wrap: wrap;
                // }

                button {
                    display: block;
                    border-radius: 5px;
                    padding: 3px 5px;
                    margin: 3px 0px;
                    border: 1px solid #e5e5ea;
                    color: $color-secondary;
                    text-decoration: none;
                    // @include respond-below('md') {
                    //     width: 32%;
                    // }
                    &:hover,
                    &.selected {
                        background-color: $color-active;
                        color: white;
                    }
                    &::before {
                        content: none;
                    }
                }
            }
            .rs-picker-toolbar-right {
                float: unset;
                display: block;
                position: absolute;
                bottom: 0px;
                left: 0px;
                width: 100%;
                text-align: right;
                padding: 10px 15px;
                border-top: 1px solid #e5e5ea;
                @include respond-below('md') {
                    position: relative;
                    border-top: 1px solid transparent;
                    padding-left: 0 !important;
                    padding-right: 0 !important;
                }
                button {
                    padding: 5px 10px !important;
                    background-color: $color-active;
                    @include respond-below('md') {
                        width: 100%;
                    }
                    span {
                        color: white;
                        display: none;
                    }
                    // &::after {
                    //     content: 'APPLY';
                    //     color: white;
                    // }
                    &:hover {
                        background-color: rgba($color-active, 0.8) !important;
                    }
                }
            }
        }
    }
}

.rs-picker-date-menu {
    z-index: 9999 !important;
    position: absolute !important;
    @include respond-below('md') {
        overflow: auto !important;
        height: 400px;
    }
    .rs-calendar-table-cell-selected .rs-calendar-table-cell-content,
    .rs-calendar-month-dropdown-cell-active .rs-calendar-month-dropdown-cell-content {
        border-color: $color-active !important;
        background-color: $color-active !important;
        .rs-calendar-table-cell-day {
            color: white !important;
        }
    }
    .rs-calendar-month-dropdown-year-active,
    .rs-calendar-header-title-date {
        color: $color-active !important;
    }
    .rs-picker-daterange-panel {
        flex-flow: row wrap;
        display: inline-flex;
        padding-top: 40px;
        padding-bottom: 55px;
        @include respond-below('md') {
            flex-flow: column;
            padding-top: 0px;
            padding-bottom: 0px;
        }
        .rs-picker-daterange-content {
            order: 2;
            border-left: 1px solid #e5e5ea;
            display: flex;
            align-items: center;
            .rs-picker-daterange-header {
                position: absolute;
                top: 0px;
                left: 0px;
                width: 100%;
                span {
                    font-weight: normal;
                }
                @include respond-below('md') {
                    display: none;
                }
            }
            .rs-picker-daterange-calendar-group {

                .rs-calendar {
                    @include respond-below('md') {
                        display: block;
                        border-color: transparent;
                    }
                }
                @include respond-below('md') {
                    min-width: 300px !important;
                    height: auto !important;
                }
            }
        }
        .rs-picker-toolbar {
            order: 1;
            border-top: 0px;
            margin-top: 0px;
            padding: 5px 10px;
            @include respond-below('md') {
                max-width: 300px !important;
            }
            .rs-picker-toolbar-ranges {
                margin-top: 0px;
                margin-left: 0px;
                @include respond-below('md') {
                    display: flex;
                    justify-content: space-between;
                    flex-wrap: wrap;
                }
                .rs-picker-toolbar-option {
                    display: block;
                    border-radius: 5px;
                    padding: 3px 5px;
                    margin: 3px 0px;
                    border: 1px solid #e5e5ea;
                    color: $color-secondary;
                    text-decoration: none;
                    @include respond-below('md') {
                        width: 32%;
                    }
                    &:hover,
                    &.selected {
                        background-color: $color-active;
                        color: white;
                    }
                    &::before {
                        content: none;
                    }
                }
            }
            .rs-picker-toolbar-right {
                float: unset;
                display: block;
                position: absolute;
                bottom: 0px;
                left: 0px;
                width: 100%;
                text-align: right;
                padding: 10px 15px;
                border-top: 1px solid #e5e5ea;
                @include respond-below('md') {
                    position: relative;
                    border-top: 1px solid transparent;
                }
                button {
                    background-color: $color-active;
                    @include respond-below('md') {
                        width: 100%;
                    }
                    span {
                        color: white;
                        display: none;
                    }
                    // &::after {
                    //     content: 'APPLY';
                    //     color: white;
                    // }
                    &:hover {
                        background-color: rgba($color-active, 0.8) !important;
                    }
                }
            }
        }
    }
}