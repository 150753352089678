.billing-page {
    .billing-primary-progress {
        height: 17px !important;
        border-radius: 0 !important;
        background-color: #ABABAB;

        > div {
            &.progress-bar-animated {
                background-color: #ABABAB;
            }
        }

        &.disabled-progress {
            > div {
                background-color: #ABABAB;
            }
        }
    }

    .billing-secondary-progress {
        height: 17px !important;
        border-radius: 0 !important;
        background-color: #EF476F;

        > div {
            background-color: #7ECE00;

            &.progress-bar-animated {
                background-color: #ABABAB;
            }
        }

        &.disabled-progress {
            > div {
                background-color: #ABABAB;
            }
        }
    }
}