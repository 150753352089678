// .manage-roles-form {
//     background-color:  white !important;
// }

.manage-layout {
    // background-color: $bg-color;
    // padding: 15px;
    // overflow: auto;
    
    .infinite-scroll-component  {
        overflow: initial !important;
    }

    .hamburger-btn {
        position: absolute;
        right: 0px;
        top: -25px;

        @include respond-above('lg') {
            display: none;
        }
    }

    .menu-list-items {
        position: relative;
        height: calc(100vh - 150px);
        min-height: 360px;

        .MuiListItem-root {
            border-radius: 5px;
            padding-top: 6px;
            padding-bottom: 6px;

            .MuiListItemText-root {
                margin: 0px;

                span {
                    font-size: $font-size-14;
                    color: #7F7F7F;
                    font-weight: bold;
                }
            }

            &:hover {
                background-color: transparent !important;

                .MuiListItemText-root {
                    span {
                        color: $main-color;
                    }
                }
            }

            &.active {
                // background-color: $color-active !important;

                .MuiListItemText-root {
                    span {
                        color: $main-color !important;
                    }
                }
            }
        }

        .bottom-list {
            position: absolute;
            bottom: 0;
            width: 100%;
        }
    }

    .manage-tabs {
        &.custom-tabs-component {
            .custom-tabs {
                border-bottom: 0px;
                min-height: unset !important;

                > div {
                    > div {
                        padding-left: 15px;
                        padding-right: 15px;
                    }
                }

                button {
                    min-width: unset !important;
                    min-height: unset !important;
                    background-color: transparent;
                    padding: 0;
                    margin-right: 25px;
                    
                    &:last-child {
                        margin-right: 0px;
                    }
                    
                    span {
                        font-size: $font-size-14;
                        font-weight: 600;
                        color: $color-secondary;
                        text-transform: none;
                        text-align: left;
                        display: block;
                    }
        
                    &[aria-selected="true"] {
                        background-color: transparent;

                        span {
                            color: $color-active;
                        }
                    }

                    &:hover {
                        background-color: transparent;
                        
                        span {
                            color: $color-active;
                        }
                    }
                }

                .MuiTabs-indicator {
                    display: none;;
                }
            }

            .custom-panel {
                border: 0px !important;
                box-shadow: unset !important;
                
                > .custom-div {
                    padding: 15px !important;
                }
            }

            .MuiTabs-indicator {
                background-color: $color-active !important;
            }
        }
    }

    .manage-pod-tabs {
        &.custom-tabs-component {
            .custom-tabs {
                border-bottom: 2px solid #F4F8FF;

                > div {
                    > div {
                        padding-top: 0;
                        padding-left: 0px;
                        padding-right: 0px;

                        @include respond-below('sm') {
                            display: block;
                        }
                    }
                }

                button {
                    min-width: unset !important;
                    background-color: $white;
                    padding: 0;
                    margin-right: 25px;
                    
                    &:last-child {
                        margin-right: 0px;
                    }
                    
                    span {
                        font-size: $font-size-14;
                        color: $color-secondary;
                        text-transform: none;
                        text-align: left;
                        display: block;

                        @include respond-below('sm') {
                            font-size: 12px;
                        }
                    }
        
                    &[aria-selected="true"] {
                        background-color: $white;
        
                        span {
                            color: $main-color;
                            font-weight: bold;
                        }
                    }

                    @include respond-below('sm') {
                        margin-right: 0px;
                        width: 100%;
                        display: block;
                    }
                }
            }

            .custom-panel {
                border: 0px !important;
                box-shadow: unset !important;
                
                > .custom-div {
                    padding: 25px 0px !important;
                }
            }

            .MuiTabs-indicator {
                background-color: transparent !important;
            }
        }
    }

    .manage-datatable-search {
        background-color: #F7F9FD;
        border: 1px solid #F7F9FD;
        border-radius: 5px;
        
        input {
            color: #7F7F7F;

            &::placeholder {
                color: #7F7F7F;
            }
        }
    
        .MuiInput-underline:before {
            border: 0px;
        }
        
        .MuiInput-underline:hover:not(.Mui-disabled):before {
            border: 0px;
        }

        svg {
            color: rgba(#7F7F7F, 1);
            font-size: 32px;
        }

        &.job-type {
            background-color: white;
            border: 0px;
            border-radius: 0px;

            input {
                background-color: white;
                border: 0px;
            }
        }
    }

    .manage-table {
        overflow: auto;

        table {
            position: relative;
            table-layout: fixed;
            width: 100%;
            min-width: 600px;
            border: 0px;
            border-collapse: separate;
            border: 1px solid #ECECEC;

            caption {
                font-weight: bold;
                text-align: left;
                padding-bottom: 10px;
            }

            thead {
                background-color: #ECECEC;

                // position: sticky;
                // top: 64px;
                z-index: 1;

                @include respond-below('lg') {
                    position: unset;
                }

                tr {
                    th {
                        font-weight: bold;
                        text-align: left;
                        padding: 5px !important;
                        padding-left: 15px !important;
                        padding-right: 15px !important;
                        border: 0px;
                        height: 42px !important;

                        border-bottom: 1px solid #ECECEC;

                        &:last-child {
                            border-right: 0px;
                        }
                    }
                }
            }

            tbody {
                background-color: white;
                
                tr {
                    td {
                        text-align: left;
                        padding: 5px !important;
                        padding-left: 15px !important;
                        padding-right: 15px !important;
                        border: 0px;
                        height: 55px !important;
                        background-color: white;

                        border-bottom: 1px solid #ECECEC;

                        &:last-child {
                            border-right: 0px;
                        }

                        &.no-p-lr {
                            padding-left: 0px !important;
                            padding-right: 0px !important;
                        }
                    }
                }
            }

            tfoot {
                background-color: white;
                
                tr {
                    td {
                        font-weight: bold;
                        text-align: left;
                        padding: 5px !important;
                        padding-left: 15px !important;
                        padding-right: 15px !important;
                        border: 0px;
                        height: 55px !important;

                        border-top: 1px solid $bg-color;
                        border-bottom: 1px solid $bg-color;

                        border-right: 1px solid $bg-color;
                        &:last-child {
                            border-right: 0px;
                        }
                    }
                }
            }
        }
        
        .align-left {
            text-align: left !important;
        }
    }
}

.side-right-menu-list {
    padding: 0px;
    padding-left: 10px !important;
    padding-right: 10px !important;
}


.subscription-download-icon {
    color: $color-active !important;
    font-size: 30px !important;
}

.custom-subscription-dialog {
    z-index: 9998 !important;

    .MuiDialog-paper {
        // background-color: #ffd1d8;

        .dialog-close-btn-in {
            .MuiIconButton-label {
                svg {
                    fill: rgba($color-secondary, 0.1) !important;
                }
            }
        }
    }

    .btn-danger {
        border-radius: 6px !important;

        span {
            font-weight: bold;
        }
    }
}