.view-layout {
    .title {
        margin-bottom: 0px;
    }
    
    .sections {
        .custom-top-section {
            position: sticky;
            z-index: 1;
            background-color: white;

            @include respond-below("md") {
                position: relative;
                top: 0px !important;
            }
        }
    }
}

.map-content-mh-100 {
    @include respond-below("md") {
        min-height: 100vh;
    }
}