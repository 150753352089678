.one-status-item-component {
    .item {
        cursor: pointer;
            
        .title {
            font-size: 18px;

            @include respond-below('xl') {
                font-size: 14px;
            }
        }
        
        .numb {
            font-size: 25px;
            // font-weight: bold;
            cursor: pointer;

            * {
                cursor: pointer;
            }

            @include respond-below('xl') {
                font-size: 18px;
            }
        }
    
        .text {
            font-size: $font-size-14;
            cursor: pointer;

            @include respond-below('xl') {
                font-size: 14px;
            }
        }
       
        .icon {
            font-size: 25px;
            cursor: pointer;
        }

        &:hover {
            .numb, .text, .icon {
                color: rgba($color-secondary, 0.5);
            }
        }

        &.active {
            .numb, .text, .icon {
                color: rgba($color-active, 0.5);
            }
        }

        &.disabled {
            cursor: default;

            .numb, .text, .icon {
                cursor: default;
                color: $color-secondary;

                .red-status-label {
                    cursor: default;
                    background-color: #EF476F;

                    * {
                        cursor: default;
                    }
                }
                
                .green-status-label {
                    cursor: default;
                    background-color: #06D6A0;

                    * {
                        cursor: default;
                    }
                }
            }
        }
    }

    &.job-statuses-types {
        &:not(.active){
            cursor: pointer;

            * {
                cursor: pointer;
            }

            .item {
                cursor: pointer;
                    
                .title {
                    font-size: 18px;
        
                    @include respond-below('xl') {
                        font-size: 14px;
                    }
                }
                
                .numb {
                    font-size: 25px;
                    font-weight: normal;
                    cursor: pointer;
                    color: $color-active;

                    span {
                        font-size: 25px;
                        font-weight: normal;
                        cursor: pointer;
                        color: $color-active;

                        &.red-status-label {
                            color: #EF476F;
                        }
                    }
                }
            
                .text {
                    font-size: 15px;
                    cursor: pointer;
                    color: $color-secondary;
                    font-size: 14px;
                    text-transform: capitalize !important;
                    padding-left: 10px !important;
                    padding-right: 10px !important;
                }
            
                .icon {
                    font-size: 25px;
                    cursor: pointer;
                }
        
                .red-status-label {
                    color: #EF476F;
                }

                .secondary-status-label {
                    font-size: 25px;
                    font-weight: normal;
                    cursor: pointer;
                    color: $color-secondary;
                }

                &:hover {
                    .numb, .icon {
                        color: rgba($color-active, 0.8);

                        .red-status-label {
                            color: rgba(#EF476F, 0.8);
                        }

                        .secondary-status-label {
                            color: rgba($color-secondary, 0.8);
                        }
                    }

                    .text {
                        color: rgba($color-secondary, 0.8);
                    }
                }
        
                &.active {
                    .numb, .icon {
                        color: rgba($color-active, 0.8);
                    }

                    .text {
                        color: rgba($color-secondary, 0.8);
                    }
                }
        
                &.disabled {
                    cursor: default;
        
                    .numb, .text, .icon {
                        cursor: default;
                        color: $color-secondary;
        
                        .red-status-label {
                            cursor: default;
                            color: #EF476F;
        
                            * {
                                cursor: default;
                            }
                        }
                    }
                }
            }
        }

        &.active {
            cursor: pointer;

            * {
                cursor: pointer;
            }

            .item {
                cursor: pointer !important;

                .numb {
                    font-size: 25px;
                    font-weight: bold;
                    cursor: pointer;
                    color: $color-secondary !important;
        
                    span {
                        font-size: 25px;
                        font-weight: bold;
                        cursor: pointer;
                        color: $color-secondary !important;

                        &.red-status-label {
                            color: $color-secondary !important;
                        }
                    }
                }

                .text {
                    font-size: 15px;
                    cursor: pointer;
                    color: $color-secondary !important;
                    font-size: 14px;
                    text-transform: capitalize !important;
                    padding-left: 10px !important;
                    padding-right: 10px !important;
                    font-weight: bold;

                    span {
                        font-weight: bold;
                        color: $color-secondary !important;
                    }
                }
            }
        }
    }
}

.icons {
    .one-status-item-component {
        .MuiGrid-container {
            .MuiGrid-item:last-child {
                position: relative;

                div {
                    &:after {
                        content: '';
                        position: absolute;
                        top: 10px;
                        width: 95%;
                        border-top: 4px dashed rgba($color-secondary, 0.10);

                        @include respond-below('md') {
                            content: unset;
                        }
                    }
                }
            }
        }
    }

    > .MuiGrid-item:last-child {
        .one-status-item-component {
            .MuiGrid-container {
                .MuiGrid-item:last-child {
                    div {
                        &:after {
                            content: unset;
                        }
                    }
                }
            }
        }
    }
}