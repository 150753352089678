.custom-rsuite-input-picker {
    .rs-picker-input {
        border: 0px !important;
    }

    .rs-picker-toggle-wrapper {
        width: 100%;
        display: grid;
        box-shadow: none !important;

        .rs-btn-default {
            height: 50px;
            display: flex !important;
            align-items: center !important;
            border-radius: 0px;
            outline: none;
            border: 0px;
            background-color: $table-td-bg !important;

            .rs-picker-toggle-value {
                color: $color-secondary;
            }
            .rs-picker-toggle-caret {
                top: 10px !important;
            }
            .rs-picker-toggle-clean {
                top: 10px !important;
            }
        }
    }

    .rs-checkbox-wrapper::before {
        border: 1px solid $color-active !important;
    }
    
    &.bg-color {
        .rs-picker-toggle-wrapper {
            .rs-btn-default {
                background: $bg-color !important;
            }
        }
    }

    &.bg-transparent {
        .rs-picker-toggle-wrapper {
            .rs-btn-default {
                background: transparent !important;
            }
        }
    }

    &.error {
        .rs-picker-toggle-wrapper {
            .rs-btn-default {
                border: 1px solid rgba(207, 62, 62, 0.4) !important
            }
        }
    }


    &.icon-left {
        .material-icons {
            position: absolute;
            top: 15px;
            left: 5px;
            z-index: 6;
    
            &.muted {
                color: rgba($color-secondary, 0.20) !important;
            }
        }
    }

    &.icon-right {
        .material-icons {
            position: absolute;
            top: 15px;
            right: 15px;
            z-index: 6;
    
            &.muted {
                color: rgba($color-secondary, 0.20) !important;
            }
        }
    }
    
    &.has-icon {
        .rs-picker-toggle-caret {
            &::before {
                content: '';
            }
        }

        &.icon-left {
            > a {
                padding-left: 35px;
            }
        }

        &.icon-right {
            > a {
                padding-right: 35px;
            }
        }
    }


    &.default-look {
        padding: 0;

        .rs-input {
            height: unset !important;
            box-shadow: none !important;
            border: 0 !important;
            padding: $input-btn-padding-y $input-btn-padding-x;
            border-radius: $border-radius !important;
            background-color: $white !important;
            outline: unset !important;
            border-color: transparent !important;
        }

        .rs-picker-toggle {
            z-index: 1 !important;
        }
        
        .rs-picker-toggle-wrapper {
            height: 100%;
            
            .rs-btn-default {
                box-shadow: none !important;
                height: 38px !important;
                padding: $input-btn-padding-y $input-btn-padding-x;
                border-radius: $border-radius !important;
                background-color: $white !important;
                justify-content: start;

                // &[aria-expanded="true"] {
                //     border-color: $input-focus-border-color !important;
                // }
                
                .rs-picker-toggle-placeholder {
                    font-size: $input-btn-font-size !important;
                    font-weight: $font-weight-bold !important;
                    line-height: $line-height-base !important;
                    min-height: 20px;
                }


                .rs-picker-toggle-value {
                    font-size: $input-btn-font-size !important;
                    font-weight: $font-weight-bold !important;
                    line-height: $line-height-base !important;
                    color: $secondary;
                }
            }
        }

        &.is-invalid {
            padding: 0px !important;
        }
    }

    &.read-only {
        // border-color: transparent !important;
        cursor: default !important;
        * {
            color: #A1A5B7 !important;
        }
        .rs-btn-default {
            cursor: default !important;
            .rs-picker-toggle-value {
                color: #A1A5B7 !important;
            }
            // padding: $input-btn-padding-y 0 !important;
        }

        .rs-picker-toggle-caret {
            display: none !important;
        }
    }
}

.rs-picker-input-menu, .rs-picker-menu {
    z-index: 1300 !important;
    border-radius: 0px !important;

    // .rs-picker-select-menu-item-focus {
    //     background-color: transparent !important;
    // }

    button {
        padding: 3px !important;
    }

    .add-btn {
        button {
            background-color: $table-td-bg !important;
            padding-bottom: 0;
            padding-top: 10px;
            padding-bottom: 5px;

            font-size: $font-size-14;
            color: $color-secondary;
            font-weight: normal;

            i {
                position: relative;
                color: $color-active;
                top: 3px;
            }

            div {
                color: $color-active;
                font-weight: 600;
            }

            &:hover {
                background-color: rgba($color-active, 0.1) !important;
            }

            &.Mui-disabled {
                cursor: not-allowed !important;
                background-color: rgba($color-secondary, 0.1) !important;

                i {
                    color: $color-secondary;
                }
    
                div {
                    color: $color-secondary;
                }
            }
        }
    }
    
    .delete-btn {
        &:hover {
            span {
                i {
                    color: $color-danger;
                }
            }
        }
    }


    &.underline-select-menu {
        > div[role="list"] {
            max-height: 200px !important;

            div[role="listitem"] {
                border-bottom: 1px solid rgba($color-secondary, 0.1);

                &:last-child {
                    border-bottom: 0px;
                }
            }
        }
    }

    .custom-mw-800 {
        min-width: 800px;

        @include media-breakpoint-down(xl) {
            min-width: 700px;
        }
        @include media-breakpoint-down(lg) {
            min-width: 600px;
        }
        @include media-breakpoint-down(md) {
            min-width: auto;
        }
    }
}