.custom-grid-table-component {
  .header-row {
    min-height: 40px;
    border-bottom: 1px solid #e5e5e5;
    margin-bottom: 15px;

    &-custom {
      min-height: 30px;

      &-item {
        .custom-row {
          margin-bottom: 5px;

          .custom-cell {
            color: #7f7f7f;
            font-weight: bold;
            font-size: 13px;

            padding-left: 15px;
          }
        }
      }
    }

    &-custom-right {
      min-width: 60px;

      &.mh-105 {
        min-width: 105px;
      }
    }
  }

  .items-row {
    min-height: 63px;

    &-custom {
      min-height: 63px;

      &.no-mh {
        min-height: unset !important;
      }

      &-item {
        .custom-row {
          .custom-cell {
            color: $color-secondary;

            .pl-25 {
              padding-left: 14px;
            }
          }
        }
      }
    }

    &.no-mh {
      min-height: unset !important;
    }

    &-custom-right {
      min-width: 60px;

      &.mh-105 {
        min-width: 105px;
      }
    }
  }

  .empty-row {
    min-height: 63px;
    border-bottom: 1px solid #e5e5e5;

    &.pb-10 {
      padding-bottom: 10px;
    }
    &.pt-10 {
      padding-top: 10px;
    }

    &-custom {
      min-height: 63px;

      &.no-mh {
        min-height: unset !important;
      }

      &-item {
        .custom-row {
          .custom-cell {
            color: $color-secondary;
          }
        }
      }

      .custom-different {
        input,
        .MuiSelect-select {
          background-color: #eaeaea !important;
        }
      }
    }

    &.no-mh {
      min-height: unset !important;
    }

    &-custom-right {
      min-width: 60px;

      &.mh-105 {
        min-width: 98px;
      }
    }
  }

  .footer-row {
    min-height: 40px;

    &-custom {
      min-height: 30px;

      &.no-mh {
        min-height: unset !important;
      }

      &-item {
        padding-top: 5px;

        .custom-row {
          .custom-cell {
            border-top: 1px solid #e5e5e5;
            color: $color-secondary;

            &.pl-25 {
              padding-left: 18px;
            }
          }
        }
      }
    }

    &.no-mh {
      min-height: unset !important;
    }

    &-custom-right {
      min-width: 60px;

      &.mh-105 {
        min-width: 98px;
      }
    }
  }

  .remove-icon {
    .MuiIconButton-label {
      color: #7f7f7f;
    }

    &:hover {
      .MuiIconButton-label {
        color: #ef476f;
      }
    }

    i {
      font-size: 25px;
    }
  }

  .add-icon {
    .MuiIconButton-label {
      color: $color-active;
    }

    &:hover {
      .MuiIconButton-label {
        color: #7f7f7f;
      }
    }

    i {
      font-size: 25px;
    }
  }
  .ok-icon {
    .MuiIconButton-label {
      color: #06d6a0;
    }

    &:hover {
      .MuiIconButton-label {
        color: rgba(#06d6a0, 0.8);
      }
    }

    i {
      font-size: 25px;
    }

    &.Mui-disabled {
      cursor: not-allowed;

      .MuiIconButton-label {
        color: rgba(#7f7f7f, 0.5) !important;
      }
    }
  }
  .cancel-icon {
    .MuiIconButton-label {
      color: rgba(#7f7f7f, 0.39);
    }

    &:hover {
      .MuiIconButton-label {
        color: #7f7f7f;
      }
    }

    i {
      font-size: 25px;
      width: 1em !important;
    }

    &.Mui-disabled {
      cursor: not-allowed;

      .MuiIconButton-label {
        color: rgba(#7f7f7f, 0.5) !important;
      }
    }
  }
  .no-border {
    border: 0px !important;
  }

  &.job-type-list {
    .header-row {
      &-custom {
        &-item {
          .custom-row {
            .custom-cell {
              border-bottom: 0px;
              padding-left: 8px;
            }
          }
        }
      }
    }
  }

  &.billing-by-site-list {
    .ok-icon {
      .MuiIconButton-label {
        color: #06d6a0;
      }

      &:hover {
        .MuiIconButton-label {
          color: rgba(#06d6a0, 0.8);
        }
      }

      i {
        font-size: 25px;
      }

      &.Mui-disabled {
        cursor: not-allowed;

        .MuiIconButton-label {
          color: rgba(#7f7f7f, 0.5) !important;
        }
      }
    }

    .cancel-icon {
      .MuiIconButton-label {
        color: rgba(#7f7f7f, 0.39);
      }

      &:hover {
        .MuiIconButton-label {
          color: #7f7f7f;
        }
      }

      i {
        font-size: 25px;
      }

      &.Mui-disabled {
        cursor: not-allowed;

        .MuiIconButton-label {
          color: rgba(#7f7f7f, 0.5) !important;
        }
      }
    }

    .remove-icon {
      .MuiIconButton-label {
        color: $color-active;
      }

      &:hover {
        .MuiIconButton-label {
          color: #ef476f;
        }
      }

      i {
        font-size: 25px;
      }

      &.Mui-disabled {
        cursor: not-allowed;

        .MuiIconButton-label {
          color: rgba(#7f7f7f, 0.5) !important;
        }
      }
    }

    .edit-icon {
      opacity: 1 !important;

      .MuiIconButton-label {
        opacity: 1 !important;

        svg {
          opacity: 1 !important;

          path {
            opacity: 1 !important;
            fill: transparent !important;
            stroke: $color-active !important;
          }
        }
      }

      &:hover {
        .MuiIconButton-label {
          svg {
            path {
              fill: $color-active !important;
              stroke: $color-active !important;
            }
          }
        }
      }

      i {
        font-size: 25px;
      }

      &.Mui-disabled {
        cursor: not-allowed;

        .MuiIconButton-label {
          svg {
            path {
              fill: rgba(#7f7f7f, 0.5) !important;
              stroke: #7f7f7f !important;
            }
          }
        }
      }
    }

    .header-row {
      margin-bottom: 0px !important;
      border-top: 1px solid #e5e5e5;

      .custom-row {
        margin-bottom: 0px !important;
        min-height: 100%;

        > div {
          > div {
            min-height: 100%;
          }
        }
      }

      .MuiGrid-container {
        min-height: 49px;
      }

      .header-row-custom-item {
        .custom-cell {
          border-right: 1px solid #e5e5e5;
          align-items: center;
          display: grid;

          @include respond-below("md") {
            border-right: 0px;
          }
        }
      }
    }

    .items-row {
      border-bottom: 1px solid #e5e5e5;

      .custom-row {
        min-height: 100%;

        > div {
          > div {
            min-height: 100%;
          }
        }
      }

      .MuiGrid-container {
        min-height: 49px;
      }

      .items-row-custom-item {
        .custom-cell {
          padding: 5px;
          border-right: 1px solid #e5e5e5;
          align-items: center;
          display: grid;

          @include respond-below("md") {
            border-right: 0px;
          }
        }
      }
    }

    .footer-row {
      .custom-row {
        min-height: 100%;

        > div {
          > div {
            min-height: 100%;
          }
        }
      }

      .MuiGrid-container {
        min-height: 49px;
      }

      .footer-row-custom-item {
        padding: 0px;

        .custom-cell {
          border: 0px;
          align-items: center;
          display: grid;

          @include respond-below("md") {
            border-right: 0px;
          }
        }
      }
    }
  }

  &.customer-grid-list {
    .remove-icon {
      .MuiIconButton-label {
        color: #ef476f;
      }

      &:hover {
        .MuiIconButton-label {
          color: #ef476f;
        }
      }

      i {
        font-size: 25px;
      }

      &.Mui-disabled {
        cursor: not-allowed;

        .MuiIconButton-label {
          color: rgba(#7f7f7f, 0.5) !important;
        }
      }
    }

    .header-row {
      margin-bottom: 0px !important;
      border-top: 0px;
      border-bottom: 0px;

      .custom-row {
        margin-bottom: 0px !important;
        min-height: 100%;

        > div {
          > div {
            min-height: 100%;
          }
        }
      }

      .MuiGrid-container {
        min-height: 39px;
      }

      .header-row-custom {
        border-bottom: 1px solid #e5e5e5;
      }

      .header-row-custom-item {
        .custom-cell {
          border-right: 0px;
          align-items: center;
          display: grid;

          @include respond-below("md") {
            border-right: 0px;
          }
        }
      }
    }

    .items-row {
      border-bottom: 0px;

      .custom-row {
        min-height: 100%;

        > div {
          > div {
            min-height: 100%;
          }
        }
      }

      .MuiGrid-container {
        min-height: 49px;
      }

      .items-row-custom {
        border-bottom: 1px solid #e5e5e5;
      }

      .items-row-custom-item {
        &:first-child {
          border-left: 1px solid #e5e5e5;
        }

        @include respond-below("md") {
          &:first-child {
            border-left: 0px;
          }
        }

        .custom-cell {
          padding: 5px;
          border-right: 1px solid #e5e5e5;
          align-items: center;
          display: grid;

          @include respond-below("md") {
            border-right: 0px;
          }
        }
      }
    }

    .footer-row {
      .custom-row {
        min-height: 100%;

        > div {
          > div {
            min-height: 100%;
          }
        }
      }

      .MuiGrid-container {
        min-height: 49px;
      }

      .footer-row-custom-item {
        padding: 0px;

        .custom-cell {
          border: 0px;
          align-items: center;
          display: grid;

          @include respond-below("md") {
            border-right: 0px;
          }
        }
      }
    }
  }
}
