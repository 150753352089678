.snackbar-component {
    .MuiAlert-icon {
        svg {
            color: white;
        }
    }
    .MuiAlert-message {
        color: white;
    }
    .MuiAlert-action {
        button {
            .MuiIconButton-label {
                svg {
                    color: white;
                }
            }
        }
    }
}