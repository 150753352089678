.custom-pod-pdf-preview {
    overflow: auto;
    height: 700px;
    
    .custom-card-pdf {
        background-color: #8D8D8D;
    }

    .mobile-m-0-auto {
        @include respond-below('md') {
            margin: 0 auto;
        }
    }

    .mobile-w-200-250 {
        width: 250px !important;
    
        @include respond-below('md') {
            width: 200px !important;
        }
    }

    .mobile-mw-200-250 {
        max-width: 250px !important;
    
        @include respond-below('md') {
            max-width: 200px !important;
        }
    }
}