.ant-select-auto-complete {
  padding: 0px !important;
  box-shadow: $shadow1 !important;

  .custom-ant-input {
    border: none;
    outline: none;
    background: transparent;
    margin: 0;
    padding-left: 11px;
    width: 100%;
    height: 100%;
    min-height: 29px;
    box-shadow: none;
    font-size: inherit;
    color: inherit;
  }

  .ant-select-selector {
    background: $transparent !important;
    border: 1px solid $transparent !important;
    box-shadow: unset !important;

    .ant-select-selection-search {
      left: 0px !important;
      padding-right: 20px;
    }

    .ant-select-selection-placeholder {
      position: absolute !important;
      left: 0px !important;
      color: $gray-500 !important;
    }

    .ant-select-selection-item {
      color: $gray-700 !important;
    }

    .ant-select-selection-overflow-item {
      .ant-select-selection-item {
        background-color: $blue-50 !important;

        .ant-select-selection-item-content {
          color: $blue-500 !important;
          font-weight: 500;
        }

        .ant-select-selection-item-remove {
          svg {
            path {
              fill: $blue-500 !important;
            }
          }

          &:hover {
            svg {
              path {
                fill: $blue-800 !important;
              }
            }
          }
        }
      }
    }
  }

  .ant-select-arrow {
    svg {
      path {
        fill: $gray-800 !important;
      }
    }
  }

  .ant-select-clear {
    svg {
      path {
        fill: $gray-800 !important;
      }
    }
  }


  &:hover {
    .ant-select-selector {
      border: 1px solid $transparent !important;

      .ant-select-selection-placeholder {
        color: $gray-600 !important;
      }
    }
  }
  &.ant-select-focused {
    .ant-select-selector {
      box-shadow: 0 0 0 0.25rem $blue-focus2 !important;
      border: 1px solid $blue-500 !important;

      .ant-select-selection-placeholder {
        color: $gray-700 !important;
      }
    }
  }

  &.ant-select-disabled {
    background-color: $gray-200 !important;
    .ant-select-selector {
      background: $gray-200 !important;
      border: 1px solid $gray-200 !important;
      box-shadow: unset !important;

      .ant-select-selection-placeholder {
        color: $gray-500 !important;
      }

      .ant-select-selection-item {
        color: $gray-500 !important;
      }

      .ant-select-selection-overflow-item {
        .ant-select-selection-item {
          background-color: $gray-50 !important;
  
          .ant-select-selection-item-content {
            color: $gray-500 !important;
          }
        }
      }
    }

    .ant-select-arrow {
      cursor: not-allowed !important;

      svg {
        path {
          fill: $gray-500 !important;
        }
      }
    }
  
    .ant-select-clear {
      cursor: not-allowed !important;

      svg {
        path {
          fill: $gray-500 !important;
        }
      }
    }

    &:hover {
      border: 1px solid $gray-300 !important;

      .ant-select-selector {
        border: 1px solid $gray-300 !important;
      }
    }
    
    +.form-text {
      color: $gray-500 !important;
    }
  }
}


.input-group {
  .ant-select.form-control {
    padding-right: 0px !important;

    .ant-select-selection-search {
      padding-right: 45px !important;
    }

    .ant-select-arrow {
      right: 37px;
    }

    .ant-select-clear {
      right: 37px;
    }

    &.is-invalid{
      +.input-group-text {
        position: absolute !important;
        right: 0px !important;
      }
    }
    &.is-valid{
      +.input-group-text {
        position: absolute !important;
        right: 0px !important;
      }
    }
  }

  .ant-select.form-control + .input-group-text {
    position: absolute !important;
    right: 0px !important;

    .btn {
      top: -1px;
    }
  }
}