.ant-checkbox-wrapper {
    box-shadow: none;
    border-radius: $border-radius;

    .ant-checkbox {
        .ant-checkbox-inner {
            border-color: $gray-400 !important;
            width: 20px !important;
            height: 20px !important;
        }

        &.ant-checkbox-indeterminate {
            .ant-checkbox-inner {
                &::after {
                    width: 10px !important;
                    height: 10px !important;
                }
            }
        }
    }

    &.sm {
        .ant-checkbox {
            .ant-checkbox-inner {
                width: 16px !important;
                height: 16px !important;
            }

            &.ant-checkbox-indeterminate {
                .ant-checkbox-inner {
                    &::after {
                        width: 8px !important;
                        height: 8px !important;
                    }
                }
            }
        }
    }

    span {
        color: $gray-800 !important;
    }

    &:hover {
        .ant-checkbox {
            .ant-checkbox-inner {
                border-color: $blue-500 !important;
            }
        }
    
        span {
            color: $gray-800 !important;
        }
    }

    &:focus, &:focus-visible {
        .ant-checkbox {
            .ant-checkbox-inner {
                box-shadow: 0 0 0 0.25rem $blue-focus2 !important;
                border-color: $blue-500 !important;
            }
        }
    
        span {
            color: $gray-800 !important;
        }
    }

    &.ant-checkbox-wrapper-disabled {
        .ant-checkbox {
            .ant-checkbox-inner {
                background-color: $gray-100 !important;
                border-color: $gray-300 !important;

                // &::after {
                //     border-color: $white !important;
                // }
            }
        }
    
        span {
            color: $gray-500 !important;
        }
    }
}


// Switch Checkbox Radio border
.checkbox-field {
    width: fit-content;
    border: 1px solid $gray-300;
    border-radius: $border-radius;
    padding: 8px;
}