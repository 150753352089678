.view-top-section-layout {
    .title {
        // margin: 0;
    }
    
    .button-arrow {
        button {
            border: 1px solid $primary !important;
            padding-top: 7px !important;
            padding-bottom: 7px !important;

            &:first-child {
                margin-right: 5px;
                padding-left: 13px !important;
                padding-right: 10px !important;
            }
            &:last-child {
                padding-left: 13px !important;
                padding-right: 10px !important;
            }

            i {
                color: $primary;
            }
        }
    }


    
    .date-range-picker {
        border: 1px solid $primary;
        border-radius: 5px;

        .custom-rsuite-date-range-picker i {
            color: $primary;
            position: absolute;
            right: 15px;
            top: 7px;
            font-size: 20px;
            z-index: 5;
            width: 20px !important;
        }
    }


    .btn-today {
        border: 1px solid $primary !important;
        padding-top: 7px !important;
        padding-bottom: 7px !important;
        padding-left: 10px !important;
        padding-right: 10px !important;
        color: $primary !important;
    }
}