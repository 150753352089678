.datatable-search-component {
    input {
        background-color: #F7F9FD;
        border: 1px solid #F7F9FD;

        &::placeholder {
            font-style: italic;
        }

        &:hover, &:focus {
            background-color: #F7F9FD;
            border: 1px solid #F7F9FD;
        }
    }

    .MuiInput-underline:before {
        border-bottom: 1px solid rgba($color-secondary, 0.10);
    }

    svg {
        color: rgba($color-secondary, 0.60);
    }
}

.bin-type-in-datatable-search {
    background-color: #F7F9FD;
    border: 1px solid #F7F9FD;
    border-radius: 5px;
    
    input {
        color: #7F7F7F;
        background-color: #F7F9FD;
        border: 1px solid #F7F9FD;

        &::placeholder {
            color: #7F7F7F;
        }
    }

    .MuiInput-underline:before {
        border: 0px;
    }
    
    .MuiInput-underline:hover:not(.Mui-disabled):before {
        border: 0px;
    }

    svg {
        color: rgba(#7F7F7F, 1);
    }
}