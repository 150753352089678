$font-size-12: 12px;

.custom-checkbox-component {
    position: relative; 
 
    &.top-4 {
        top: -4px;
    }

    *:not(i) {
        text-transform: capitalize !important;
        padding: 1.5px;
    }

    > span {
        padding: 0px;
    }

    > div {
        > span {
            display: inline-flex;
        }
    }
    
    .checkbox-label-style {
        position: relative;
        top: 2px;
        margin-left: 5px;
        cursor: pointer;
        font-size: $font-size-12;
        display: inline;

        &.boldCB {
            font-weight: bold;
        }

        .desc-muted {
            font-size: 12px;
            color: rgba($color-secondary, 0.60);
            padding-left: 31px;
            cursor: pointer;
        }
    }

    .MuiCheckbox-colorPrimary {
        svg {
            color: $color-active !important;
        }
    }

    .MuiCheckbox-colorSecondary {
        svg {
            color: $color-secondary !important;
        }
    }

    &.green {
        .MuiCheckbox-colorPrimary {
            svg {
                color: #06D6A0 !important;
            }
        }
    }

    &.recurring {
        > div {
            > span {
                padding: 0px;
            }
        }

        .MuiCheckbox-colorPrimary {
            svg {
                color: #7F7F7F !important;
            }

            &.Mui-checked {
                svg {
                    color: $color-active !important;
                }
            }
        }
    }
}


.checkbox-secondary {
    svg {
        fill: $color-secondary
    }

    &.Mui-disabled {
        svg {
            fill: $color-secondary
        }
    }
}

.checkbox-primary {
    svg {
        fill: $color-active
    }

    &.Mui-disabled {
        svg {
            fill: $color-secondary
        }
    }
}