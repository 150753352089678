
.ant-progress {
  .ant-progress-outer {
    .ant-progress-inner {
      background-color: $gray-300;
      height: 2px;
      overflow: unset;
  
      .ant-progress-bg {
        background-color: $blue-400 !important;
        height: 5px !important;
        top: -1px !important;
      }
    }
  }

  &.ant-progress-status-success {
    .ant-progress-outer {
      .ant-progress-inner {
        .ant-progress-bg {
          background-color: $green-400 !important;
        }
      }
    }
  }
}