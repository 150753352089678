.custom-column-resize-component {
    table {
        width: max-content !important;
        min-width: 100%;

        th {
            user-select: none !important;
            position: relative;

            &.header--being-resized {
                background-color: rgba(72, 72, 72, 0.1);
            }
        }
    }
      
    .resize-handle {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        opacity: 0;
        width: 0;
        padding-right: 2px;
        cursor: col-resize;
        
        opacity: 1 !important;
        height: 85% !important;
        background-color: transparent !important;
        border-left: 2px dashed rgba(72, 72, 72, 0.1);
        z-index: 1;

        &:hover {
            border-left: 2px dashed rgba(72, 72, 72, 0.6);
        }
    }
}