.icon-list-component {
    button {
        &:hover {
            .material-icons {
                color: $color-active;
            }
        }
    }
    
    .material-icons {
        color: rgba($color-secondary, 0.6);
    }
}

.datatable-action-menu {
    li {
        &:focus {
            outline: none !important;
        }
    }
}