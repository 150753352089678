.custom-steps-component {
    .step-container {
        height: 100%;
    }

    .step-content {
        &-item {
            position: relative;
            min-height: 70px;

            &.no-min-height {
                min-height: unset;
            }

            &-left {
                position: relative;
                min-width: 30px;
                z-index: 0;

                @include respond-below('md') {
                    display: none;
                }

                .material-icons, svg {
                    left: -4px;
                    top: 4px;
                    position: relative;
                    font-size: 24px;
                }

                div {
                    position: relative;
                    top: 4px;
                    width: 25px;
                    height: 25px;
                    border-radius: 50%;
                    text-align: center;
                    z-index: 1;
                    line-height: 25px;
                    font-size: 15px;
                    background-color: $white;
                    border: 2px solid #7F7F7F;
                    color: #7F7F7F;
                }
            }

            &-right {
                position: relative;
                padding-left: 15px;
                padding-top: 8px;

                &.no-padding {
                    padding-left: 0px;
                    padding-top: 0px;
                }
            }
               
            &:after {
                content: '';
                position: absolute;
                top: 33px;
                left: 14px;
                height: 95%;
                border-left: 2px dashed rgba(#7F7F7F, 1);

                @include respond-below('md') {
                    content: none;
                }
            }

            &:last-child {
                &:after {
                    content: none;
                }
            }
        }
    }
}